import { DatePicker } from "antd";
import React from "react";
import dayjs from 'dayjs';
import "./WeekFilter.scss";

type WeekFilterProps = {
    setPrevSelectedWeek: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
    setNextSelectedWeek: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
    selectedWeek: dayjs.Dayjs;
    onChange: (date: dayjs.Dayjs, dates: string | string[]) => void;
    format?: string;
    allowClear?: boolean;
    size?: 'sm' | 'md' | 'lg';
};

function WeekFilter(props: Readonly<WeekFilterProps>) {
    const { setPrevSelectedWeek, setNextSelectedWeek, selectedWeek, onChange, format, allowClear, size = 'sm' } = props;

    const weekFormat = 'MM/DD';
    const customWeekStartEndFormat = (value: any) =>
        `${dayjs(value).startOf('week').format(format ?? weekFormat)} - ${dayjs(value)
            .endOf('week')
            .format(format ?? weekFormat)}`;

    const getPickerSize = (size: WeekFilterProps['size']): string => {
        if (size === 'sm') return '125px'
        if (size === 'md') return '175px'
        return '225px'
    }

    const [panelChanged, setPanelChanged] = React.useState(false);

    return (
        <div className="custom-week-filter d-flex align-items-center">
            <div className='d-flex align-items-center'>
                <button
                    className="left-icon-btn btn btn-custom-secondary-outline"
                    type="button"
                    onClick={() => setPrevSelectedWeek((prev) => prev.subtract(1, 'week'))}
                    title="Previous Week"
                >
                    <span className="btn-text">
                        <i className="ri-arrow-left-double-line"></i>
                    </span>
                </button>
                <DatePicker
                    className="custom-week-picker mx-1"
                    popupClassName={panelChanged ? 'custom-week-picker-panel-changed' : ''}
                    onChange={onChange}
                    picker="week"
                    value={selectedWeek}
                    format={customWeekStartEndFormat}
                    showWeek={false}
                    allowClear={allowClear ?? {
                        clearIcon: <i
                            className={selectedWeek.isSame(dayjs(), 'week') ? "ri-calendar-event-line" : "ri-calendar-close-line"}
                            style={selectedWeek.isSame(dayjs(), 'week') ? {} : { color: 'var(--primary-color)' }}
                        ></i>
                    }}
                    suffixIcon={<i
                        className={selectedWeek.isSame(dayjs(), 'week') ? "ri-calendar-event-line" : "ri-calendar-close-line"}
                        style={selectedWeek.isSame(dayjs(), 'week') ? {} : { color: 'var(--primary-color)' }}
                    ></i>}
                    style={{ width: getPickerSize(size) }}
                    onPanelChange={(value, mode) => setPanelChanged(true)}
                    onOpenChange={(open) => {
                        if (!open) {
                            setTimeout(() => {
                                setPanelChanged(false);
                            }, 400);
                        }
                    }}
                />
                <button
                    className="right-icon-btn btn btn-custom-secondary-outline"
                    type="button"
                    onClick={() => setNextSelectedWeek((prev) => prev.add(1, 'week'))}
                    title="Next Week"
                >
                    <span className="btn-text">
                        <i className="ri-arrow-right-double-line"></i>
                    </span>
                </button>
            </div>

        </div>
    )
}

export default WeekFilter