import { allowSwapRequest, checkIfManageSwapRequest } from '../../helpers/swapScheduleHelper'
import { capitalizeFirstLetter, customDeepClone, getFirstLetterUpperCase, getFirstThreeLetter, getStaffId } from '../../helpers/utils'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { days, ScheduleData, Staff, getDateOfWeekday, JobRoleForShift, PublishedSchedule, Availability, isDateInSelectedWeek, isDateInRange, getTimeOffMessage, getStaffAvailabilityForTheDay, ScheduleMetadata } from '../../helpers/workTimeScheduleHelper'
import { NO_ROLES_ASSIGNED } from '../../constants/strings'
import { StyleDetails } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import CustomTooltip from '../manage_work_schedule/CustomTooltip'
import dayjs from 'dayjs'
import CustomEventListForTable from '../manage_work_schedule/CustomEventListForTable'

type Props = {
    isLoading: boolean;
    selectedWeek: dayjs.Dayjs;
    staffsWithSchedule: ScheduleData | null;
    swapShiftRequest: (shift: PublishedSchedule, staff: Staff) => void;
    parent?: string;
    getRoleColorCode:(roleId: string) => StyleDetails;
    scheduleMetadata: ScheduleMetadata | null;
}

type GetStaffAvailability = {
    day: string
    availabilities: Availability | undefined
}

const SwapScheduleDesktopView = ({
    isLoading,
    selectedWeek,
    staffsWithSchedule,
    swapShiftRequest,
    parent,
    getRoleColorCode,
    scheduleMetadata
}: Readonly<Props>) => {
    const queryClient = useQueryClient()
    const staffId = getStaffId()
    const { staffs } = staffsWithSchedule!
    const staffSortByCurrentUser = staffs.reduce<Staff[]>((staffArray, staff) => {
        if (staff.id === staffId) {
            return [staff, ...staffArray];
        } else {
            return [...staffArray, staff];
        }
    }, [])
    .filter((staff) => Object.values(staff.shifts).length);// Filter staff list if no shifts assigned/scheduled and sort with first name

    if (parent === 'myScheduleView') {
        staffSortByCurrentUser.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    const managePermission = checkIfManageSwapRequest(queryClient)

    const staffNameTemplate = (rowData: Staff) => {
        const { preferredName = '', jobRoles = [] } = rowData ?? {}
        const roles = jobRoles
            .map((role: JobRoleForShift | undefined) => role?.jobRole?.jobRoleName)
            .filter(Boolean)
            .join(', ')

        return (
            <div className="text-nowrap">
                <div className="overflow-ellipsis fw-bold">{capitalizeFirstLetter(preferredName) || preferredName}</div>
                <div className="overflow-ellipsis" title={roles}>
                    {roles || NO_ROLES_ASSIGNED}
                </div>
            </div>
        )
    }

    const cellBodyTemplate = ({ day, rowData }: { day: string; rowData: Staff }) => {
        const { shifts, preferredName, unavailableDays, availabilities } = rowData
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const availabilityText = getStaffAvailabilityForTheDay({ day, availabilities, selectedWeek, type: 'schedule' })

        const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
        const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))
        const shiftsArray = shifts[date]

        const timeOffText = getTimeOffMessage({ unavailability: unavailability, type: 'schedule' }, date)?.length
            ? getTimeOffMessage({ unavailability: unavailability, type: 'schedule' }, date)
            : availabilityText

        if (shiftsArray?.length) {
            return shiftsArray.map((shift, index) => {
                const updatedShift = customDeepClone(shift) as PublishedSchedule
                const { shiftName, startTime, endTime, isBd, isClose, role, isPoolRequested, roleId } = updatedShift.assignedShifts
                const canRequestSwap = (managePermission && !allowSwapRequest(updatedShift)) || (
                    !managePermission && (
                        rowData.id !== staffId ||
                        !allowSwapRequest(updatedShift)
                    )
                )

                const styleDetails = getRoleColorCode(roleId);
                const { backgroundColor, color } = styleDetails;

                return (
                    <>
                        {(index === 0 && timeOffText) && timeOffText}
                        <CustomTooltip key={uuidv4()} staffName={preferredName} showDelay={500} content={shiftsArray}>
                            <div
                                className={`scheduler-shift-card-body w-100`}
                                style={{
                                    backgroundColor: "white",
                                    border: `2px solid ${backgroundColor}`,
                                }}
                                onClick={() => {
                                    if(parent !== 'myScheduleView'){
                                        if (canRequestSwap) return
                                        swapShiftRequest(updatedShift, rowData)
                                    }
                                }}
                            >
                                <div className="d-flex">
                                    <div className="flex-grow-0 d-flex align-items-center justify-center">
                                        <span 
                                            className="shift-role"
                                            style={{
                                                backgroundColor,
                                                color: color,
                                            }}
                                        >{getFirstLetterUpperCase(role ?? '')}</span>
                                    </div>
                                    <div className="flex-grow-1 shift-name-main text-nowrap">
                                        {/* <span className="shift-name">{shiftName ?? ''}</span> */}
                                        {isClose ? `${startTime} - Close` : ""}
                                        {isBd ? `${startTime} - V` : ""}
                                        {!isBd && !isClose ? `${startTime} - ${endTime}` : ''}
                                    </div>
                                    {(parent !== 'myScheduleView' && canRequestSwap && isPoolRequested) && (
                                        <div className="flex-grow-0 d-flex align-items-center justify-center swap-icn-bg">
                                            <i className="pi pi-arrow-right-arrow-left text-light mx-1"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CustomTooltip >
                    </>
                )
            })
        } else {
            return timeOffText ?? (<></>)
        }
    }

    const renderColumns = () => {
        return days.map((day) => {
            return (
                <Column
                    key={day}
                    headerClassName="workTimeScheduleHeader"
                    header={() => (
                        <div className="scheduler-header">
                            <div className='mb-0 text-center text-nowrap fw-normal text-muted'>{getFirstThreeLetter(day)}</div>
                            <div className="mb-2 text-center text-nowrap">{getDateOfWeekday({ selectedWeek, day: day })}</div>
                            <CustomEventListForTable 
                                customEvents={scheduleMetadata?.customEvents ?? []}
                                day={day}
                            />
                        </div>
                    )}
                    body={(data) => cellBodyTemplate({ day, rowData: data })}
                    style={{ width: '12.5%' }}
                    className="shift-column"
                />
            )
        })
    }

    return (
        <div className="card h-100">
            <DataTable
                value={staffSortByCurrentUser}
                showGridlines
                scrollable
                scrollHeight="100%"
                className={`h-100 work-time-schedule-table ${parent!=='myScheduleView' ? 'bg-active-user' : ''}`}
                loading={isLoading}
                pt={{ loadingOverlay: { className: 'color-primary-custom', style: { zIndex: 3 } } }}
            >
                <Column
                    field="day"
                    frozen 
                    className="overflow-ellipsis"
                    header=""
                    body={staffNameTemplate}
                    style={{ maxWidth: '12rem' }}
                />
                {renderColumns()}
            </DataTable>
        </div>
    )
}

export default SwapScheduleDesktopView
