import { capitalize, cloneDeep } from "lodash";
import { CustomEvent } from "../../helpers/workTimeScheduleHelper"
import dayjs from 'dayjs'
import { Badge } from "primereact/badge";
import { JTranslation } from "../../helpers/jTranslate";
import { Popover } from "antd";

type CustomEventListForTableProps = {
    customEvents: CustomEvent[];
    day: string;
}

function CustomEventListForTable({
    customEvents,
    day
}: Readonly<CustomEventListForTableProps>) {
    const addedEvents = customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())?.eventData;
    const sortedEvents = cloneDeep(addedEvents)?.sort((a,b) => dayjs(a?.time, 'HH:mm A').isAfter(dayjs(b?.time, 'HH:mm A')) ? 1 : -1)

    const popoverContent = (currentEvent: any) => (
        <div className="tooltip-content" style={{ maxWidth: '260px', minWidth: '150px' }}>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0"><strong>{capitalize(currentEvent?.guestName ?? '')}</strong></p>
                {currentEvent?.guests && (
                    <Badge value={currentEvent?.guests ?? ''} />
                )}
            </div>
            <div className="mt-3 tooltip-container">
                <div className="tooltip-row">
                    <div className="tooltip-label"><JTranslation typeCase="capitalize" text={'Time'} /></div>
                    <div className="tooltip-separator">:</div>
                    <div className="tooltip-value">{
                        dayjs(currentEvent?.time, 'HH:mm A').isValid() 
                            ? dayjs(currentEvent?.time, 'HH:mm A').format('h:mm A') 
                            : ''
                        }
                    </div>
                </div>
            </div>
            {currentEvent?.desc && (
                <>
                    <hr className="tooltip-divider" />
                    <div className="tooltip-row">{currentEvent?.desc}</div>
                </>
            )}
        </div>
    )

    return (
        <div className='event-card-scroll'>
            {/* rendering events  */}
            {sortedEvents?.map((event) => {
                const currentEvent = event as any
                return (
                    <div key={event?.id} className="d-flex flex-row mb-0">
                        <div className="d-flex flex-row justify-content-between align-items-center px-2 reservation w-100">
                            <Popover
                                title=""
                                content={popoverContent(currentEvent)}
                                trigger={["hover", "click"]}
                            >
                                <small className="text-nowrap w-100 text-center">
                                    {currentEvent?.guests} @ {dayjs(currentEvent?.time, 'HH:mm A').format('h:mm A')}
                                </small>
                            </Popover>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default CustomEventListForTable