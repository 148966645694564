import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef } from "react";
import "./FilterWrapper.scss";
import { useWindowSize } from "react-use";
import { JTranslation } from "../../helpers/jTranslate";

type FilterWrapperProps = {
    children: React.ReactNode;
    isFiltered: boolean;
    zIndexRef?: React.MutableRefObject<any>;
    clearFilter?: () => void;
}

function FilterWrapper(props: Readonly<FilterWrapperProps>) {
    const { children, isFiltered, zIndexRef, clearFilter } = props;
    const filterPanelRef = useRef(null);
    const { width } = useWindowSize()

    // hide the filter panel when the screen width changes
    useEffect(() => (filterPanelRef.current as any)?.hide(), [width])

    return (
        <>
        <div className="custom-week-filter">
            <Button
                pt={{ root: { style: { padding: '7px 0px' } } }}
                className={`filter-wrapper-btn btn-sm left-icon-btn btn btn-custom-secondary-outline d-flex align-items-center justify-center  ${isFiltered ? "btn-custom-primary" : ""}`}
                icon={isFiltered ? "pi pi-filter-fill" : "pi pi-filter-slash"}
                onClick={(e) => {
                    (filterPanelRef.current as any)?.toggle(e)
                }}
                outlined
            />
            </div>
            <OverlayPanel
                className="filter-overlay-panel"
                ref={filterPanelRef}
                pt={{
                    content: { style: { padding: '10px', maxWidth: '250px' } },
                    root: {
                        style: {
                            borderRadius: '3px',
                            background: 'var(--bg-color)',
                            "--custom-filter-overlay-panel-z-index": zIndexRef?.current?.getElement()?.parentElement?.style?.zIndex
                                ? zIndexRef?.current?.getElement()?.parentElement?.style?.zIndex + 1
                                : 1042
                        }
                    }
                }}
            >
                {children}
                {clearFilter && (
                    <div className="mt-4 d-flex flex-row justify-content-end align-items-center">
                        <button
                            className={`btn btn-sm ${isFiltered ? 'btn-custom-primary' : 'btn-secondary'}`}
                            type="button"
                            onClick={clearFilter}
                            disabled={!isFiltered}
                        >
                            <JTranslation typeCase="pascal" text={'Clear'} />
                        </button>
                    </div>
                )}
            </OverlayPanel>
        </>
    )
}

export default FilterWrapper