import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { getFirstThreeLetter } from '../../helpers/utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { EmployeeScheduleData, days, ScheduleData, Staff, getDateOfWeekday, ScheduleMetadata, CustomEvent, isDateInRange } from "../../helpers/workTimeScheduleHelper";
import { JTranslation } from '../../helpers/jTranslate';
import { NO_ROLES_ASSIGNED } from '../../constants/strings';
import { PerDayCountForListing, ToastMessageProps } from '../../constants/staticTypes';
import { v4 as uuidv4 } from 'uuid';
import CustomTooltip from '../../components/manage_work_schedule/CustomTooltip';
import dayjs from 'dayjs';
import noPublishedSchedule from '../../assets/images/no_published_schedule.svg';
import Loader from '../../components/loader/Loader';

// Define the type for grouped ScheduleData
export type GroupedScheduleData = {
    [roleId: string]: Staff[];
};

interface RoleData {
    roleId: string;
    roleName: string;
}

interface GroupedDetails {
    roleData: RoleData
    staffs: Staff[]
}

interface GroupedData {
    [id: string | number]: GroupedDetails
}

type Props = {
    reloadPageData: () => void
    saveEmployeeName: (name: string) => void
    scheduleId: string
    selectedWeek: dayjs.Dayjs
    setData: Dispatch<SetStateAction<Staff | null>>,
    setSelectedDay: Dispatch<SetStateAction<string>>
    setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: Dispatch<SetStateAction<ToastMessageProps>>
    shiftSideBar: boolean,
    staffingRequirement: PerDayCountForListing[]
    staffsWithSchedule: ScheduleData | null
    scheduleMetadata: ScheduleMetadata | null
    isLoading: boolean
    selectedView: string | null
}

const RoleBasedShiftScheduler = ({
    saveEmployeeName,
    selectedWeek,
    setData,
    setShiftSideBar,
    shiftSideBar,
    staffsWithSchedule,
    setSelectedDay,
    scheduleMetadata,
    isLoading,
    selectedView
}: Props) => {

    const dataTableHeaderRef = useRef<any>(null);
    const eventsTableRef = useRef<any>(null);
    const [groupedData, setGroupedData] = useState<GroupedData>({});
    const [allStaffsWithSchedule, setAllStaffsWithSchedule] = useState<Staff[] | undefined>([])

    useEffect(() => {
        if (dataTableHeaderRef.current || eventsTableRef.current) {
            // Find the tbody element inside the DataTable
            const tbody = dataTableHeaderRef.current.querySelector('tbody');
            const tbodyEvents = eventsTableRef.current.querySelector('tbody');
            if (tbody || tbodyEvents) {
                // Remove tbody from the DOM
                tbody.remove();
                tbodyEvents.remove();
            }
        }
    }, []); // Depend on `data` to rerun if `data` changes

    useEffect(() => {
        const updateScrollStyle = () => {
            const wrapper = document.querySelectorAll('.role-based-table>.p-datatable-wrapper');
            if (wrapper.length > 0) {
                wrapper.forEach((element) => {
                    (element as HTMLElement).style.overflow = 'visible';
                })
            }
        }
        setTimeout(() => {
            updateScrollStyle()
        }, 500);
    }, []);

    // Interfaces and Function to group staff by assigned role
    interface AssignedShifts {
        date: string;
        role: string;
        roleId?: string;
        shiftName?: string;
        startTime?: string;
        endTime?: string;
        isClose?: boolean;
        isBd?: boolean;
    }

    interface JobRole {
        id: string;
        jobRoleDescription: string;
        jobRoleName: string;
    }

    interface RowData {
        id: string;
        email: string | null;
        firstName: string;
        lastName: string;
        preferredName: string;
        jobRoles: JobRole[];
        assignedShifts: AssignedShifts[];
    }

    useEffect(() => {
        // get data of all the staffs assigned with shifts in the published schedule in role based view
        function groupByRoleAndDate(staffs: Staff[] | undefined): any {
            const groupedByRole: any = {};

            staffs?.forEach((staff: any) => {
                Object.values(staff.shifts).forEach((shiftArray: any) => {
                    shiftArray.forEach((shift: any) => {
                        const role = shift.assignedShifts.roleId;
                        if (!groupedByRole[role]) {
                            groupedByRole[role] = {
                                roleData: {
                                    roleId: shift.assignedShifts.roleId,
                                    roleName: shift.assignedShifts.role
                                }, staffs: []
                            };
                        }

                        // Check if this staff is already added under this role
                        let staffEntry = groupedByRole[role].staffs.find((entry: any) => entry.id === staff.id);
                        if (!staffEntry) {
                            staffEntry = {
                                id: staff.id,
                                email: staff.email,
                                firstName: staff.firstName,
                                lastName: staff.lastName,
                                preferredName: staff.preferredName,
                                jobRoles: staff.jobRoles.map((jobRole: any) => ({
                                    id: jobRole.id,
                                    jobRoleName: jobRole.jobRole?.jobRoleName,
                                    jobRoleDescription: jobRole.jobRole?.jobRoleDescription
                                })),
                                shifts: {}
                            };
                            groupedByRole[role].staffs.push(staffEntry);
                        }

                        // Group shifts by date
                        const date = shift.scheduleDate;
                        if (!staffEntry.shifts[date]) {
                            staffEntry.shifts[date] = [];
                        }

                        staffEntry.shifts[date].push(shift);
                    });
                });
            });
            return groupedByRole;
        }
        const groupedStaffsData: GroupedData = groupByRoleAndDate(staffsWithSchedule?.staffs);
        setGroupedData(groupedStaffsData);
        
        // Get all the staffs with shifts assigned in published schedule for all staffs view
        // const filteredStaffs = staffsWithSchedule?.staffs.filter(staff => Object.keys(staff.shifts).length > 0);
        const filteredStaffs = staffsWithSchedule?.staffs.filter(staff => Object.values(staff.shifts).flat().length > 0);
        setAllStaffsWithSchedule(filteredStaffs)
    }, [staffsWithSchedule, selectedWeek])

    const checkHoliday = (day: string) => {
        if (staffsWithSchedule !== null) {
            const { holidays } = staffsWithSchedule
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
            return isHolidays
        }
    };

    const checkMandatoryDays = (day: string) => {
        if (staffsWithSchedule !== null) {
            const { mandatoryDays } = staffsWithSchedule
            const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
            const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
            return isMandatory
        }
    };

    const headerTemplate = ({ day }: { day: string; schedule: EmployeeScheduleData[], customEvent: CustomEvent | undefined }) => {
        return (
            <div>
                <div className='mb-2 text-center'>{getFirstThreeLetter(day)}</div>
                <div className="mb-2 text-center date-role-based">{getDateOfWeekday({ selectedWeek, day: day })}</div>

                {/* rendering holidays */}
                {checkHoliday(day)
                    ? (
                        <div className={`${checkHoliday(day) && 'holiday-header'}`}>
                            <div className='mb-1 text-center text-nowrap fw-normal text-muted w-100'>
                                <div className="d-flex align-center justify-content-center position-relative">
                                    <div><JTranslation typeCase="none" text={"Holiday"} /></div>
                                </div>
                            </div>
                        </div>
                    )
                    : ""}

                {/* rendering mandatory days */}
                {checkMandatoryDays(day)
                    ? (
                        <div className={`${checkMandatoryDays(day) && 'mandatory-day'}`}>
                            <div className='mb-1 text-center text-nowrap fw-normal text-muted w-100'>
                                <div className="d-flex align-center justify-content-center position-relative">
                                    <div><JTranslation typeCase="none" text={"Mandatory Day"} /></div>
                                </div>
                            </div>
                        </div>
                    )
                    : ""}
            </div>
        )
    }


    const staffNameTemplate = (rowData: RowData) => {
        const { preferredName, jobRoles } = rowData
        const roles = jobRoles.map((role, idx) =>
            role?.jobRoleName === undefined ? '' : idx === jobRoles.length - 1 ? `${role?.jobRoleName}` : ` ${role?.jobRoleName} | `
        )
        const roleString = roles.join('').trim()

        return (
            <div className="text-nowrap" style={{ minWidth: "12rem", maxWidth: "12rem" }}>
                <div className="overflow-ellipsis fw-bold">{preferredName}</div>
                <div className="overflow-ellipsis" title={roleString}>
                    {roles}
                </div>
                <div className="overflow-ellipsis">{!roles.length ? NO_ROLES_ASSIGNED : ''}</div>
            </div>
        )
    }


    const cellBodyTemplate = ({ day, rowData }: { day: string; rowData: Staff }) => {
        const { shifts, preferredName } = rowData
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const shiftsArray = shifts[date]

        if (shiftsArray?.length) {
            return shiftsArray.map((shift, index) => {
                const { assignedShifts } = shift
                const { startTime, endTime, isBd, isClose, role } = assignedShifts

                return (
                    <CustomTooltip key={uuidv4()} staffName={preferredName} showDelay={500} content={shiftsArray}>
                        <div
                            className="scheduler-shift-card-body"
                            onClick={() => {
                                if (staffsWithSchedule?.schedule.actionStatus === 'draft') {
                                    setShiftSideBar(!shiftSideBar)
                                    saveEmployeeName(rowData.preferredName)
                                    setSelectedDay(day)
                                    setData(rowData)
                                }
                            }}
                        >
                            <div className="d-flex" style={{ minWidth: "12rem", maxWidth: "12rem" }}>
                                <div className="flex-grow-1 shift-name-main">
                                    {isClose ? `${startTime} - Close` : ""}
                                    {isBd ? `${startTime} - V` : ""}
                                    {!isBd && !isClose ? `${startTime} - ${endTime}` : ''}
                                    <span className="shift-name">{role}</span>
                                </div>
                            </div>
                        </div>
                    </CustomTooltip>
                )
            })
        } else {
            return ''
        }
    }

    const renderColumns = () => {
        return days.map((day) => (
            <Column
                key={day}
                headerClassName="workTimeScheduleHeader"
                body={(data) => cellBodyTemplate({ day, rowData: data })}
                style={{ minWidth: "12rem", maxWidth: "12rem" }}
                className="shift-column"
            />
        ))
    }

    const renderHeader = () => {
        return days.map((day) => {
            const customEvent = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
            return (
                <Column
                    key={day}
                    headerClassName="workTimeScheduleHeader"
                    header={(data) => headerTemplate({ day, schedule: data.props.value, customEvent })}
                    style={{ minWidth: "12rem", maxWidth: "12rem" }}
                    className="shift-column"
                />
            )
        })
    }

    const eventsTemplate = ({ day }: { day: string; schedule: EmployeeScheduleData[], customEvent: CustomEvent | undefined }) => {
        const addedEvents = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())

        return (
            // rendering events 
            addedEvents?.eventData.map((event) => {
                const currentEvent = event as any
                return (
                    <div key={event?.id} className="d-flex flex-row mb-0">
                        <div className="d-flex flex-row justify-content-between align-items-center px-2 reservation w-100">
                            <small className="text-nowrap w-100 text-center">{currentEvent?.guests} @ {dayjs(currentEvent?.time, 'HH:mm A').format('h:mm A')}</small>
                        </div>
                    </div>
                )
            })
        )
    }

    const renderEvents = () => {
        return days.map((day) => {
            const customEvent = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
            return (
                <Column
                    key={day}
                    headerClassName="workTimeScheduleHeader"
                    header={(data) => eventsTemplate({ day, schedule: data.props.value, customEvent })}
                    style={{ minWidth: "12rem", maxWidth: "12rem" }}
                    className="shift-column"
                />
            )
        })
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className="card role-based-main-container ">
            {/* Rendering the header for data tables  */}
            <div className="role-based-header-container">
                <div ref={dataTableHeaderRef} className='role-based-header-container'>
                    <DataTable
                        // value={[]}
                        showGridlines
                        scrollable={false}
                        // scrollHeight="70vh"
                        className="work-time-schedule-table role-based-table-header role-based-table w-100"
                        emptyMessage={' '}
                    >
                        <Column
                            field="day"
                            className="overflow-ellipsis"
                            header={<div className='text-center' style={{ minWidth: "12rem", maxWidth: "12rem" }}></div>}
                        // style={{ width: '12.5%' }}
                        />
                        {renderHeader()}
                    </DataTable>
                </div>

                {/* table to show the events */}
                <div ref={eventsTableRef} className='role-based-header-container'>
                    <DataTable
                        // value={[]}
                        showGridlines
                        scrollable={false}
                        // scrollHeight="70vh"
                        className="work-time-schedule-table role-based-table-header role-based-table w-100"
                        emptyMessage={' '}
                    >
                        <Column
                            field="day"
                            className="overflow-ellipsis"
                            header={<div className='text-center' style={{ minWidth: "12rem", maxWidth: "12rem" }}>
                                <JTranslation typeCase="none" text={"Events"} />
                            </div>}
                        // style={{ width: '12.5%' }}
                        />
                        {renderEvents()}
                    </DataTable>
                </div>
            </div>

            {selectedView === 'role-based' ?
                <>
                    {/* Rendering datatables with the grouped data */}
                    {Object.keys(groupedData)
                        .sort((a, b) => {
                            // sort the keys according to the role name to show role-based view in alphabetic order
                            const roleNameA = groupedData[a].roleData.roleName.toLowerCase();
                            const roleNameB = groupedData[b].roleData.roleName.toLowerCase();
                            if (roleNameA < roleNameB) return -1;
                            if (roleNameA > roleNameB) return 1;
                            return 0;
                        })
                        .map((key, index) => {
                            const { roleData, staffs } = groupedData[key];

                            return (
                                <div key={key} className="datatable-container" style={{ width: "auto", display: "grid" }}>
                                    <div className='role-based-table-title' style={{ width: "auto" }}>{roleData.roleName}</div>
                                    <div >
                                        <DataTable
                                            value={staffs}
                                            showGridlines
                                            scrollable={false}
                                            // scrollHeight="70vh"
                                            className="work-time-schedule-table role-based-table-list role-based-table w-100"
                                            emptyMessage={' '}
                                        >
                                            <Column
                                                field="day"
                                                className="overflow-ellipsis"
                                                body={staffNameTemplate}
                                            // style={{ maxWidth: '12rem' }}
                                            />
                                            {renderColumns()}
                                        </DataTable>
                                    </div>
                                </div>
                            );
                        })
                    }
                </>
                :
                <>

                    {/* table showing the staffs details */}
                    <div className="datatable-container" style={{ width: "auto", display: "grid" }}>
                        <div >
                            <DataTable
                                value={allStaffsWithSchedule}
                                showGridlines
                                stripedRows
                                scrollable={false}
                                // scrollHeight="70vh"
                                className="work-time-schedule-table role-based-table-list role-based-table w-100"
                                emptyMessage={' '}
                            >
                                <Column
                                    field="day"
                                    className="overflow-ellipsis"
                                    body={staffNameTemplate}
                                // style={{ maxWidth: '12rem' }}
                                />
                                {renderColumns()}
                            </DataTable>
                        </div>
                    </div>
                </>
            }

            {
                (!Object.keys(groupedData).length && !isLoading) && (
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <div className="flex-grow-1 align-items-center justify-content-center p-3 text-center mt-5">
                            <img src={noPublishedSchedule} alt='no published schedule' style={{ textAlign: 'center' }} />
                            <h5 className="mt-2 mb-3">
                                <JTranslation typeCase="none" text={"No published schedule for the selected week"} />
                            </h5>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default RoleBasedShiftScheduler
