import { useContext, useEffect, useRef, useState } from 'react'
import './work_schedule.scss'
import { AxiosResponse } from 'axios'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { checkStaffSchedule, cloneDraftFromPublished, copyEntireSchedule, copyIndividualSchedule, CopyScheduleIndividualPayloadType, CopySchedulePayloadType, createDraftSchedule, days, deleteExistingDraft, depOptionTemplate, depSelectionTemplate, DraftResponse, DropDown, fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, generateConflictMessages, getGlobalStaffing, getScheduleId, getWeekRange, initialDataMinimumEmployeeCount, initialScheduleStatus, optionTemplate, optionTemplateWithTime, publishDraftedSchedule, Schedule, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, selectionTemplate, Shifts, ShiftsByDate, shiftSelectionTemplate, Staff, staffCountByDay, StartAndEndDates, validateCopyEntireSchedule, validateCopyIndividualSchedule } from '../../helpers/workTimeScheduleHelper'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { CREATE_DRAFT_SCHEDULE, ROLES_LIST, SCHEDULE_STATUS, SHIFT_CONFIG_LIST, STAFFING_REQUIREMENTS_LIST, STAFFS_WITH_SCHEDULES_AND_SHIFT, USERS_LIST } from '../../constants/queryKeys'
import { DATE_FORMAT, MOBILE_VIEW_BREAKPOINT, TAB_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { DatePicker } from 'antd'
import { Dialog } from 'primereact/dialog'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { getRolesList } from '../../helpers/roleHelper'
import { getShiftConfigList } from '../../helpers/shiftConfigHelper'
import { isEmptyObject, toPascalCase } from '../../helpers/utils'
import { JTranslation } from '../../helpers/jTranslate'
import { MenuItem } from 'primereact/menuitem'
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect'
import { PUBLISH, WORK_TIME_SCHEDULE, WORK_TIME_SCHEDULE_HELP, PUBLISHED, DRAFT, CONFIRM_PUBLISH_SCHEDULE, CONFIRM_UPDATE_SCHEDULE, CLEAR_DRAFT, CONFIRM_CLEAR_DRAFT, ROLE, SHIFT, STAFF_SCHEDULE_DISCREPANCY, STAFF_COUNT, EDIT, AREA_OF_OPERATION, COPY_SCHEDULE, COPY_SCHEDULE_TO } from '../../constants/strings'
import { TieredMenu } from 'primereact/tieredmenu'
import { ToastMessageProps, JobRole, ShiftTemplate, PerDayCountForListing, ShiftDropDown, ScheduleRoleDropDown, ScheduleDepartmentDropDown, PrimeReactDropDown, Users, ConflictData, MinimumEmployeeCountType } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { useWindowSize } from 'react-use'
import { v4 as uuidv4 } from 'uuid'
import CommonModal from '../../components/common_modal/CommonModal'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import Loader from '../../components/loader/Loader'
import MinimumEmployeeCountSideBar from '../../components/manage_work_schedule/MinimumEmployeeCountSideBar'
import MlxPopover from '../../components/common/MlxPopover'
import PrintPreview from './PrintPreview'
import scheduleError from '../../assets/images/work_time_schedule_error.png'
import ShiftScheduler from '../../components/manage_work_schedule/ShiftScheduler'
import ShiftSchedulerMobileView from '../../components/manage_work_schedule/ShiftSchedulerMobileView'
import ShiftScheduleSideBar from '../../components/manage_work_schedule/ShiftScheduleSideBar'
import ToastAlert from '../../components/alert/ToastAlert'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import { getUsersList } from '../../helpers/manageUsersHelper'
import FilterWrapper from '../../components/filter_wrapper/FilterWrapper'
import WeekFilter from '../../components/week_filter/WeekFilter'
import { cloneDeep } from 'lodash'
dayjs.extend(isSameOrBefore);

const ManageWorkSchedule = () => {
    const { showSideBar, setShowSideBar } = useContext(CommonCtx)
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const breakPointTab = TAB_VIEW_BREAKPOINT
    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    const defaultCopyToWeek = dayjs().add(1, 'week')
    const scheduleDialogRef = useRef(null);
    const copyScheduleMenu = useRef(null);

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [copyToWeek, setCopyToWeek] = useState<dayjs.Dayjs>(defaultCopyToWeek);
    const [selectedDateRange, setSelectedDateRange] = useState<StartAndEndDates>(getWeekRange(selectedWeek))
    const [selectedDay, setSelectedDay] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState<ScheduleDepartmentDropDown | null>(null)
    const [selectedRole, setSelectedRole] = useState<DropDown | null>(null)
    const [selectedEmployeeName, setSelectedEmployeeName] = useState('')
    const [staffDataWithSchedule, setStaffDataWithSchedule] = useState<Staff | null>(null)
    const [selectedShift, setSelectedShift] = useState<Shifts | null>(null)
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [shiftSideBar, setShiftSideBar] = useState(false)
    const [pageInitialLoad, setPageInitialLoad] = useState(true)
    const [departments, setDepartments] = useState<ScheduleDepartmentDropDown[]>([])
    const [roles, setRoles] = useState<ScheduleRoleDropDown[]>([])
    const [shifts, setShifts] = useState<ShiftDropDown[]>([])
    const [draftedScheduleId, setDraftedScheduleId] = useState('')
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [scheduleStatus, setScheduleStatus] = useState<Schedule>(initialScheduleStatus)
    const [scheduleData, setScheduleData] = useState<ScheduleIdsResponse | null>(null)
    const [filteredStaffsWithSchedule, setFilteredStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)
    const [staffingRequirement, setStaffingRequirement] = useState<MinimumEmployeeCountType>(initialDataMinimumEmployeeCount)
    const [scheduleId, setScheduleId] = useState('')
    const [isDateOlder, setIsDateOlder] = useState<boolean>(false)
    const [visible, setVisible] = useState({ edit: false, publish: false, clear: false })
    const [showCopyScheduleModal, setShowCopyScheduleModal] = useState(false)
    const [copyScheduleMode, setCopyScheduleMode] = useState<"full" | "user">("full")
    const [submitCounter, setSubmitCounter] = useState(0)
    const [isCountOk, setIsCountOk] = useState<boolean>(true)
    const [filteredRoles, setFilteredRoles] = useState<ScheduleRoleDropDown[]>([])
    const [dateChangeDataLoading, setDateChangeDataLoading] = useState<boolean>(false);
    const [schedulerOpenFlag, setSchedulerOpenFlag] = useState<string>(uuidv4()); // unique id to know the sidebar closing
    const [showPreview, setShowPreview] = useState(false)
    const [showPrintPreview, setShowPrintPreview] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState<PrimeReactDropDown[]>([]);
    const [usersListOptions, setUsersListOptions] = useState<PrimeReactDropDown[]>([])
    const [conflictMessages, setConflictMessages] = useState<{ name: string; message: string }[]>([])
    const conflictCount = conflictMessages.length
    const [filteredUsers, setFilteredUsers] = useState<string[]>([]);

    useEffect(() => {
        const res = queryClient.getQueryData(STAFFING_REQUIREMENTS_LIST) as AxiosResponse<any, any>;
        if (res) onSuccessOfGlobalStaffing(res)
    }, [queryClient])

    const copyMenuItems: MenuItem[] = [
        {
            label: "Copy full week schedule",
            command: () => {
                setCopyScheduleMode("full")
                setShowCopyScheduleModal(true)
            }
        },
        {
            label: "Copy user schedule",
            command: () => {
                setCopyScheduleMode("user")
                setShowCopyScheduleModal(true)
            }
        },
    ];

    const createUserOptions = ({ usersList }: { usersList: Users[] }) => {
        const filteredUsersList = usersList
            .filter((user: Users) => user.isActive)
            .map((user: Users, index: number) => {
                const { id, preferredName, jobRoles } = user;

                let isAvailableForSchedule = true;
                if(jobRoles.length === 1) {
                    isAvailableForSchedule = 'isAvailableForSchedule' in jobRoles[0] 
                        ? !!jobRoles[0].isAvailableForSchedule
                        : true;
                }
                
                return { code: id, name: preferredName, isAvailableForSchedule };
            })
            .sort((a: PrimeReactDropDown, b: PrimeReactDropDown) => a.name.localeCompare(b.name));
        setUsersListOptions(filteredUsersList);
    };

    // users list fetch success
    const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
        const responseData = res?.data?.data.lists as Users[]
        createUserOptions({ usersList: responseData })
    }

    // fetch users list
    useQueryHook(USERS_LIST, () => getUsersList('', 500, ''), onUserListFetchSuccess)

    const onCopySuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        setShowCopyScheduleModal(false)
        setConflictMessages([])
        setSelectedUsers([])
        setCopyToWeek(defaultCopyToWeek)
    }

    const onValidateSuccess = (conflictData: ConflictData) => {
        const { conflicts } = conflictData
        const messages = generateConflictMessages({ conflicts, selectedUsers })
        setConflictMessages(messages)

        if (messages.length === 0) {
            if (copyScheduleMode === "full") {
                copyScheduleApiCall()
            } else {
                copyIndividualScheduleApiCall()
            }
        }
    }

    const disablePastWeeks = (currentDate: dayjs.Dayjs) => {
        // Disable all dates before the start of the current week
        return currentDate && currentDate.isBefore(dayjs().startOf('week'), 'day');
    };

    const ConflictListModalContent = (
        <>
            <div className='mb-4'>
                <p className="text-muted mb-2">
                    <JTranslation
                        typeCase="none"
                        text={"Scheduling Conflicts"}
                    />
                </p>
                <div className="mb-0" style={{ maxHeight: '360px', overflowY: 'auto' }}>
                    {conflictMessages.map((message) => (
                        <div className='conflict-card'>
                            <div className='icon-section'>
                                <i className="ri-information-line me-3  text-danger"></i>
                            </div>
                            <div className='content-section'>
                                <div className='username'>
                                    <JTranslation
                                        typeCase="none"
                                        text={message.name}
                                    /></div>
                                <div className='small'>
                                    <JTranslation
                                        typeCase="none"
                                        text={message.message}
                                    />
                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <button
                    type="button"
                    className="btn btn-custom-primary-outline w-100 mb-2"
                    onClick={() => {
                        setShowCopyScheduleModal(false)
                        setCopyToWeek(defaultCopyToWeek)
                        setConflictMessages([])
                    }}
                >
                    <JTranslation typeCase="pascal" text={'Cancel and go back and make changes'} />
                </button>
                <button
                    type="button"
                    className="btn btn-custom-primary w-100"
                    onClick={() => {
                        if (copyScheduleMode === "full") {
                            copyScheduleApiCall()
                        } else {
                            copyIndividualScheduleApiCall()
                        }
                    }}
                >
                    <JTranslation typeCase="pascal" text={'Continue and copy those shifts anyway'} />
                </button>
            </div>
        </>
    )

    const CopyScheduleModalContent = (
        <>
            <div className="mb-4">
                <p className="text-muted">
                    <JTranslation
                        typeCase="none"
                        text="Easily duplicate the schedule from this week to the next!"
                    />
                </p>
            </div>
            {copyScheduleMode === "user" && (
                <MultiSelect
                    value={selectedUsers}
                    onChange={(e: MultiSelectChangeEvent) => setSelectedUsers(e.value)}
                    options={usersListOptions}
                    display="chip"
                    optionLabel="name"
                    placeholder="Select Staffs"
                    maxSelectedLabels={10}
                    className="w-full md:w-20rem my-3"
                // panelClassName='custom-multiselect'
                // filter={true}
                // showClear={false}

                />
            )}
            {copyScheduleMode === "full" && (
                <div className="mb-3">
                    <div className="mb-1 ">
                        <span className="fw-bold me-2">
                            {
                                <JTranslation
                                    typeCase="none"
                                    text={`Copying schedule from`}
                                />
                            }
                        </span>
                        <span>
                            {
                                <JTranslation
                                    typeCase="none"
                                    text={`"${dayjs(selectedWeek)
                                        .startOf("week")
                                        .format(DATE_FORMAT)} - ${dayjs(selectedWeek)
                                            .endOf("week")
                                            .format(DATE_FORMAT)}"`}
                                />
                            }
                        </span>
                    </div>
                </div>
            )}
            <div className="mb-4">
                <div className="mb-1 fw-bold">
                    {<JTranslation typeCase="none" text={COPY_SCHEDULE_TO} />}
                </div>
                <DatePicker
                    onChange={(date) => {
                        setCopyToWeek(dayjs(date));
                    }}
                    picker="week"
                    style={{ width: "100%" }}
                    value={copyToWeek}
                    className="custom-schedule-calendar"
                    format={(value) =>
                        `${dayjs(value).startOf("week").format(DATE_FORMAT)} - ${dayjs(
                            value
                        )
                            .endOf("week")
                            .format(DATE_FORMAT)}`
                    }
                    allowClear={false}
                    showWeek={false}
                    disabledDate={disablePastWeeks}
                />
            </div>
        </>
    );

    const accept = () => {
        setVisible({ publish: false, edit: false, clear: false });

        switch (scheduleStatus.actionStatus) {
            case 'draft':
                publishDraftedSchedule(scheduleMutation, { scheduleId, scheduleData }, onSuccess, onError);
                break;
            case 'published':
                cloneDraftFromPublished(scheduleMutation, { scheduleId }, onSuccessWithoutMessage, onError);
                break;
            case 'clear-draft':
                deleteExistingDraft(scheduleMutation, { scheduleId: scheduleId }, onSuccessWithoutMessage, onError);
                break;
            default:
                // NO DEFAULT
                break;
        }
    };

    const copyScheduleApiCall = () => {
        // create a payload with from and to week date
        const payload: CopySchedulePayloadType = {
            scheduleId: scheduleId,
            startDate: dayjs(copyToWeek).startOf('week').format("YYYY-MM-DD"),
            endDate: dayjs(copyToWeek).endOf('week').format("YYYY-MM-DD")
        }
        // API call
        copyEntireSchedule(scheduleMutation, payload, onCopySuccess, onError)
    }

    const validateCopyScheduleApiCall = () => {
        // create a payload with from and to week date
        const payload: CopySchedulePayloadType = {
            scheduleId: scheduleId,
            startDate: dayjs(copyToWeek).startOf('week').format("YYYY-MM-DD"),
            endDate: dayjs(copyToWeek).endOf('week').format("YYYY-MM-DD")
        }
        // API call
        validateCopyEntireSchedule(scheduleMutation, payload, onValidateSuccess, onError)
    }

    const validateCopyIndividualScheduleApiCall = () => {
        const tenantStaffIds = selectedUsers.map(user => user.code)

        // create a payload with from and to week date
        const payload: CopyScheduleIndividualPayloadType = {
            scheduleId: scheduleId,
            tenantStaffIds: tenantStaffIds,
            startDate: dayjs(copyToWeek).startOf('week').format("YYYY-MM-DD"),
            endDate: dayjs(copyToWeek).endOf('week').format("YYYY-MM-DD")
        }
        // API call
        validateCopyIndividualSchedule(scheduleMutation, payload, onValidateSuccess, onError)
    }

    const copyIndividualScheduleApiCall = () => {
        const tenantStaffIds = selectedUsers.map(user => user.code)

        // create a payload with from and to week date
        const payload: CopyScheduleIndividualPayloadType = {
            scheduleId: scheduleId,
            tenantStaffIds: tenantStaffIds,
            startDate: dayjs(copyToWeek).startOf('week').format("YYYY-MM-DD"),
            endDate: dayjs(copyToWeek).endOf('week').format("YYYY-MM-DD")
        }
        // API call
        copyIndividualSchedule(scheduleMutation, payload, onCopySuccess, onError)
    }

    const isTodayWithinSelectedWeek = () => {
        const today = dayjs().startOf('day')
        const endOfWeek = selectedWeek.endOf('week')
        today.isSameOrBefore(endOfWeek) ? setIsDateOlder(false) : setIsDateOlder(true)
    }

    const reloadPageData = () => {
        setScheduleData(null)
        setScheduleId("")
        setDraftedScheduleId("")
        setPublishedScheduleId("")
        scheduleDataQuery.refetch()
    }

    const resetPageFilters = () => {
        setSelectedDepartment(null)
        setSelectedRole(null)
        setSelectedShift(null)
        setSelectedUsers([])
        setFilteredUsers([])
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const filteredStaffsWithSchedule: ScheduleData = res.data.data;
        const { schedule } = filteredStaffsWithSchedule;

        const sortStaffByPreferredName = (filteredStaffsWithSchedule: ScheduleData) => {
            // Separate staffs into two groups: with shifts and without shifts
            const staffsWithShifts = filteredStaffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length > 0);
            const staffsWithoutShifts = filteredStaffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length === 0);

            // Sort each group by preferredName
            staffsWithShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));
            staffsWithoutShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));

            // Combine the sorted arrays, with staff with shifts first
            const sortedStaffs = [...staffsWithShifts, ...staffsWithoutShifts];

            // Update the staffs in the original object
            return { ...filteredStaffsWithSchedule, staffs: sortedStaffs };
        };

        const sortedStaffsWithSchedule = sortStaffByPreferredName(filteredStaffsWithSchedule);
        setStaffsWithSchedule(sortedStaffsWithSchedule);
        setFilteredStaffsWithSchedule(sortedStaffsWithSchedule);
        setScheduleMetadata(schedule?.scheduleMetadata ?? {} as ScheduleMetadata);
        setPageInitialLoad(false);
        setDateChangeDataLoading(false);
    };

    const onErrorOfStaffsWithSchedule = () => {
        setDateChangeDataLoading(false);
    }

    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        setScheduleData(scheduleData)
        // Destructure draft and published from scheduleData
        const { draft, published } = scheduleData

        // Check if draft and published schedules are empty
        const isDraftEmpty = isEmptyObject(draft)
        const isPublishedEmpty = isEmptyObject(published)

        // If draft is not empty, save the draft's id using saveDraftedScheduleId
        if (!isDraftEmpty) saveDraftedScheduleId(draft.id)

        // If published is not empty, save the published's id using savePublishedScheduleId
        if (!isPublishedEmpty) savePublishedScheduleId(published.id)
    }

    const onSuccessOfDraftCreated = (res: AxiosResponse<any, any>) => {
        const draftData: DraftResponse = res.data.data
        saveDraftedScheduleId(draftData.id)
    }

    const onSuccessOfGlobalStaffing = (res: AxiosResponse<any, any>) => {
        const staffingData: PerDayCountForListing[] = res.data.data.lists
        // Sort data to match the order of the pascalCaseDays array
        const sortedData = days.map(dayName => staffingData.find(weekDay => weekDay.dayName.toLowerCase() === dayName));
        setStaffingRequirement(sortedData as PerDayCountForListing[])
    }

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onSuccessWithoutMessage = (message: string, variant: string) => {
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const onRoleListSuccess = (res: AxiosResponse<any, any>) => {
        let data: JobRole[] | [] = res.data?.data.lists
        let departments: ScheduleDepartmentDropDown[] = []
        let roleDetails: ScheduleRoleDropDown[] = []

        data.forEach((item) => {
            const { id, jobRoleName, jobRoleDepartment, isAvailableForSchedule } = item

            if (!isAvailableForSchedule) return;

            if (jobRoleDepartment.departmentName) {
                departments.push({
                    departmentId: jobRoleDepartment.id,
                    depName: jobRoleDepartment.departmentName ?? "",
                })
            }

            roleDetails.push({
                code: id,
                name: jobRoleName,
                roleDepartmentName: jobRoleDepartment.departmentName ?? "",
                roleDepartmentId: jobRoleDepartment.id ?? "",
            })

            roleDetails.sort((a, b) => a.name.localeCompare(b.name));
        })

        // Use Set to remove duplicates based on the department ID
        const uniqueDepartments = Array.from(
            new Set(departments.map(dep => dep.departmentId))
        ).map(code => departments.find(dep => dep.departmentId === code)!);

        setRoles(roleDetails);
        setDepartments(uniqueDepartments);
    }

    const onShiftConfigSuccess = (res: AxiosResponse<any, any>) => {
        const data: ShiftTemplate[] = res.data?.data.lists
        let shiftDetails: ShiftDropDown[] = []
        data.forEach((item) => {
            const { shiftName, startTime, endTime } = item
            shiftDetails.push({
                code: shiftName,
                name: shiftName,
                startTime: startTime,
                endTime: endTime
            })
        })
        shiftDetails.push({ code: 'Custom Shift', name: 'Custom Shift', startTime: '00:00:00', endTime: '00:00:00' });
        // Sort shiftDetails by startTime
        shiftDetails.sort((a, b) => a.startTime.localeCompare(b.startTime));
        setShifts(shiftDetails)
    }

    const resetScheduleData = () => {
        const res = queryClient.getQueryData(STAFFS_WITH_SCHEDULES_AND_SHIFT) as AxiosResponse<any, any>
        if (res) onSuccessOfStaffsWithSchedule(res)
    }

    const filterStaff = ({
        staffArray,
        roleId,
        shiftName,
        departmentId
    }: {
        staffArray: Staff[];
        roleId?: string;
        shiftName?: string;
        departmentId?: string;
    }) => {
        // filter staff by filteredUsers
        const staffCopy = cloneDeep(staffArray)
        const data = staffCopy.filter(staff => {
            // Filter shifts by matching roleId, shiftName, and departmentId dynamically across all dates
            const filteredShifts = Object.keys(staff.shifts).reduce((acc, date) => {
                const matchingShifts = staff.shifts[date].filter(shift => {
                    const matchesDepartment = departmentId ? shift.assignedShifts.departmentId === departmentId : true;
                    const matchesRole = roleId ? shift.assignedShifts.roleId === roleId : true;
                    const matchesShiftName = shiftName ? shift.assignedShifts.shiftName === shiftName : true;

                    // Only include shifts that match all criteria
                    return matchesRole && matchesShiftName && matchesDepartment;
                });

                if (matchingShifts.length > 0) {
                    acc[date] = matchingShifts; // Include only matching shifts for this date
                }
                return acc;
            }, {} as ShiftsByDate);

            // Check if the staff has any shifts left after filtering
            const hasAnyShiftsLeft = Object.keys(filteredShifts).length > 0;

            // If the staff has any matching shifts, update their shifts and include them in the result
            if (hasAnyShiftsLeft) {
                staff.shifts = filteredShifts; // Update only in the copy
                return true;
            }
            return false;
        });

        return data;
    };

    const saveDraftedScheduleId = (id: string) => setDraftedScheduleId(id)
    const savePublishedScheduleId = (id: string) => setPublishedScheduleId(id)
    const saveEmployeeName = (name: string) => setSelectedEmployeeName(name)

    // fetch shift list (api call)
    useQueryHook(SHIFT_CONFIG_LIST, () => getShiftConfigList(), onShiftConfigSuccess)
    // fetch role list (api call)
    useQueryHook(ROLES_LIST, () => getRolesList({ limit: 500 }), onRoleListSuccess)
    // api call to fetch staffing requirements
    const staffingQuery = useQueryHook(STAFFING_REQUIREMENTS_LIST, () => getGlobalStaffing(), onSuccessOfGlobalStaffing)
    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(SCHEDULE_STATUS, () => fetchDraftedAndPublishedScheduleIds(selectedDateRange), onSuccessOfScheduleStatus)
    // If both the draft and published IDs are empty, call the Create Draft API (api call)
    const isDraftEmpty = scheduleData?.draft && isEmptyObject(scheduleData.draft); // Check if the draft schedule ID is empty
    const isPublishedEmpty = scheduleData?.published && isEmptyObject(scheduleData.published); // Check if the published schedule ID is empty
    const createDraft = scheduleData && isDraftEmpty && isPublishedEmpty; // Determine if Create Draft API should be called
    //  schedule data dependent QUERY-2
    const draftDataQuery = useQueryHook(CREATE_DRAFT_SCHEDULE, () => createDraftSchedule(selectedDateRange), onSuccessOfDraftCreated, undefined, !!createDraft)

    // Determine which schedule ID to use
    useEffect(() => {
        const scheduleId = getScheduleId(draftedScheduleId, publishedScheduleId);
        setScheduleId(scheduleId)
    }, [draftedScheduleId, publishedScheduleId])

    //  schedule data dependent QUERY-3
    const staffsWithScheduleQuery = useQueryHook(
        STAFFS_WITH_SCHEDULES_AND_SHIFT, 
        () => fetchStaffsWithSchedulesAndShift({ scheduleId }), 
        onSuccessOfStaffsWithSchedule, 
        onErrorOfStaffsWithSchedule, 
        !!scheduleId
    )

    // assigning API data to state variables
    useEffect(() => {
        if (filteredStaffsWithSchedule) {
            const { schedule } = filteredStaffsWithSchedule;
            setScheduleStatus(schedule);
        }
    }, [filteredStaffsWithSchedule]); // Run effect when filteredStaffsWithSchedule data changes

    // filter schedules
    useEffect(() => {
        if (staffsWithSchedule && (selectedRole?.code || selectedShift?.code || selectedDepartment?.departmentId || filteredUsers?.length)) {
            const clonedSchedules = cloneDeep(staffsWithSchedule);

            // filter staff by filteredUsers
            const filteredStaff = clonedSchedules?.staffs.filter(staff => {
                if(filteredUsers.length) {
                    return filteredUsers.includes(staff.id)
                }
                return true
            })
            
            // prioritize staff filter over other filters
            if(!selectedRole?.code && !selectedShift?.code && !selectedDepartment?.departmentId) {
                setFilteredStaffsWithSchedule({ ...clonedSchedules, staffs: filteredStaff });
            } else {
                const filterStaffByRole = filterStaff({ 
                    staffArray: filteredStaff, 
                    roleId: selectedRole?.code, 
                    shiftName: selectedShift?.name, 
                    departmentId: selectedDepartment?.departmentId,
                });
                setFilteredStaffsWithSchedule({ ...clonedSchedules, staffs: filterStaffByRole });
            }
        } else if (!selectedRole?.code && !selectedShift?.code && !selectedDepartment?.departmentId && !filteredUsers?.length) {
            resetScheduleData();
        }
    }, [selectedRole, selectedShift, selectedDepartment, submitCounter, filteredUsers]); // eslint-disable-line

    // refetch the staff schedule api once the selected date has changed 
    useEffect(() => {
        reloadPageData();
        resetPageFilters();
    }, [selectedDateRange]) // eslint-disable-line

    // Calculate the date range for the selected week
    useEffect(() => {
        const dateRange = getWeekRange(selectedWeek)
        setSelectedDateRange(dateRange)
        isTodayWithinSelectedWeek()
        setDateChangeDataLoading(true)
    }, [selectedWeek]) // eslint-disable-line

    // check expected vs current staff count
    const checkStaffCount = () => {
        const staffCount = checkStaffSchedule(staffCountByDay)
        setIsCountOk(staffCount)
    }
    // used to rerun the filterStaff function
    const submitCount = () => {
        setSubmitCounter((current) => current + 1)
    }

    // Initialize filteredRoles when component mounts or roles change
    useEffect(() => {
        if (selectedDepartment) {
            // Filter roles based on the selected department
            const filtered = roles.filter(
                (role) => role.roleDepartmentId === selectedDepartment.departmentId
            );
            setFilteredRoles(filtered);
        } else {
            // If no department is selected, set all roles
            setFilteredRoles(roles);
        }
    }, [selectedDepartment, roles]);


    const handleDepartmentChange = (e: DropdownChangeEvent) => {
        resetScheduleData();
        setSelectedDepartment(e.value);

        // Filter roles based on selected department
        const departmentId = e.value?.departmentId;
        const filtered = roles.filter(role => role.roleDepartmentId === departmentId);
        setFilteredRoles(filtered);
    };

    const scheduleFilters = (
        <FilterWrapper
            isFiltered={!!selectedDepartment || !!selectedRole || !!selectedShift}
            clearFilter={() => {
                setSelectedDepartment(null)
                setSelectedRole(null)
                setSelectedShift(null)
            }}
        >
            <Dropdown
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                options={departments}
                optionLabel="name"
                placeholder={AREA_OF_OPERATION}
                filter
                filterBy="depName"
                showClear
                disabled={!departments.length}
                valueTemplate={depSelectionTemplate}
                itemTemplate={depOptionTemplate}
                className="w-100"
                panelClassName="full-screen-dropdown-panel"
            />
            <Dropdown
                value={selectedRole}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedRole(e.value)
                }}
                options={filteredRoles}
                optionLabel="name"
                placeholder={ROLE}
                filter
                disabled={!filteredRoles.length}
                showClear
                valueTemplate={selectionTemplate}
                itemTemplate={optionTemplate}
                className="w-100 mt-2"
                panelClassName="full-screen-dropdown-panel"
            />
            <Dropdown
                value={selectedShift}
                onChange={(e: DropdownChangeEvent) => {
                    resetScheduleData()
                    setSelectedShift(e.value)
                }}
                options={shifts}
                optionLabel="name"
                placeholder={SHIFT}
                filter
                filterBy='name'
                disabled={!shifts.length}
                showClear
                valueTemplate={shiftSelectionTemplate}
                itemTemplate={optionTemplateWithTime}
                className="w-100 mt-2"
                panelClassName="full-screen-dropdown-panel"
            />
        </FilterWrapper>
    )

    const getModalTitle = () => {
        if (conflictCount === 1) {
            return `${conflictCount} Conflict`
        } else if (conflictCount > 1) {
            return `${conflictCount} Conflicts`
        } else {
            return COPY_SCHEDULE
        }
    }

    const PublishOrEditContent = (
        <div>
            {
                (scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft')
                    ? !isCountOk ? `${STAFF_SCHEDULE_DISCREPANCY} ${CONFIRM_PUBLISH_SCHEDULE}` : CONFIRM_PUBLISH_SCHEDULE
                    : CONFIRM_UPDATE_SCHEDULE
            }
        </div>
    )

    const ClearContent = <div>{CONFIRM_CLEAR_DRAFT}</div>;

    // loading screen
    if (
        pageInitialLoad
    ) {
        return <Loader />;
    }

    // error screen
    if (scheduleDataQuery.status === "error" || draftDataQuery.status === "error" || staffsWithScheduleQuery.status === "error") {
        return (

            <div className="text-center d-flex flex-column align-items-center justify-content-center">
                <img src={scheduleError} alt="" className="img-fluid mb-3" />
                <h2><JTranslation typeCase="pascal" text={'Oops!!'} /></h2>
                <h5 className="mt-2 mb-3">
                    <div className='mb-1 error-text-schedule'><JTranslation typeCase="pascal" text={"Something went wrong.We're sorry, but an error occurred while loading this page."} /></div>
                    <div className='error-text-schedule'><JTranslation typeCase="pascal" text={'Please try refreshing the page or come back later.'} /></div>
                </h5>
                <div className='mt-2 mb-2'>
                    <button
                        className="btn btn-custom-primary d-flex">
                        <i className="ri-restart-line me-1"></i>
                        <span className="btn-text"><JTranslation typeCase="pascal" text={'Refresh Page'} /></span>
                    </button>
                </div>
            </div>
        )
    }

    if (staffsWithScheduleQuery.status === "success" && staffingQuery.status === "success") {
        return (
            <>
                <TieredMenu model={copyMenuItems} popup ref={copyScheduleMenu} breakpoint="767px" />
                <Dialog
                    ref={scheduleDialogRef}
                    visible={showPrintPreview}
                    maximized={true}
                    showHeader={false}
                    onHide={() => {
                        if (!showPrintPreview) return;
                        setShowPrintPreview(false);
                    }}
                    pt={{
                        content: {
                            style: { padding: '0px' }
                        }
                    }}
                >
                    <div className='h-100 w-100'>
                        <PrintPreview 
                            reloadPageDataSchedule={() => {
                                setSelectedUsers([]);
                                setSelectedWeek(dayjs());
                                reloadPageData();
                            }} 
                            setShowPrintPreview={setShowPrintPreview} 
                            scheduleDialogRef={scheduleDialogRef} 
                            filterProps={{
                                selectedWeek: selectedWeek,
                                areaOfOperation: selectedDepartment?.departmentId,       
                                // TODO: Role and shift filter, yet to be added               
                            }}
                        />
                    </div>
                </Dialog>
                <CommonModal
                    show={(visible.publish || visible.edit || visible.clear)}
                    onHide={() => setVisible({ publish: false, edit: false, clear: false })}
                    modalContent={visible.clear ? ClearContent : PublishOrEditContent}
                    title="Review and Confirm Changes"
                    callback={accept}
                />

                <CommonModal
                    show={showCopyScheduleModal}
                    onHide={() => {
                        setShowCopyScheduleModal(false)
                        setCopyToWeek(defaultCopyToWeek)
                        setConflictMessages([])
                    }}
                    modalContent={conflictCount ? ConflictListModalContent : CopyScheduleModalContent}
                    title={getModalTitle()}
                    callback={() => {
                        if (copyScheduleMode === "full") {
                            conflictMessages.length
                                ? copyScheduleApiCall()
                                : validateCopyScheduleApiCall()
                        } else {
                            conflictMessages.length
                                ? copyIndividualScheduleApiCall()
                                : validateCopyIndividualScheduleApiCall()
                        }
                    }
                    }
                    hideFooter={Boolean(conflictCount)}
                    proceedButtonName={COPY_SCHEDULE}
                />

                <div className="page-title d-flex mb-3 align-items-center">
                    <div className="flex-grow-1 d-flex">
                        <span className="d-flex">
                            <h5 className="d-flex mb-0 align-items-center">
                                <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                                <MlxPopover data={{ title: WORK_TIME_SCHEDULE, body: WORK_TIME_SCHEDULE_HELP }} />
                            </h5>
                        </span>
                        <span className="d-flex align-items-center">
                            {(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft')
                                ? <Badge
                                    value={<JTranslation typeCase="pascal" text={DRAFT} />}
                                    //severity="danger"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap draft-badge"
                                />
                                : <Badge
                                    value={<JTranslation typeCase="pascal" text={PUBLISHED} />}
                                    severity="success"
                                    size={'large'}
                                    className="schedule-title-badge ms-2 text-nowrap"
                                />}
                        </span>
                    </div>
                    <div className="flex-grow-0 align-items-center">
                        {/* Fullscreen preview */}
                        {width >= breakPoint && (
                            <Button
                                icon="ri-fullscreen-line"
                                className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                                title="Fullscreen"
                                onClick={() => {
                                    setShowPreview(true)
                                }}
                            />
                        )}

                        <Button
                            icon="ri-settings-4-line "
                            className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                            title={STAFF_COUNT}
                            data-testid="global-config"
                            onClick={() => {
                                setShowSideBar(!showSideBar)
                            }}
                        />
                    </div>
                </div>
                {/* TOAST MESSAGE COMPONENT */}
                <ToastAlert
                    show={toastMessage.show}
                    onClose={() => setToastMessage(toastMessageInitialData)}
                    message={toastMessage.message}
                    variant={toastMessage.variant}
                />

                {/*SIDE BARS  */}
                <MinimumEmployeeCountSideBar setToastMessage={setToastMessage} />
                <ShiftScheduleSideBar
                    shiftSideBar={shiftSideBar}
                    setShiftSideBar={setShiftSideBar}
                    setToastMessage={setToastMessage}
                    selectedWeek={selectedWeek}
                    selectedEmployeeName={selectedEmployeeName}
                    setSelectedEmployeeName={setSelectedEmployeeName}
                    rolesList={roles}
                    departments={departments}
                    shifts={shifts}
                    scheduleId={scheduleId}
                    staffDataWithSchedule={staffDataWithSchedule}
                    selectedDay={selectedDay}
                    staffsWithSchedule={filteredStaffsWithSchedule}
                    submitCount={submitCount}
                    schedulerOpenFlag={schedulerOpenFlag}
                    setSchedulerOpenFlag={setSchedulerOpenFlag}
                    setShifts={setShifts}
                />
                <div className={`col-md-12 col-lg-12 mb-3 h-100 ${showPreview ? 'mlx-fullscreen' : ''}`}>
                    <div className="card h-100 ">
                        <div className="card-header flex-grow-0 work-schedule">
                            <div className="row align-items-center">
                                <div className="col align-items-center d-flex justify-content-start">
                                    <MultiSelect
                                        className='me-2 search-dropdown'
                                        data-testid='users-select'
                                        value={filteredUsers}
                                        options={usersListOptions}
                                        itemTemplate={(option: any) => (
                                            <div className='d-flex flex-row align-items-center justify-content-between' key={option.code}>
                                                <span>{toPascalCase(option.name)}</span>
                                                {!option.isAvailableForSchedule && <i className="ri-calendar-close-line text-danger ms-2"></i>}
                                            </div>
                                        )}
                                        onChange={(e) => {
                                            setFilteredUsers(e.value)
                                        }}
                                        panelClassName={'custom-multiselect'}
                                        optionLabel='name'
                                        showSelectAll={true}
                                        maxSelectedLabels={1}
                                        optionValue='code'
                                        filter={true}
                                        pt={{ 
                                            root: { style: { maxWidth: '42px', borderRadius: '3px' } },
                                            header: { style: { padding: '10px' } },
                                            filterContainer: { className: 'custom-multiselect-filter-container' },
                                            closeButton: { style: { display: 'none' } },
                                            // labelContainer: { style: { display: 'none' } }
                                        }}
                                        panelStyle={{ maxWidth: '250px' }}
                                        resetFilterOnHide={true}
                                        optionDisabled={(option: any) => !option.isAvailableForSchedule}
                                        showClear={false}
                                        placeholder={''}
                                        selectedItemsLabel={''}
                                        dropdownIcon={
                                            filteredUsers.length > 0 
                                                ? <i className="pi pi-users" style={{ fontSize: '1.15rem', fontWeight: '500' }}></i>
                                                : <i className="pi pi-search" style={{ fontSize: '1.15rem', fontWeight: '500' }}></i>
                                        }
                                        panelFooterTemplate={(
                                            <div className='px-2 py-2 shadow-lg d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    {filteredUsers.length > 0 && (
                                                        <JTranslation 
                                                            typeCase="none" 
                                                            text={`${filteredUsers.length} user${filteredUsers.length > 1 ? 's' : ''}`} 
                                                        />                                                 
                                                    )}
                                                </div>
                                                <Button 
                                                    label='clear'
                                                    className="btn btn-custom-primary fw-normal"
                                                    onClick={() => setFilteredUsers([])}
                                                    size='small'
                                                    style={{ padding: '0.25rem 0.5rem' }}
                                                />
                                            </div>
                                        )}
                                    />
                                    {scheduleFilters}
                                </div>
                                <div className="col align-items-center d-flex justify-content-between">
                                    <WeekFilter
                                        onChange={(date, dateString) => {
                                            const selectedDate = date ? dayjs(date) : dayjs()
                                            setSelectedWeek(selectedDate)
                                            setSelectedUsers([])
                                        }}
                                        selectedWeek={selectedWeek}
                                        setPrevSelectedWeek={setSelectedWeek}
                                        setNextSelectedWeek={setSelectedWeek}
                                        size={width < 415 ? 'sm' : 'lg'}
                                        format={width < 415 ? undefined : DATE_FORMAT}
                                    />
                                </div>
                                <div className={`col align-items-center d-flex justify-content-end ${width < 768 ? 'mt-2' : ''}`}>
                                    <div className='cldr-action-icon'>
                                        {(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft') && (
                                            <i 
                                                className={`pi pi-trash ${
                                                    staffsWithScheduleQuery.isFetching || 
                                                    scheduleDataQuery.isFetching || 
                                                    draftDataQuery.isFetching || 
                                                    isDateOlder ? 'disabled' : ''
                                                }`}
                                                role='button'
                                                title={CLEAR_DRAFT}
                                                onClick={() => {
                                                    if(
                                                        staffsWithScheduleQuery.isFetching || 
                                                        scheduleDataQuery.isFetching || 
                                                        draftDataQuery.isFetching || 
                                                        isDateOlder
                                                    ) return;

                                                    // preventing accidental draft publish
                                                    scheduleStatus.actionStatus = 'clear-draft'
                                                    setVisible({ edit: false, publish: false, clear: true })
                                                }}
                                            ></i>
                                        )}
                                    </div>

                                    {(
                                        // don't show if the selected week is not the current week or before the current week
                                        dayjs().isBetween(selectedWeek.isoWeekday(1), selectedWeek.isoWeekday(7), 'day', '[]') ||
                                        !selectedWeek.isoWeekday(7).isBefore(dayjs(), 'day')
                                    ) && (
                                        <div className='cldr-action-icon'>
                                            <i 
                                                className={`pi pi-pencil ${
                                                    scheduleStatus.actionStatus === 'draft' || 
                                                    scheduleStatus.actionStatus === 'clear-draft' || 
                                                    scheduleMutation.isLoading ||
                                                    staffsWithScheduleQuery.isFetching ||
                                                    scheduleDataQuery.isFetching ||
                                                    isDateOlder ? 'disabled' : ''
                                                }`}
                                                role='button'
                                                title={EDIT}
                                                onClick={() => {
                                                    if(
                                                        scheduleStatus.actionStatus === 'draft' || 
                                                        scheduleStatus.actionStatus === 'clear-draft' || 
                                                        scheduleMutation.isLoading || 
                                                        staffsWithScheduleQuery.isFetching ||
                                                        scheduleDataQuery.isFetching ||
                                                        isDateOlder
                                                    ) return;

                                                    setVisible({ edit: true, publish: false, clear: false })
                                                }}
                                            ></i>
                                        </div>
                                    )}

                                    <div className='cldr-action-icon'>
                                        <i 
                                            className={`pi pi-copy ${
                                                scheduleMutation.isLoading || 
                                                staffsWithScheduleQuery.isFetching ||
                                                scheduleDataQuery.isFetching
                                                    ? 'disabled' : ''
                                            }`}
                                            role='button'
                                            title={COPY_SCHEDULE}
                                            onClick={(e) => {
                                                if(scheduleMutation.isLoading) return;

                                                (copyScheduleMenu as any)?.current?.toggle(e)
                                            }}
                                        ></i>
                                    </div>
                                    <div className='cldr-action-icon'>
                                        <i 
                                            className={`pi pi-print ${
                                                scheduleMutation.isLoading || 
                                                staffsWithScheduleQuery.isFetching || 
                                                scheduleStatus.actionStatus !== 'published' ||
                                                scheduleDataQuery.isFetching
                                                    ? 'disabled' : ''
                                            }`}
                                            role='button'
                                            title={'Print Preview'}
                                            onClick={() => {
                                                if(scheduleMutation.isLoading || scheduleStatus.actionStatus !== 'published') return;

                                                setShowPrintPreview(!showPrintPreview)
                                            }}
                                        ></i>
                                    </div>

                                    <Button
                                        className="publish-button ms-3 text-nowrap prime-icon-only-btn rounded ewts-published"
                                        tooltipOptions={{ position: 'bottom' }}
                                        disabled={
                                            scheduleStatus.actionStatus === 'published' || 
                                            scheduleMutation.isLoading || 
                                            staffsWithScheduleQuery.isFetching || 
                                            scheduleDataQuery.isFetching ||
                                            isDateOlder
                                        }
                                        onClick={() => {
                                            checkStaffCount()
                                            setVisible({ edit: true, publish: true, clear: false })
                                        }}
                                        icon={<i 
                                            className={`
                                                ${
                                                    scheduleMutation.isLoading || 
                                                    staffsWithScheduleQuery.isFetching || 
                                                    scheduleDataQuery.isFetching
                                                        ? 'pi pi-spin pi-spinner' : 'ri-send-plane-2-line'
                                                } me-1
                                            `}></i>
                                        }
                                        pt={{
                                            root: { style: { padding: '0.375rem 1.25rem' } },
                                            icon: { style: { fontSize: '1.5rem' } }
                                        }}
                                    >
                                        <span className="btn-text overflow-ellipsis">
                                            {
                                                scheduleMutation.isLoading || 
                                                staffsWithScheduleQuery.isFetching || 
                                                scheduleDataQuery.isFetching
                                                ? (
                                                    <JTranslation
                                                        typeCase="pascal"
                                                        text={'Loading...'}
                                                    />
                                                ) : (
                                                    <JTranslation
                                                        typeCase="pascal"
                                                        text={(scheduleStatus.actionStatus === 'draft' || scheduleStatus.actionStatus === 'clear-draft') ? PUBLISH : PUBLISHED}
                                                    />
                                                )
                                            }
                                        </span>
                                    </Button>

                                    {width >= breakPoint && (
                                        <Button
                                            hidden={!showPreview}
                                            rounded
                                            outlined
                                            icon="ri-fullscreen-exit-line"
                                            className="ms-3 p-button-primary color-primary-custom"
                                            title="Close Preview"
                                            onClick={() => {
                                                setShowPreview(false)
                                            }}
                                            pt={{
                                                root: { style: { height: '2.5rem', width: '2.5rem' } },
                                                icon: { style: { fontSize: '1.5rem' } }
                                            }}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                        {(staffsWithScheduleQuery.isLoading || dateChangeDataLoading)
                            ? <Loader />
                            : (
                                <div className="card-body flex-grow-1 overflow-auto p-0">
                                    <ConfirmPopup />
                                    <div className="survey-section h-100">
                                        {/* switch web and mobile components */}
                                        {width >= breakPoint ? (
                                            <ShiftScheduler
                                                shiftSideBar={shiftSideBar}
                                                setShiftSideBar={setShiftSideBar}
                                                saveEmployeeName={saveEmployeeName}
                                                selectedWeek={selectedWeek}
                                                staffsWithSchedule={filteredStaffsWithSchedule}
                                                staffingRequirement={staffingRequirement}
                                                scheduleId={scheduleId}
                                                setToastMessage={setToastMessage}
                                                reloadPageData={reloadPageData}
                                                setData={setStaffDataWithSchedule}
                                                setSelectedDay={setSelectedDay}
                                                scheduleMetadata={scheduleMetadata}
                                                selectedUsers={selectedUsers}
                                                setSelectedUsers={setSelectedUsers}
                                                isLoading={staffsWithScheduleQuery?.isRefetching ?? false}
                                                isDateOlder={isDateOlder}
                                            />
                                        ) : (
                                            <ShiftSchedulerMobileView
                                                shiftSideBar={shiftSideBar}
                                                setShiftSideBar={setShiftSideBar}
                                                saveEmployeeName={saveEmployeeName}
                                                selectedWeek={selectedWeek}
                                                staffsWithSchedule={filteredStaffsWithSchedule}
                                                staffingRequirement={staffingRequirement}
                                                scheduleId={scheduleId}
                                                setToastMessage={setToastMessage}
                                                reloadPageData={reloadPageData}
                                                setData={setStaffDataWithSchedule}
                                                setSelectedDay={setSelectedDay}
                                                scheduleMetadata={scheduleMetadata}
                                                isDateOlder={isDateOlder}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }

}

export default withSidebar(ManageWorkSchedule)

