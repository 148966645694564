import React from "react";
import { Field, ErrorMessage, FormikErrors, FormikTouched, FieldProps } from 'formik';
import { detectOS } from '../../helpers/utils';
import { JTranslation } from '../../helpers/jTranslate';
import { MinimumEmployeeCountType, PerDayCountForListing } from '../../constants/staticTypes';
import { InputNumber } from "antd";
import { capitalize } from "lodash";

type Props = {
    errors: FormikErrors<MinimumEmployeeCountType>;
    touched: FormikTouched<MinimumEmployeeCountType>;
    weekDayIndex: number;
    weekDay: PerDayCountForListing
};

const StaffCountCard = ({ weekDayIndex, errors, touched, weekDay }: Props) => {
    const currentOs = detectOS();

    const OneColumnLayout = () => {
        return (
            <>
                {weekDay.jobRoles.map((role, index) => (
                    <div className="my-3 row" key={role.id}>
                        <label>{role.jobRoleName}</label>
                        <Field
                            className='col'
                            name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                        >
                            {({ field, form }: FieldProps) => (
                                // <InputNumber
                                //     id={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                //     data-testid={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                //     value={Number(field.value)}
                                //     className='input-number-custom'
                                //     onValueChange={(event) => form.setFieldValue(field.name, event.value)}
                                //     style={
                                //         errors &&
                                //             (errors[weekDayIndex] as any)?.jobRoles?.[index]?.minCount &&
                                //             touched && // @ts-ignore
                                //             (touched[weekDayIndex]?.jobRoles?.[index]?.minCount)
                                //             ? { border: '1px solid red' }
                                //             : undefined
                                //     }
                                //     showButtons={true}
                                //     useGrouping={false}
                                //     mode="decimal"
                                //     min={0} max={999}
                                // />
                                <InputNumber
                                    id={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                    data-testid={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                    value={Number(field.value)}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                    min={0}
                                    max={999}
                                    style={{ width: '70px', minWidth: '70px' }}
                                />
                            )}
                        </Field>
                        <ErrorMessage
                            className="formik-error"
                            name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                            component="div"
                            render={(error) => (
                                <span className="formik-error">
                                    <JTranslation text={error} />
                                </span>
                            )}
                        />
                    </div>
                ))}
            </>
        )
    }

    const ThreeColumnLayout = () => {
        return (
            <div className="row">
                {weekDay.jobRoles.map((role, index) => (
                    <div 
                        className="my-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-md-flex d-lg-flex align-items-center flex-md-column flex-lg-row 
                    align-items-md-start align-items-lg-center justify-content-between" 
                        key={role.id}
                    >
                        <label>{capitalize(role?.jobRoleName ?? '')}</label>
                        <Field
                            className='col'
                            name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                        >
                            {({ field, form }: FieldProps) => (
                                // <InputNumber
                                //     id={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                //     data-testid={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                //     value={Number(field.value)}
                                //     className='input-number-custom'
                                //     onValueChange={(event) => form.setFieldValue(field.name, event.value)}
                                //     style={
                                //         errors &&
                                //             (errors[weekDayIndex] as any)?.jobRoles?.[index]?.minCount &&
                                //             touched && // @ts-ignore
                                //             (touched[weekDayIndex]?.jobRoles?.[index]?.minCount)
                                //             ? { border: '1px solid red' }
                                //             : undefined
                                //     }
                                //     showButtons={true}
                                //     useGrouping={false}
                                //     mode="decimal"
                                //     min={0} max={999}
                                //     size={2}
                                // />
                                <InputNumber
                                    id={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                    data-testid={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                                    value={Number(field.value)}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                    min={0}
                                    max={999}
                                    style={{ width: '70px', minWidth: '70px' }}
                                />
                            )}
                        </Field>
                        <ErrorMessage
                            className="formik-error"
                            name={`[${weekDayIndex}].jobRoles.[${index}].minCount`}
                            component="div"
                            render={(error) => (
                                <span className="formik-error">
                                    <JTranslation text={error} />
                                </span>
                            )}
                        />
                    </div>

                ))}
            </div>
        )
    }

    if (currentOs === 'ios' || currentOs === 'mac' || currentOs === 'android') {
        return <OneColumnLayout />
    } else {
        return <ThreeColumnLayout />
    }

};


export default React.memo(StaffCountCard);
