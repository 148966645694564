import dayjs from "dayjs";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { AlertVariant, DATE_FORMAT, toastMessageInitialData } from "../../constants/constants";
import { REJECT, APPROVE, REJECTING_TIME_OFF_REQUEST, NOTE, REJECT_TIME_OFF } from "../../constants/strings";
import { JTranslation } from "../../helpers/jTranslate";
import { formatTimeToAmPm } from "../../helpers/utils";
import { UnavailableDay } from "../../helpers/workTimeScheduleHelper";
import CustomTooltip from "./CustomTooltip";
import { Badge } from "primereact/badge";
import { useState } from "react";
import { useQueryClient } from "react-query";
import useMutationHook from "../../hooks/useMutationHook";
import { AddTimeOffResponse, TimeOffRequestStatus, TimeOffStatuses, ToastMessageProps } from "../../constants/staticTypes";
import { timeOffChangeRequest } from "../../helpers/timeOffHelper";
import CommonModal from "../common_modal/CommonModal";
import ToastAlert from "../alert/ToastAlert";
import { STAFFS_WITH_SCHEDULES_AND_SHIFT } from "../../constants/queryKeys";

type TimeOffCardProps = {
    unavailability: UnavailableDay[],
    type?: 'schedule' | 'others',
    actionButton?: boolean,
    date: string
}

function TimeOffCard({
    unavailability,
    type = 'others',
    actionButton = false,
    date
}: Readonly<TimeOffCardProps>
) {
    const queryClient = useQueryClient()
    const timeOffMutation = useMutationHook(queryClient, true)


    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [rejectTimeOffId, setRejectTimeOffId] = useState('')
    const [rejectReason, setRejectReason] = useState('')
    const [showWarning, setShowWarning] = useState(false)

    const rejectRequestModalContent = (
        <>
            <div><JTranslation typeCase="capitalize" text={REJECTING_TIME_OFF_REQUEST} /></div>
            <p className="mt-3 mb-0">
                <JTranslation typeCase="pascal" text={NOTE} />
                <span className="mandatory ">*</span>
            </p>
            <textarea
                name="reject-reason"
                className="form-control reason"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
            />
        </>
    )

    const closeRejectionModal = () => {
        setShowWarning(false)
        setRejectReason('')
        setRejectTimeOffId('')
    }

    const onSuccess = (res: AddTimeOffResponse) => {
        setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
        queryClient.fetchQuery([STAFFS_WITH_SCHEDULES_AND_SHIFT])
    }

    const onError = (message: string) => {
        setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
    }

    const changeRequestStatus = (timeOffId: string, status: TimeOffStatuses) => {
        const params: TimeOffRequestStatus = { id: timeOffId, newStatus: status, note: rejectReason }
        // approve api call
        closeRejectionModal();
        timeOffChangeRequest(timeOffMutation, params, onSuccess, onError)
    }

    // Filter unavailability based on the matching date
    const filteredDays = unavailability.filter(
        (timeOff) => timeOff.startDate === date || timeOff.endDate === date
    );

    // console.log({filteredDays})
    const wrapTemplate = (children: JSX.Element, timeOff: UnavailableDay) => {
        if (type === 'others') {
            return (
                <div className="scheduler-shift-card-body-not-available">
                    <div className="d-flex">
                        <div className="flex-grow-0 d-flex align-items-center justify-center">
                            <span className="shift-role"><i className="ri-time-line"></i></span>
                        </div>
                        <div className="flex-grow-1 shift-name-main text-nowrap">
                            {children}
                        </div>
                    </div>
                </div>
            )
        }
        if (type === 'schedule') {
            const acceptRequest = (event: React.MouseEvent<HTMLButtonElement>, timeOffId: string) => {
                confirmDialog({
                    className: 'custom-confirm-dialog',
                    message: <JTranslation typeCase="capitalize" text={'Are you sure you want to accept this request?'} />,
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-success',
                    rejectClassName: 'p-button-danger',
                    draggable: false,
                    accept: () => {
                        changeRequestStatus(timeOffId, 'APPROVED')
                    },
                })
            }

            const checkIfMultipleDays = timeOff?.startDate !== timeOff?.endDate;
            const toolTipContent = (
                <div className="d-flex flex-column" style={{ minWidth: '270px' }}>
                    <div className="d-flex flex-row justify-content-between my-2">
                        <div className="flex-grow-1">
                            <strong><JTranslation typeCase="none" text="Requested Time off" /></strong>
                        </div>
                        {
                            timeOff?.actionStatus === 'PENDING' && (
                                <div className="flex-grow-0">
                                    <Badge
                                        className="custom-badge"
                                        value={<JTranslation typeCase="pascal" text={'Pending'} />}
                                        severity="warning"
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className="mt-2 d-flex flex-row align-items-center">
                        {checkIfMultipleDays ? (
                            <strong>
                                {`${dayjs(timeOff?.startDate).format(DATE_FORMAT)} - ${dayjs(timeOff?.endDate).format(DATE_FORMAT)}`}
                            </strong>
                        ) : (
                            <strong>
                                {
                                    (timeOff?.startTime && timeOff?.endTime)
                                        ? `${dayjs(timeOff?.startDate).format(DATE_FORMAT)} | ${formatTimeToAmPm(timeOff?.startTime)} to ${formatTimeToAmPm(timeOff?.endTime)}`
                                        : `${dayjs(timeOff?.startDate).format(DATE_FORMAT)}`
                                }
                            </strong>
                        )}
                    </div>
                    <hr />
                    <div className='mt-2'>
                        <JTranslation typeCase="none" text={timeOff?.reason ?? ''} />
                    </div>
                    {timeOff?.actionStatus === 'PENDING' && actionButton && (
                        <>
                            <hr />
                            <ConfirmDialog />
                            <div className='mt-2 d-flex align-items-center justify-content-end'>
                                <button
                                    className='me-2 btn btn-sm btn-success'
                                    type='button'
                                    onClick={(event) => acceptRequest(event, timeOff?.id)}
                                >
                                    <JTranslation typeCase="pascal" text={APPROVE} />
                                </button>
                                <button
                                    className='btn btn-sm btn-danger'
                                    type='button'
                                    onClick={(event) => {
                                        setRejectTimeOffId(timeOff?.id)
                                        setShowWarning(true)
                                    }}
                                >
                                    <JTranslation typeCase="pascal" text={REJECT} />
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )
            return (
                <>
                    <ToastAlert
                        data-testid="toast"
                        message={toastMessage.message}
                        onClose={() => setToastMessage(toastMessageInitialData)}
                        show={toastMessage.show}
                        variant={toastMessage.variant}
                    />
                    
                    {/* reject request popup */}
                    <CommonModal
                        show={showWarning}
                        modalContent={rejectRequestModalContent}
                        title={REJECT_TIME_OFF}
                        modalSize="md"
                        disableProceed={!rejectReason}
                        onHide={closeRejectionModal}
                        callback={() => changeRequestStatus(rejectTimeOffId, 'REJECTED')}
                        hideCloseButton
                    />
                    <CustomTooltip
                        key={'pending-' + timeOff?.id}
                        staffName={null}
                        showDelay={500}
                        content={toolTipContent}
                        manualClose={timeOff?.actionStatus === 'PENDING'}
                    >
                        <div className="scheduler-shift-card-body-not-available">
                            <div className="d-flex">
                                <div className="flex-grow-0 d-flex align-items-center justify-center">
                                    <span className="shift-role"><i className="ri-time-line"></i></span>
                                </div>
                                <div className="flex-grow-1 shift-name-main text-nowrap">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </CustomTooltip>
                </>
            )
        }
    }

    // Return the mapped JSX for the filtered days
    return filteredDays.map((timeOff, index) => {
        if (timeOff.actionStatus === 'APPROVED') {
            if (timeOff.type === 'full-day') {
                return wrapTemplate(
                    <div key={index}>
                        <span style={{ marginRight: "3px", borderBottom: "none" }}>
                            <strong><JTranslation typeCase="none" text="R/O:" /></strong>
                        </span>
                        <span style={{ color: "red", borderBottom: "none" }}>
                            <JTranslation typeCase="none" text="Full Day" />
                        </span>
                    </div>,
                    timeOff
                );
            } else {
                return wrapTemplate(
                    <div key={index}>
                        <span style={{ marginRight: "3px", borderBottom: "none" }}>
                            <strong><JTranslation typeCase="none" text="R/O:" /></strong>
                        </span>
                        <span style={{ color: "red", borderBottom: "none" }}>
                            <JTranslation
                                typeCase="none"
                                text={`${formatTimeToAmPm(timeOff.startTime)} to ${formatTimeToAmPm(timeOff.endTime)}`}
                            />
                        </span>
                    </div>,
                    timeOff
                );
            }
        } else if (timeOff.actionStatus === 'PENDING') {
            return wrapTemplate(
                <div key={index}>
                    <span style={{ marginRight: "3px", borderBottom: "none" }}>
                        <strong><JTranslation typeCase="none" text="R/O:" /></strong>
                    </span>
                    <span style={{ color: "#fbaa07", borderBottom: "none" }}>
                        <JTranslation typeCase="none" text="Pending" />
                    </span>
                </div>,
                timeOff
            );
        }
    });
}

export default TimeOffCard;