import { BD, CLOSE, SELECT_SHIFT } from '../../constants/strings'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ErrorMessage, Field, FieldArrayRenderProps, FieldProps, FormikErrors, FormikTouched } from 'formik'
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch'
import { PrimeReactDropDown, ShiftAssignment, ShiftDropDown } from '../../constants/staticTypes'
import dayjs from 'dayjs'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import MlxTimePicker from '../mlx_time_picker/MlxTimePicker'
import { JTranslation } from '../../helpers/jTranslate'
import { Button } from 'antd'
import RepeatShiftModal from './RepeatShiftModal'
import { isRoleWithShiftPresent } from '../../helpers/workTimeScheduleHelper'
import { capitalize } from 'lodash'

type Props = {
    shifts: PrimeReactDropDown[]
    arrayHelpers: FieldArrayRenderProps
    availabilityIndex: number
    roleIndex: number
    shiftIndex: number
    errors: FormikErrors<ShiftAssignment>
    touched: FormikTouched<ShiftAssignment>
    setShifts: (value: SetStateAction<ShiftDropDown[]>) => void
    availabilityHelper: FieldArrayRenderProps
    
}

const ShiftAssignmentCard = ({
    shifts,
    availabilityIndex,
    roleIndex,
    shiftIndex,
    errors,
    touched,
    arrayHelpers,
    setShifts,
    availabilityHelper,
}: Props) => {

    const [showRepeatShiftModal, setShowRepeatShiftModal] = useState(false)
    const [selectedShiftRole, setSelectedShiftRole] = useState<any>(undefined)
    
    const isRolesRepeated = useCallback((
        arrayHelpers: FieldArrayRenderProps,
        availabilityIndex: number,
        roleIndex: number
    ) => {
        return isRoleWithShiftPresent(
            arrayHelpers.form.values.availability[availabilityIndex]?.roles[roleIndex],
            arrayHelpers.form.values.availability
        );
    }, []);

    const repeatedShifts = isRolesRepeated(arrayHelpers, availabilityIndex, roleIndex);
    const hasMultipleShifts = repeatedShifts?.length > 1;
    const tooltipText = hasMultipleShifts
        ? repeatedShifts?.map((shift: any) => capitalize(shift?.day))?.join(', ')
        : 'Repeating Shift';

    useEffect(() => {
        if(selectedShiftRole && !showRepeatShiftModal) {
            setShowRepeatShiftModal(true)
        } 
    }, [selectedShiftRole, showRepeatShiftModal])
    
    return (
        <>
            {/* Repeat Shift Modal */}
            <RepeatShiftModal
                visible={showRepeatShiftModal}
                shiftRole={selectedShiftRole}
                availabilities={arrayHelpers.form.values.availability}
                header="Repeat Shift"
                callback={(availabilities) => {
                    // Update availabilities
                    arrayHelpers.form.setFieldValue(`availability`, availabilities)
                }}
                onHide={() => {
                    setSelectedShiftRole(undefined)
                    setShowRepeatShiftModal(false)
                }}
            />
            
            <div className="d-flex justify-content-between align-items-center my-1 flex-wrap">
                <div className="flex-grow-1">
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}`}>
                        {({ field, form }: FieldProps) => {
                            const { code, name, startTime, endTime } = field.value;

                            //@ts-ignore
                            const rolesExist = errors?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]
                            const touchedExist = touched?.availability?.[availabilityIndex]?.roles?.[roleIndex]?.shifts?.[shiftIndex]

                            // Check if rolesExist and touchedExist are not undefined
                            const isError = rolesExist && touchedExist

                            // Apply style conditionally
                            const shiftStyle = isError ? { border: '1px solid red' } : undefined

                            const handleTimeChange = (name: string, time: any, key: 'startTime' | 'endTime') => {

                                // Update the field value, ensuring it matches the updated "Custom Shift"
                                form.setFieldValue(field.name, {
                                    ...field.value, // Retain the existing properties
                                    [key]: time ? time.format('HH:mm:ss') : null, // Update startTime or endTime
                                });
                            };

                            // Dropdown change handler
                            const handleDropdownChange = (e: DropdownChangeEvent) => {
                                const selectedShift = e.value;
                                form.setFieldValue(field.name, selectedShift);
                            };


                            return (
                                <div className="d-flex align-items-center justify-content-between gap-1">
                                    {/* Dropdown for Shift Names */}
                                    <Dropdown
                                        value={shifts.find((shift) => shift.code === code && shift.name === name)}
                                        options={shifts}
                                        optionLabel="name"
                                        placeholder={SELECT_SHIFT}
                                        onChange={handleDropdownChange}
                                        className="w-50"
                                        style={shiftStyle}
                                        itemTemplate={(option) => <>{option.name}</>}
                                        filter
                                        pt={{
                                            wrapper: {
                                                style: { fontSize: '1rem' }
                                            }
                                        }}
                                    />

                                    {/* Time Pickers for Start and End Time */}
                                    <MlxTimePicker
                                        value={startTime ? dayjs(startTime, 'HH:mm:ss') : null}
                                        onChange={(time) => handleTimeChange(name, time, 'startTime')}
                                        disabled={code !== 'Custom Shift'}
                                        style={{ ...shiftStyle, width: '120px' }}
                                        className="form-control antD-shiftcard"
                                    />
                                    <span className=''>
                                        <strong><JTranslation typeCase="none" text={'to'} /></strong>
                                    </span>
                                    <MlxTimePicker
                                        value={endTime ? dayjs(endTime, 'HH:mm:ss') : null}
                                        onChange={(time) => handleTimeChange(name, time, 'endTime')}
                                        disabled={code !== 'Custom Shift'}
                                        style={{ ...shiftStyle, width: '120px' }}
                                        className="form-control antD-shiftcard"
                                    />
                                </div>
                            );
                        }}
                    </Field>
                </div>

                {/* ADD BUTTON */}
                {/* <div className="flex-grow-0">{shiftIndex === 0 && AddShiftButton({ arrayHelpers: arrayHelpers })}</div> */}

                {/* REMOVE BUTTON */}
                <i 
                    className="ri-indeterminate-circle-fill delete mx-3" 
                    onClick={() => {
                        if (shiftIndex === 0 && arrayHelpers.form.values.availability[availabilityIndex]?.roles[roleIndex]?.shifts?.length === 1) {
                            availabilityHelper.remove(roleIndex)
                        } else {
                            arrayHelpers.remove(shiftIndex)
                        }
                    }}
                    role='button'
                    tabIndex={0}
                    title='Remove Shift'
                ></i>
                <ErrorMessage
                    className="formik-error visible w-100"
                    name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.code`}
                    component="div"
                />

                <ErrorMessage
                    className="formik-error visible w-100"
                    name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.endTime`}
                    component="div"
                />
            </div>
            <div className="mb-1 pb-1 border-bottom-dashed">
                <div className="d-flex align-items-center">
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <div className='d-flex align-items-center'>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className='ms-1'>{CLOSE}</label>
                                </div>
                            )
                        }}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.close`}
                        component="div"
                    />
                    <Field name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}>
                        {({ field, form }: FieldProps) => {
                            return (
                                <div className='ms-3 d-flex align-items-center'>
                                    <InputSwitch
                                        checked={field.value}
                                        onChange={(e: InputSwitchChangeEvent) => {
                                            const { value } = e
                                            form.setFieldValue(field.name, value)
                                        }}
                                    />
                                    <label className='ms-1'>{BD}</label>
                                </div>
                            )
                        }}
                    </Field>
                </div>

                <div className="d-block">
                    <ErrorMessage
                        className="formik-error mt-2"
                        name={`availability.${availabilityIndex}.roles.${roleIndex}.shifts.${shiftIndex}.bd`}
                        component="div"
                    />
                </div>

            </div>

            {/* Add/Delete Button */}
            {shiftIndex === arrayHelpers.form.values.availability[availabilityIndex]?.roles[roleIndex]?.shifts?.length - 1 && (
                <div className='d-flex align-items-center justify-content-between'>
                    <Button
                        className='add-shift-btn border-0 shadow-none'
                        //type='dashed'
                        icon={<i className="ri-add-circle-fill"></i>}
                        onClick={() => {
                            arrayHelpers.push({
                                bd: false,
                                close: false,
                                code: "",
                                endTime: "",
                                name: "",
                                startTime: "",
                            })
                            
                            // scroll shift card body
                            if(roleIndex === arrayHelpers.form.values.availability[availabilityIndex]?.roles.length - 1) {
                                setTimeout(() => {
                                    const shiftCardBody = document.getElementById(`shift-card-body-${availabilityIndex}`);
                                    if (shiftCardBody) {
                                        shiftCardBody.scroll({
                                            top: shiftCardBody.scrollHeight - shiftCardBody.offsetHeight,
                                            behavior: 'smooth'
                                        });
                                    }
                                }, 100);
                            }
                        }}
                    >
                        <small><JTranslation type='text' text={'Add Shift'} /></small>
                    </Button>

                    {/* Repeating Shift */}
                    <div 
                        className='repeating-shift'
                        role='button'
                        tabIndex={1}
                        title={tooltipText}
                        onClick={() => setSelectedShiftRole(arrayHelpers.form.values.availability[availabilityIndex]?.roles[roleIndex])}
                        style={hasMultipleShifts ? { color: "var(--primary-color)" } : {}}
                    >
                        <strong> 
                            <i className="ri-repeat-line me-1" />
                            <JTranslation typeCase="none" text="Repeat Shift" />
                        </strong>
                    </div>

                    <div
                        className="delete-round shadow-sm"
                        onClick={() => availabilityHelper.remove(roleIndex)}
                        role='button'
                        tabIndex={1}
                        title='Remove Role'
                    >
                        <i className="ri-delete-bin-line delete"></i>
                    </div>
                </div>
            )}

        </>
    )
}

export default ShiftAssignmentCard
