import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { Amplify } from 'aws-amplify'
import '@fortawesome/fontawesome-svg-core'
// import { render } from 'react-dom'
import 'remixicon/fonts/remixicon.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { awsConfig } from './config/awsConfig'
import { JTranslationProvider } from './helpers/jTranslate'
import { createRoot } from 'react-dom/client'; // Import createRoot
import { CHANNEL } from './constants/strings'
import { ConfigProvider } from 'antd'

// remove hardcoded values
const apiKey =
  process.env.REACT_APP_GCP_API_KEY || 'AIzaSyAXBRDCzoclcitYEn8k5mMyepW_JarbPr0' // Set the API key

// import * as serviceWorkerRegistration from './pwa-bkp/serviceWorkerRegistration';
// import * as serviceWorker from './serviceWorker'
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// const isLocalEnv = process?.env?.IS_LOCAL_ENV || false

Amplify.configure(awsConfig)
const queryClient = new QueryClient()

// render(root, document.getElementById('root'))
const container = document.getElementById('root');
const root = createRoot(container!); // Use the ! to assert that container is non-null


root.render(
  /*  <React.StrictMode> */
  <JTranslationProvider apiKey={apiKey}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider 
        theme={{
          token: {
            colorPrimary: '#fbaa07'
          }
        }}
      >
        <Router>
          <App />
        </Router>
      </ConfigProvider>
    </QueryClientProvider>
  </JTranslationProvider>
)

// Remove the initial loader once React has mounted
const removeInitialLoader = () => {
  const loader = document.getElementById('initial-loader');
  if (loader) {
    // First fade it out
    loader.classList.add('fade-out');
    // Then remove it from DOM after animation completes
    setTimeout(() => {
      loader.parentNode.removeChild(loader);
    }, 500);
  }
};

// Call after React is mounted
removeInitialLoader();


// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // commented, since the Firebase service worker registration generates a new token with every page refresh or reload

    // for (const registration of registrations) {
    //   console.log('SW: cache registration clearing...')
    //   registration.unregister().then(() => {
    //     console.log('SW: cache registration cleared!')
    //     caches.delete(registration.scope).then(() => {
    //       console.log('SW: cache cleared!')
    //       // window.location.reload()
    //     })
    //   })
    // }
  })

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (!event.data.action) {
      return
    }

    switch (event.data.action) {
      case 'redirect-from-notificationclick':
        const channel = new BroadcastChannel(CHANNEL)
        channel.postMessage({type: 'redirect', url: event.data.url || ''} )
        channel.close()
        break
      // no default
    }
  })
}
//   window.addEventListener('load', () => {
//     if (!isLocalhost) {
//       navigator.serviceWorker
//         .register('/service-worker.js')
//         .then((registration) => {
//           console.log('Service worker registered:', registration)
//         })
//         .catch((error) => {
//           console.log('Service worker registration failed:', error)
//         })
//     } else {
//       console.log('Service worker not registered in local env')
//     }
//   })
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
