import { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Carousel } from 'primereact/carousel';
import { initialScheduleData, EmployeeScheduleData, generateWeekdayDates, Weekday, days, Shift, formatApiStaffData, ScheduleData, getDateOfWeekday, PublishedSchedule, Staff, ExtendedShift, StyleDetails, Availability, isDateInSelectedWeek, isDateInRange, getTimeOffMessage, getStaffAvailabilityForTheDay } from '../../helpers/workTimeScheduleHelper';
import { DayOfWeek } from '../../constants/staticTypes';
import { capitalizeFirstLetter, getFirstLetterUpperCase, getFirstThreeLetter, getStaffId } from '../../helpers/utils';
import { Panel } from 'primereact/panel';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import CustomTooltip from '../manage_work_schedule/CustomTooltip';
import { Button } from 'primereact/button';
import { allowSwapRequest, checkIfManageSwapRequest, getBackgroudColorForSwap } from '../../helpers/swapScheduleHelper';
import { useQueryClient } from 'react-query';
import { JTranslation } from '../../helpers/jTranslate';
import useEmblaCarousel from 'embla-carousel-react';
import { usePrevNextButtons } from '../f&b_menu/guest/EmblaCarouselArrowButton';
import { SliderSkeleton } from '../../helpers/menuViewHelper';

type Props = {
    isLoading: boolean;
    selectedWeek: dayjs.Dayjs;
    staffsWithSchedule: ScheduleData | null;
    swapShiftRequest: (shift: PublishedSchedule, staff: Staff) => void;
    parent?: string;
    getRoleColorCode:(roleId: string) => StyleDetails;
}

type GetStaffAvailability = {
    day: string
    availabilities: Availability | undefined
}

const SwapScheduleMobileView = ({
    isLoading,
    selectedWeek,
    staffsWithSchedule,
    swapShiftRequest,
    parent,
    getRoleColorCode
}: Readonly<Props>) => {
    const queryClient = useQueryClient()
    const staffId = getStaffId()
    const managePermission = checkIfManageSwapRequest(queryClient)

    const [scheduleData, setScheduleData] = useState<EmployeeScheduleData[]>(initialScheduleData)
    const [weekdayDates, setWeekdayDates] = useState<Weekday[]>()

    const [emblaRef, emblaApi] = useEmblaCarousel({})
    const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick
	} = usePrevNextButtons(emblaApi)

    useEffect(() => {
        if (staffsWithSchedule) {
            // Sort staff data by current logged user
            let staffsData = formatApiStaffData({ scheduleData: staffsWithSchedule, filterBy: 'SWAP' })
            staffsData = staffsData.reduce<EmployeeScheduleData[]>((EmployeeScheduleArray, EmployeeScheduleData) => {
                if (EmployeeScheduleData.staffId === staffId) {
                    return [EmployeeScheduleData, ...EmployeeScheduleArray];
                } else {
                    return [...EmployeeScheduleArray, EmployeeScheduleData];
                }
            }, []);
            if(parent === 'myScheduleView') {
                staffsData.sort((a, b) => a.staffName.localeCompare(b.staffName));
            }
            setScheduleData(staffsData)
        }
    }, [staffsWithSchedule])

    useEffect(() => {
        if (selectedWeek) {
            const dates = generateWeekdayDates(selectedWeek)
            setWeekdayDates(dates)
        }
    }, [selectedWeek])

    const headerTemplate = (day: Weekday) => {
        return (
            <div key={uuidv4()} className="m-1 h-100 w-100">
                <div className="scheduler-header">
                    <div className='mb-0 text-center text-nowrap fw-normal text-muted'>{getFirstThreeLetter(day.day)}</div>
                    <div className="mb-2 text-center text-nowrap">{getDateOfWeekday({ selectedWeek, day: day.day })}</div>
                </div>
            </div>
        )
    }

    const getFormattedDate = (day: DayOfWeek) => {
        let date = ''
        weekdayDates?.forEach((weekDay) => {
            if (weekDay.day === day) {
                date = dayjs(weekDay.date).format('ddd, D MMM YYYY')
            }
        })
        return date
    }

    const cellBodyTemplate = ({ staff, shift, day }: { staff: Staff; shift: Shift[], day: string }) => {
        const { unavailableDays, availabilities } = staff
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const availabilityText = getStaffAvailabilityForTheDay({ day, availabilities, selectedWeek })
        const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
        const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))
        const timeOffText = getTimeOffMessage({ unavailability: unavailability }, date)?.length
            ? getTimeOffMessage({ unavailability: unavailability }, date)
            : availabilityText

        if (shift.length) {
            const extendedShift = (shift as any) as ExtendedShift[]
            return shift.map((shift, index) => {
                const schedule = (shift as any) as PublishedSchedule
                const assignedShifts = schedule.assignedShifts
                const { shiftName, startTime, endTime, isBd, isClose, role, isPoolRequested, roleId } = assignedShifts
                const canRequestSwap = (managePermission && !allowSwapRequest(schedule)) || (
                    !managePermission && (
                        staff.id !== staffId ||
                        !allowSwapRequest(schedule)
                    )
                )

                const styleDetails = getRoleColorCode(roleId);
                const { backgroundColor, color } = styleDetails;

                return (
                    <CustomTooltip
                        key={uuidv4()}
                        staffName={staff?.preferredName ?? `${staff?.firstName} ${staff?.lastName}`}
                        showDelay={500}
                        content={extendedShift}
                    >
                        <>
                            {(index === 0 && timeOffText) && timeOffText}

                            <div
                                className={`scheduler-shift-card-body w-100`}
                                style={{
                                    backgroundColor: "white",
                                    border: `2px solid ${backgroundColor}`,
                                }}
                            >
                                <div className="d-flex">
                                    <div className="flex-grow-0 d-flex align-items-center justify-center">
                                        <span 
                                            className="shift-role"
                                            style={{
                                                backgroundColor,
                                                color: color,
                                            }}
                                        >{getFirstLetterUpperCase(role ?? '')}</span>
                                    </div>
                                    <div className="flex-grow-1 d-flex justify-content-center shift-name-main text-nowrap">
                                        {/* <span className="shift-name">{shiftName ?? ''}</span> */}
                                        {isClose ? `${startTime} - Close` : ""}
                                        {isBd ? `${startTime} - V` : ""}
                                        {!isBd && !isClose ? `${startTime} - ${endTime}` : ''}
                                    </div>
                                    <div className="flex-grow-0 d-flex align-items-center justify-center swap-icn-bg">
                                        {(parent!=='myScheduleView' && !canRequestSwap) && (
                                            <Button
                                                className="rounded btn-custom-primary p-0"
                                                severity="warning"
                                                size="small"
                                                icon="pi pi-arrow-right-arrow-left"
                                                onClick={() => {
                                                    if(parent!=='myScheduleView')
                                                        swapShiftRequest(schedule, staff)
                                                }}
                                            ></Button>
                                        )}
                                        {((parent!=='myScheduleView') && canRequestSwap && isPoolRequested) && (
                                            <i className="pi pi-arrow-right-arrow-left text-light mx-1"></i>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>
                    </CustomTooltip>
                )
            })
        } else {
            return timeOffText ?? (<></>)
        }
    }

    return (
        <div className="card">

            {parent !== 'myScheduleView' && (
                <div className='col-lg-12 my-3'>
                    <div className='scroll-menu-container'>
                        <div className='nav-scroller'>
                            <nav className='nav-scroller-nav'>
                                <div className='nav-scroller-content-new main-menu'>
                                {weekdayDates?.length ? (
                                    <div className="embla">
                                        <div className="embla__viewport" ref={emblaRef}>
                                            <div className="embla__container">
                                                {weekdayDates?.map((data, index) => {
                                                    return (
                                                        <div className="embla__slide" key={index}>
                                                            {headerTemplate(data)}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    ) : (
                                        <SliderSkeleton />
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-end align-items-center'>                                
                        <Button 
                            size="small" 
                            icon="ri-arrow-left-s-line" 
                            rounded outlined 
                            aria-label="Cancel" 
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                            style={{width: '2rem', height: '2rem', padding: 0}}
                        />
                        <Button 
                            className='ms-2'
                            size="small" 
                            icon="ri-arrow-right-s-line" 
                            rounded outlined 
                            aria-label="Cancel" 
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                            style={{width: '2rem', height: '2rem', padding: 0}}
                        />
                    </div>
                </div>
            )}

            <Accordion>
                {scheduleData.map((schedule: EmployeeScheduleData, index: number) => (
                    <AccordionTab header={capitalizeFirstLetter(schedule.staffName)} key={uuidv4()}>
                        {days.map((day) => {
                            if (!schedule[day].length) return null
                            return (
                                <Panel key={uuidv4()} header={getFormattedDate(day)} toggleable collapsed>
                                    <div className='row'>
                                        <div className='col'>
                                            {cellBodyTemplate({ staff: schedule.staffData, shift: schedule[day], day })}
                                        </div>
                                    </div>
                                </Panel>
                            )
                        })}
                    </AccordionTab>
                ))}
            </Accordion>
            {scheduleData.length <= 0 && (<JTranslation typeCase="none" text={"No published schedule for the selected week"} />)}
        </div>
    )
}

export default SwapScheduleMobileView
