import { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { CANCEL, ADD, UPDATE, HOLIDAY, MANDATORY, NO_TITLE, BUSINESS_CLOSE, MANDATORY_POPUP_CONTENT, MINIMUM_STAFF_COUNT } from "../../constants/strings";
import { ColumnHeaderOptions } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import { CustomEvent, EventFormData, Holidays, JobRoleWithMinCount, MandatoryDay, ScheduleMetadata, Staff, StaffRequirement, UpdateDraftMetaData, getDateOfWeekday, initialEventFormValues, isDateInRange, updateDraftMetadata } from "../../helpers/workTimeScheduleHelper";
import { DayOfWeek, Payload, PerDayCountForListing } from "../../constants/staticTypes";
import { Formik, Form, Field, FieldProps, ErrorMessage } from "formik";
import { getFirstThreeLetter, timeFormatter } from "../../helpers/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { JTranslation } from "../../helpers/jTranslate";
import { OverlayPanel } from "primereact/overlaypanel";
import { staffCountByDay } from "../../helpers/workTimeScheduleHelper";
import { UseMutationResult } from "react-query";
import { useTranslation } from "../../hooks/useTranslation";
import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs";
import MlxPopover from "../common/MlxPopover";
import MlxTimePicker from "../mlx_time_picker/MlxTimePicker";
import StaffCountCardForEvents from "./StaffCountCardForEvents";
import { capitalize, cloneDeep } from "lodash";
import { Divider } from "primereact/divider";
import { InputNumber, Popover } from "antd";
import { Badge } from "primereact/badge";

type Props = {
    parent: 'web' | 'mobile',
    scheduleId: string,
    scheduleMetadata: ScheduleMetadata | null,
    customEvent?: CustomEvent,
    scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
    onSuccess: (message: string, variant: string) => void,
    onError: (res: string, variant: string) => void
    day: DayOfWeek,
    selectedWeek: dayjs.Dayjs
    actionStatus: string | undefined
    staffingRequirement: PerDayCountForListing[]
    staffingRequirementWithMinStaff: StaffRequirement[]
    headerData: ColumnHeaderOptions
    holidays: Holidays[];
    mandatoryDays: MandatoryDay[];
    isDateOlder: boolean
}

const CustomEventForm = ({
    parent,
    scheduleId,
    scheduleMetadata,
    customEvent,
    scheduleMutation,
    onSuccess,
    onError,
    day,
    selectedWeek,
    actionStatus,
    staffingRequirement,
    staffingRequirementWithMinStaff,
    headerData,
    holidays,
    mandatoryDays,
    isDateOlder,
}: Readonly<Props>) => {
    const addEventPanelRef = useRef<OverlayPanel>(null)
    const addStaffRequirementPanelRef = useRef<OverlayPanel>(null)
    const addedEvents = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
    const sortedEvents = cloneDeep(addedEvents?.eventData)?.sort((a, b) => dayjs(a?.time, 'HH:mm A').isAfter(dayjs(b?.time, 'HH:mm A')) ? 1 : -1)
    const staffRequirementData = staffingRequirementWithMinStaff?.find(data => data.dayName.toLowerCase() === day.toLowerCase())
    const minStaffRequirement = staffingRequirement?.find(data => data.dayName.toLowerCase() === day.toLowerCase())
    const deleteEventMessage = useTranslation('Do you want to delete this event?')

    const [eventId, setEventId] = useState<string>()
    const [initialEventFormData, setInitialEventFormData] = useState<EventFormData>(initialEventFormValues)
    const [staffRequirementFormData, setStaffRequirementFormData] = useState<StaffRequirement>()
    const [showAll, setShowAll] = useState(false)

    const confirmDeleteEvent = (event: any, eventId: string) => {
        const accept = () => {
            const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
            const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
            const updatedCustomEvents = customEvents?.map((event): CustomEvent => {
                return {
                    date: event.date,
                    dayId: event.dayId,
                    dayName: event.dayName,
                    eventData: event.eventData.filter(data => data.id !== eventId),
                };
            })

            const params: UpdateDraftMetaData = {
                scheduleId,
                scheduleMetadata: {
                    staffRequirement: staffRequirement,
                    customEvents: updatedCustomEvents,
                },
            }
            // update API call
            updateDraftMetadata(scheduleMutation, params, onSuccess, onError)

            // close overlay panel
            addEventPanelRef?.current?.hide()
        }
        const reject = () => { }

        confirmPopup({
            className: 'delete-event-confirm-popup',
            target: event.currentTarget,
            message: deleteEventMessage,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

    // Retrieve the minimum staff count for a given day
    const getMinStaffCount = () => {
        const customCount = staffRequirementData?.customCount;
        const minCount = minStaffRequirement?.minCount;

        return customCount ? Number(customCount) : Number(minCount);
    }

    const getCurrentStaffCountForDay = () => {
        let staffsWithShift
        if (parent === 'web') {
            staffsWithShift = headerData.props.value as Staff[];
        } else {
            staffsWithShift = headerData as unknown as Staff[];
        }

        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const staffCount = staffsWithShift.reduce((count, staffData) => {
            const { shifts } = staffData;
            if (shifts[date]) {
                return count + 1;
            }
            return count;
        }, 0);

        return Number(staffCount);
    };

    const checkHoliday = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
        return isHolidays
    };

    const getHolidayTitle = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const holiday = holidays?.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday?.title || NO_TITLE
    };

    const getIsOpenForBusiness = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const holiday = holidays?.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
        return holiday ? holiday.isOpenForBusiness : false
    };

    const checkMandatoryDays = () => {
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
        return isMandatory
    };

    const getColor = () => {
        const currentStaffCount = getCurrentStaffCountForDay() || 0;
        const minimumStaffCount = getMinStaffCount() || 0;

        if (currentStaffCount < minimumStaffCount) {
            return 'less';
        } else if (currentStaffCount === minimumStaffCount) {
            return 'equal';
        } else {
            return 'more';
        }
    };

    const saveStaffCount = () => {
        const currentStaffCount = getCurrentStaffCountForDay() || 0;
        const minimumStaffCount = getMinStaffCount() || 0;
        // update context with expected and current staff count
        staffCountByDay[day] = { current: currentStaffCount, expected: minimumStaffCount }
    }

    const eventCardTemplate = (event: any) => {
        return (
            <div
                className="d-flex align-items-center p-1 reservation"
                style={{ width: '85px', borderRadius: '15px' }}
                role="button"
                onClick={(e) => {
                    if (actionStatus === 'draft' && !isDateOlder && addEventPanelRef.current) {
                        setEventId(event?.id)
                        setInitialEventFormData({ ...event, time: dayjs(event.time, 'HH:mm A') })
                        addEventPanelRef.current.toggle(e)
                    }
                }}
            >
                <small className="text-nowrap w-100 text-center">
                    {event?.guests} @ {timeFormatter({ time: event?.time, format: 'hh:mm A' })}
                </small>
            </div>
        )
    }

    const popoverContent = (event: any) => (
        <div key={event?.id} className="d-flex flex-row mb-0">
            <div className="d-flex flex-row justify-content-between align-items-center px-2 reservation w-100">
                <Popover
                    title=""
                    content={
                        <div className="tooltip-content" style={{ maxWidth: '260px', minWidth: '150px' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="m-0"><strong>{capitalize(event?.guestName ?? '')}</strong></p>
                                {event?.guests && (
                                    <Badge value={event?.guests ?? ''} />
                                )}
                            </div>
                            <div className="mt-3 tooltip-container">
                                <div className="tooltip-row">
                                    <div className="tooltip-label"><JTranslation typeCase="capitalize" text={'Time'} /></div>
                                    <div className="tooltip-separator">:</div>
                                    <div className="tooltip-value">{
                                        dayjs(event?.time, 'HH:mm A').isValid()
                                            ? dayjs(event?.time, 'HH:mm A').format('h:mm A')
                                            : ''
                                    }
                                    </div>
                                </div>
                            </div>
                            {event?.desc && (
                                <>
                                    <hr className="tooltip-divider" />
                                    <div className="tooltip-row">{event?.desc}</div>
                                </>
                            )}
                        </div>
                    }
                    trigger={["hover", "click"]}
                >
                    <small className="text-nowrap w-100 text-center">
                        {event?.guests} @ {dayjs(event?.time, 'HH:mm A').format('h:mm A')}
                    </small>
                </Popover>
            </div>
        </div>
    )

    const renderEventContent = (events: Array<any>) => {
        if (!events || events.length === 0) {
            return <></>;
        }

        if (events.length === 1) {
            if (actionStatus === 'draft' && !isDateOlder) {
                return eventCardTemplate(events[0])
            }
            return popoverContent(events[0])
        }
        return <></>;
    }

    useEffect(() => {
        saveStaffCount()
    }, []) // eslint-disable-line

    return (
        <div className={`scheduler-header p-0 w-100 ${checkHoliday() && 'holiday-header'} ${checkMandatoryDays() && 'mandatory-day'}`} style={{ height: '110px' }}>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center' style={{ height: '60px' }}>
                <div className='py-1 text-center text-nowrap fw-normal text-muted w-100'>
                    <div className="d-flex align-center justify-content-center position-relative">
                        <span className="text-dark">{getFirstThreeLetter(day)}, {getDateOfWeekday({ selectedWeek, day: day, format: 'MMM D' })}</span>
                        {checkMandatoryDays()
                            ? (<span className="help-main ms-3 me-2 position-absolute end-0">
                                <MlxPopover data={{ title: MANDATORY, body: MANDATORY_POPUP_CONTENT }} iconName="ri-calendar-check-line" />
                            </span>)
                            : checkHoliday()
                                ? (<span className="help-main ms-3 me-2  position-absolute end-0">
                                    <MlxPopover data={{ title: getIsOpenForBusiness() ? HOLIDAY : BUSINESS_CLOSE, body: getHolidayTitle() }} iconName="ri-calendar-close-line" />
                                </span>)
                                : ""
                        }
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-center">
                    <div className={`calender-headcount-display ${getColor()}`} id={`pencil-${day}`}>
                        <div className={actionStatus !== 'draft' ? 'w-100 px-3 d-flex justify-content-between align-items-center' : ''}>
                            <i className="ri-group-line"></i>
                            <span className='ms-1'>{`${getCurrentStaffCountForDay()}/${getMinStaffCount()}`}</span>
                        </div>

                        {actionStatus === 'draft' && !isDateOlder && (
                            <>
                                <i
                                    role="button"
                                    className="ri-pencil-line edit-headcount"
                                    onClick={(e) => {
                                        if (addStaffRequirementPanelRef.current) {
                                            if (staffRequirementData) {
                                                setStaffRequirementFormData(staffRequirementData)
                                                addStaffRequirementPanelRef.current.toggle(e)
                                            }
                                        }
                                    }}
                                />

                                <OverlayPanel
                                    ref={addStaffRequirementPanelRef}
                                    onShow={() => {
                                        const currentElement = addStaffRequirementPanelRef?.current?.getElement();
                                        if (currentElement) {
                                            currentElement.style.zIndex = '1040'
                                        }
                                    }}
                                    style={{ width: '280px' }}
                                    pt={{
                                        content: {
                                            style: { padding: '0px' }
                                        }
                                    }}
                                >
                                    <Formik
                                        initialValues={staffRequirementFormData ?? {} as StaffRequirement}
                                        validate={(values) => {
                                            const errors: Record<string, any> = {};

                                            // Validate customCount
                                            if (values.customCount === null || values.customCount === undefined) {
                                                errors.customCount = 'Minimum staff count is required';
                                            }
                                            // Validate jobRoles array
                                            if (values.jobRoles && values.jobRoles.length > 0) {
                                                const jobRoleErrors = values.jobRoles.map((role, index) => {
                                                    const roleErrors: Record<string, string> = {};
                                                    if (role.minCount === null || role.minCount === undefined) {
                                                        roleErrors.minCount = 'Each job role must have a minimum count. Please enter at least 0';
                                                    }
                                                    return Object.keys(roleErrors).length > 0 ? roleErrors : null;
                                                });

                                                if (jobRoleErrors.some((error) => error !== null)) {
                                                    errors.jobRoles = jobRoleErrors;
                                                }
                                            }

                                            return Object.keys(errors).length > 0 ? errors : undefined;
                                        }}
                                        onSubmit={(values) => {
                                            const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]
                                            const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
                                            const updatedStaffRequirements = staffRequirement.map((data): StaffRequirement => {
                                                if (data.dayName.toLowerCase() === values.dayName.toLowerCase()) {
                                                    return {
                                                        ...values,
                                                        jobRoles: values.jobRoles.map((jobRole) => {
                                                            const { id, jobRoleName, minCount } = jobRole
                                                            return { id, jobRoleName, minCount }
                                                        }) as JobRoleWithMinCount[]
                                                    }
                                                }
                                                return data
                                            })

                                            const params: UpdateDraftMetaData = {
                                                scheduleId,
                                                scheduleMetadata: {
                                                    staffRequirement: updatedStaffRequirements,
                                                    customEvents,
                                                },
                                            }
                                            // update API call
                                            updateDraftMetadata(scheduleMutation, params, onSuccess, onError)

                                            setStaffRequirementFormData({} as StaffRequirement)
                                            addStaffRequirementPanelRef.current?.hide()
                                        }}
                                    >
                                        {(
                                            { errors, touched, isValid } // nosonar
                                        ) => (
                                            <Form>
                                                <div className='d-flex flex-column'>
                                                    <div className='d-flex flex-column'>
                                                        <div className="d-flex flex-row p-2 shadow-sm">
                                                            <div className="flex-grow-1 d-flex align-items-center">
                                                                <label className="fw-bold">{MINIMUM_STAFF_COUNT}</label>
                                                            </div>
                                                            <div className="flex-grow-0 d-flex align-items-center">
                                                                <Field
                                                                    name="customCount"
                                                                >
                                                                    {({ field, form }: FieldProps) => (
                                                                        // <InputNumber
                                                                        //     id="customCount"
                                                                        //     data-testid="customCount"
                                                                        //     value={field.value}
                                                                        //     className="input-number-custom p-inputtext-sm"
                                                                        //     onChange={(event) => form.setFieldValue(field.name, event.value, true)}
                                                                        //     // style={errors.customCount && touched.customCount
                                                                        //     //     ? { border: '1px solid red' }
                                                                        //     //     : undefined
                                                                        //     // }
                                                                        //     showButtons={true}
                                                                        //     useGrouping={false}
                                                                        //     min={0} max={100}
                                                                        // />
                                                                        <InputNumber
																			id="customCount"
																			data-testid="customCount"
																			value={Number(field.value)}
																			onChange={(value) => form.setFieldValue(field.name, value, true)}
																			min={0} 
																			max={100}
																			style={{ width: '70px', minWidth: '70px', padding: '0px' }}
																		/>
                                                                    )}
                                                                </Field>
                                                                <ErrorMessage
                                                                    className="formik-error"
                                                                    name="customCount"
                                                                    component="div"
                                                                    render={(error) => (
                                                                        <span className="formik-error">
                                                                            <JTranslation text={error} />
                                                                        </span>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Divider className="m-0 p-0" />
                                                        <div 
                                                            style={{ 
                                                                maxHeight: '200px', 
                                                                overflow: 'scroll', 
                                                                overflowX: 'hidden',
                                                                padding: '10px 15px',
                                                            }}
                                                        >
                                                            {staffingRequirementWithMinStaff?.map((staffingRequirement, index) => {
                                                                if (staffingRequirement.dayName.toLowerCase() === day) {
                                                                    const { jobRoles } = staffingRequirement;
                                                                    return <StaffCountCardForEvents jobRoles={jobRoles} />
                                                                }
                                                                return null;
                                                            })}
                                                        </div>

                                                    </div>
                                                    <div 
                                                        className='mt-1 p-2 d-flex flex-row justify-content-end'
                                                        style={{ background: 'hsl(from var(--primary-color) h s calc(l + 40))' }}
                                                    >
                                                        <button
                                                            type="reset"
                                                            className="btn btn-custom-primary-outline ms-3 p-0"
                                                            data-testid="add-new-event"
                                                            onClick={() => addStaffRequirementPanelRef.current?.hide()}
                                                        >
                                                            <small><JTranslation typeCase="pascal" text={CANCEL} /></small>
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-custom-primary ms-3 p-0"
                                                            data-testid="add-new-event"
                                                            disabled={!isValid}
                                                        >
                                                            <small><JTranslation typeCase="pascal" text={UPDATE} /></small>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </OverlayPanel>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <Divider className='p-0 m-0 py-1' />

            <div className="d-flex align-items-center px-2" style={{ height: '43px' }}>
                <div className="d-flex flex-row justify-content-between w-100">
                    {sortedEvents && sortedEvents?.length > 0 ? (
                        <>
                            {renderEventContent(sortedEvents)}
                            {sortedEvents?.length > 1 && (
                                <Popover content={() => (
                                    <div className="">
                                        {sortedEvents?.map(event => {
                                            if (actionStatus === 'draft' && !isDateOlder) {
                                                return <strong key={event?.id}>{eventCardTemplate(event)}</strong>
                                            } else {
                                                return popoverContent(event)
                                            }
                                        })}
                                    </div>
                                )}
                                    title=""
                                    trigger="click"
                                    destroyTooltipOnHide={true}
                                    placement="bottomLeft"
                                    open={showAll}
                                    onOpenChange={(event) => {
                                        if (event) {
                                            setShowAll(true)
                                            return
                                        }

                                        // close show all popover if no event is selected
                                        if (!event && !addEventPanelRef?.current?.getElement()) {
                                            setShowAll(false)
                                        }
                                    }}
                                >
                                    <small
                                        className="d-flex flex-row justify-content-center align-items-center reservation bg-transparent"
                                        style={{ width: '85px', cursor: 'pointer' }}
                                    >
                                        <i className="ri-arrow-right-s-line me-1" />
                                        <p className="m-0">
                                            <JTranslation text="See all " typeCase="pascal" />
                                            {sortedEvents.length}
                                        </p>
                                    </small>
                                </Popover>
                            )}
                        </>
                    ) : <span></span>}
                    {actionStatus === 'draft' && !isDateOlder && (
                        <span
                            className="reservation no-events white-space-nowrap flex-grow-1"
                            style={{ maxWidth: '65px', borderRadius: '15px' }}
                            role="button"
                            onClick={(event) => {
                                if (addEventPanelRef.current) {
                                    setEventId(undefined)
                                    setInitialEventFormData(initialEventFormValues)
                                    addEventPanelRef.current.toggle(event)
                                }
                            }}
                        >
                            <span className=""><JTranslation text='Add' typeCase='pascal' /></span>
                            <i className="ri-add-circle-fill add-reservation-btn-only ps-1" />
                        </span>
                    )}
                </div>
            </div>

            {actionStatus === 'draft' && !isDateOlder && (
                <OverlayPanel
                    ref={addEventPanelRef}
                    onShow={() => {
                        const currentElement = addEventPanelRef?.current?.getElement();
                        if (currentElement) {
                            currentElement.style.zIndex = '1041'
                        }
                    }}
                    onHide={() => setShowAll(false)}
                >
                    <Formik
                        initialValues={initialEventFormData}
                        validate={(data) => {
                            let errors: any = {};
                            if (data.guests <= 0) {
                                errors.guests = 'requried';
                            }
                            if (data.guests > 999) {
                                errors.guests = 'Guest count should not be greater than 999';
                            }
                            if (!data.time ?? !data?.time.isValid()) {
                                errors.time = 'requried';
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            const updatedValues = { ...values, time: values.time.format('HH:mm A') }
                            const updatedEvents: any = customEvent?.eventData.map((event: EventFormData) => {
                                // edit
                                if (event.id === eventId) {
                                    return {
                                        ...event,
                                        ...updatedValues
                                    }
                                }
                                return event
                            })
                            // if no entry, add 
                            if (updatedEvents?.find((event: EventFormData) => event.id === eventId) === undefined) {
                                updatedEvents.push({ ...updatedValues, id: uuidv4() })
                            }

                            const staffRequirement = scheduleMetadata?.staffRequirement ?? [] as StaffRequirement[]
                            const customEvents = scheduleMetadata?.customEvents ?? [] as CustomEvent[]

                            const updatedCustomEvents = customEvents?.map((event): CustomEvent => {
                                if (event.dayName.toLowerCase() === customEvent?.dayName.toLowerCase()) {
                                    return { ...event, eventData: updatedEvents }
                                }
                                return event
                            })

                            const params: UpdateDraftMetaData = {
                                scheduleId,
                                scheduleMetadata: {
                                    staffRequirement: staffRequirement,
                                    customEvents: updatedCustomEvents,
                                },
                            }
                            // update API call
                            updateDraftMetadata(scheduleMutation, params, onSuccess, onError)

                            setEventId(undefined)
                            setInitialEventFormData(initialEventFormValues)
                            addEventPanelRef.current?.hide()
                        }}
                    >
                        {(
                            { errors, touched, submitForm, isValid, resetForm, getFieldProps } // nosonar
                        ) => (
                            <Form>
                                <div className='d-flex flex-column' style={{ minWidth: '320px' }}>
                                    <h5 className="modal-title d-flex align-items-center justify-content-between">
                                        <JTranslation text='Add Event' typeCase='pascal' /> {customEvent?.dayName}
                                        {actionStatus === 'draft' && !isDateOlder && eventId && <i
                                            role="button"
                                            className="ri-delete-bin-6-line edit-headcount ps-1 delete"
                                            onClick={(e) => {
                                                if (eventId) {
                                                    confirmDeleteEvent(e, eventId)
                                                }
                                            }}
                                        />}
                                    </h5>
                                    <div className='mt-4 d-flex flex-column'>
                                        <div className="mb-3">
                                            <label htmlFor="customerCount" className="form-label">
                                                <JTranslation typeCase="pascal" text={"No of Guests"} />
                                            </label>
                                            <Field name="guests">
                                                {({ field, form }: FieldProps) => (
                                                    <InputText
                                                        type='number'
                                                        min={1}
                                                        max={999}
                                                        className="form-control"
                                                        id="customerCount"
                                                        data-testid="customer-count"
                                                        autoComplete="off"
                                                        value={field.value}
                                                        onChange={(event) => form.setFieldValue(field.name, event.target.value, true)}
                                                        style={
                                                            errors.guests && touched.guests
                                                                ? { border: '1px solid red' }
                                                                : undefined
                                                        }
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage className="formik-error" name="guests" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="time" className="form-label">
                                                <JTranslation typeCase="pascal" text={"Time"} />
                                            </label>
                                            <Field name="time">
                                                {({ field, form }: FieldProps) => (
                                                    <MlxTimePicker
                                                        className="form-control"
                                                        value={field.value}
                                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                                        style={
                                                            errors.time && touched.time
                                                                ? { border: '1px solid red' }
                                                                : undefined
                                                        }
                                                        popupStyle={{
                                                            zIndex: addEventPanelRef.current?.getElement()?.style.zIndex
                                                                ? addEventPanelRef.current?.getElement()?.style.zIndex + 2
                                                                : 1042
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <ErrorMessage className="formik-error" name="time" component="div" render={(error) => <span className='formik-error'><JTranslation typeCase="pascal" text={error} /></span>} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="guestName" className="form-label">
                                                <JTranslation typeCase="pascal" text={"Guest Name"} />
                                            </label>
                                            <Field name="guestName">
                                                {({ field, form }: FieldProps) => (
                                                    <InputText
                                                        type='text'
                                                        className="form-control"
                                                        id="guestName"
                                                        data-testid="guest-name"
                                                        autoComplete="off"
                                                        value={field.value}
                                                        onChange={(event) => form.setFieldValue(field.name, event.target.value, true)}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="mb-3 d-flex flex-column">
                                            <label htmlFor="desc" className="form-label">
                                                <JTranslation typeCase="pascal" text={"Description"} />
                                            </label>
                                            <Field name="desc">
                                                {({ field, form }: FieldProps) => (
                                                    <InputTextarea
                                                        value={field.value}
                                                        onChange={(event) => form.setFieldValue(field.name, event.target.value)}
                                                        rows={5} cols={3}
                                                        autoResize
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className='mt-3 d-flex flex-row justify-content-end'>
                                        <button
                                            type="button"
                                            className="btn btn-custom-primary-outline"
                                            onClick={() => {
                                                resetForm()
                                                addEventPanelRef.current?.hide()
                                            }}
                                        >
                                            <JTranslation typeCase="pascal" text={CANCEL} />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-custom-primary ms-3"
                                            data-testid="add-new-event"
                                            disabled={!isValid}
                                            onClick={(event) => {
                                                event.preventDefault()
                                                submitForm()
                                            }}
                                        >
                                            <JTranslation typeCase="pascal" text={eventId ? UPDATE : ADD} />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </OverlayPanel>
            )}
        </div >
    )
}

export default CustomEventForm