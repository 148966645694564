import { SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { AddAssignmentCard, Availability, AvailableTime, JobRoleForShift, ScheduleData, Shift, Staff, UnavailableDay, addOrUpdateScheduleApi, days, depOptionTemplate, depSelectionTemplate, getDateOfWeekday, getStaffAvailabilityForTheDayForAccordian, initialAddOrUpdateSchedule, initialDataShiftAssignment, isDateInRange, optionTemplate, selectionTemplate, validationSchema } from '../../helpers/workTimeScheduleHelper'
import { AlertVariant, DATE_FORMAT, PageMode } from '../../constants/constants'
import { AVAILABILITY_LIST, STAFFS_WITH_SCHEDULES_AND_SHIFT } from '../../constants/queryKeys'
import { Badge } from 'primereact/badge'
import { Button, Drawer } from 'antd'
import { calculateDuration, formatTimeToAmPm, getAllWeekDays, imageOnErrorHandler, toPascalCase } from '../../helpers/utils'
import { Card } from 'primereact/card'
import { CloseOutlined } from "@ant-design/icons"
import { CommonCtx } from '../../context/CommonCtxProvider'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from 'formik'
import { getAvailabilityOfUsers, initialValuesForAvailability } from '../../helpers/availabilityHelper'
import { getFullDataById, initialValuesForTimeOff } from '../../helpers/timeOffHelper'
import { isEqual } from "lodash"
import { JTranslation } from '../../helpers/jTranslate'
import { parseTimeForTimePicker } from '../../helpers/newsAndEventsHelper'
import { SAVE, CANCEL, ROLE, SELECT_ROLE, NO_ROLES_ASSIGNED, HOLIDAY, MANDATORY, BUSINESS_CLOSE, AREA_OF_OPERATION, ADD_NEW_SHIFT } from '../../constants/strings'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { ToastMessageProps, ShiftAssignment, ShiftDropDown, AvailabilityForFE, DayOfWeek, Roles, ScheduleDepartmentDropDown, ScheduleRoleDropDown, AvailabilityFormData, AvailabilityWeekFormData, Id, TimeOffEntry } from '../../constants/staticTypes'
import { useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import AvailabilitySideBar from '../availability/AvailabilitySideBar'
import CommonModal from '../common_modal/CommonModal'
import dayjs from 'dayjs'
import Loader from '../loader/Loader'
import noProfileAvatar from '../../../src/assets/images/placeholder-avatar.jpg'
import SchedulerTimeOffSidebar from './SchedulerTimeOffSidebar'
import ShiftAssignmentCard from './ShiftAssignmentCard'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import ShiftAssignmentSkeleton from './ShiftAssignmentSkeleton'
import './ShiftScheduleSideBar.scss'

type Props = {
	rolesList: ScheduleRoleDropDown[]
	departments: ScheduleDepartmentDropDown[]
	scheduleId: string
	selectedDay: string
	selectedEmployeeName: string,
	setSelectedEmployeeName: React.Dispatch<React.SetStateAction<string>>
	selectedWeek: dayjs.Dayjs
	setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
	setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
	shifts: ShiftDropDown[]
	shiftSideBar: boolean,
	staffDataWithSchedule: Staff | null,
	staffsWithSchedule: ScheduleData | null
	submitCount: () => void
	schedulerOpenFlag: string
	setSchedulerOpenFlag: React.Dispatch<React.SetStateAction<string>>
	setShifts: (value: SetStateAction<ShiftDropDown[]>) => void
}

export type GetStaffAvailability = {
	day: string
	availabilities: Availability | undefined
}

function ShiftScheduleSideBar({
	rolesList,
	departments,
	scheduleId,
	selectedDay,
	selectedEmployeeName,
	setSelectedEmployeeName,
	selectedWeek,
	setShiftSideBar,
	setToastMessage,
	shifts,
	shiftSideBar,
	staffDataWithSchedule,
	staffsWithSchedule,
	submitCount,
	schedulerOpenFlag,
	setSchedulerOpenFlag,
	setShifts
}: Readonly<Props>) {
	const formRef = useRef(null);
	const [initialValues, setInitialValues] = useState<ShiftAssignment>(initialDataShiftAssignment);
	const { savedStaffData, setSavedStaffData, currentStaffIndex, setCurrentStaffIndex, shiftFormHistory, setShiftFormHistory } = useContext(ScheduleManagementCtx)
	const uuid = uuidv4()
	const onClose = () => {
		setCurrentStaffIndex(0);
		setCurrentStaffValues(null);
		setInitialValues(initialDataShiftAssignment);
		setShiftSideBar(false);
		setSavedStaffData(null);
		setAvailabilityUpdate(false);
		setSchedulerOpenFlag(uuid)
	};
	const queryClient = useQueryClient()
	const scheduleMutation = useMutationHook(queryClient, true)
	const timeOffMutation = useMutationHook(queryClient, true)
	const { staffs, holidays, mandatoryDays } = staffsWithSchedule!
	const [currentStaffValues, setCurrentStaffValues] = useState<Staff | null>(savedStaffData ? savedStaffData : null);
	const [jobRoles, setJobRoles] = useState<JobRoleForShift[]>()
	const [tenantStaffId, setTenantStaffId] = useState<string>();
	const [availabilities, setAvailabilities] = useState<Availability>();
	const [unavailableDays, setUnavailableDays] = useState<UnavailableDay[]>([]);
	const [profileImage, setProfileImage] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [visible, setVisible] = useState({ traverse: false });
	const [traverseDirection, setTraverseDirection] = useState<string>('')
	const [isChanged, setIsChanged] = useState(false); // track changes in initial values
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [availabilityUpdate, setAvailabilityUpdate] = useState<boolean>(false);
	const { setShowSideBar } = useContext(CommonCtx)
	const [mode, setMode] = useState<PageMode>(PageMode.EDIT)
	const [availabilityStaffId, setAvailabilityStaffId] = useState<string>();
	const [availabilityListData, setAvailabilityListData] = useState<AvailabilityFormData>(initialValuesForAvailability)
	const [availabilityType, setAvailabilityType] = useState<'REGULAR' | 'TEMPORARY'>('REGULAR')
	const [showSchedulerAvailabilitySideBar, setShowSchedulerAvailabilitySideBar] = useState(false)
	const [showSchedulerTimeOffSideBar, setShowSchedulerTimeOffSideBar] = useState(false)
	const [timeOffPageMode, setTimeOffPageMode] = useState(PageMode.EDIT);
	const { setTimeOffData } = useContext(ScheduleManagementCtx)
	const [reRender, setReRender] = useState(0)
	const [showRepeatShiftModal, setShowRepeatShiftModal] = useState(false)

	useEffect(() => {
		// Calculate the initial index when staffDataWithSchedule is available
		if (staffDataWithSchedule && staffs.length > 0 && !savedStaffData) {
			const initialIndex = staffs.findIndex(staff => staff.id === staffDataWithSchedule.id);
			setCurrentStaffIndex(initialIndex !== -1 ? initialIndex : 0); // Fallback to 0 if not found
		}

		if (availabilityUpdate) return;

		setJobRoles(staffDataWithSchedule?.jobRoles.map((role: any) => role?.jobRole?.jobRoleName))
		setTenantStaffId(staffDataWithSchedule?.id)
		setAvailabilities(staffDataWithSchedule?.availabilities)
		setUnavailableDays(staffDataWithSchedule?.unavailableDays ?? [])
		setProfileImage(staffDataWithSchedule?.signedUrl)
	}, [staffDataWithSchedule, staffs, schedulerOpenFlag]);

	const handleStaffTraversal = (direction: string) => {
		setShiftFormHistory(initialDataShiftAssignment) // clear shift form history
		setIsImageLoaded(false)
		setTraverseDirection(direction)
		let nextIndex;

		if (isChanged) {
			setVisible({ traverse: true });
		} else {
			// reset form on traverse
			if (formRef?.current) {
				(formRef?.current as any)?.resetForm();
			}

			if (direction === 'previous') {
				nextIndex = currentStaffIndex > 0 ? currentStaffIndex - 1 : 0;
				setCurrentStaffIndex(nextIndex);
			} else {
				nextIndex = currentStaffIndex < staffs.length - 1 ? currentStaffIndex + 1 : staffs.length - 1;
				setCurrentStaffIndex(nextIndex);
			}
			const nextStaff = staffs[nextIndex];
			setAvailabilityStaffId(nextStaff.id);
			setCurrentStaffValues(nextStaff);
			setSelectedEmployeeName(nextStaff?.preferredName || '');
			setJobRoles(nextStaff?.jobRoles.map((role: any) => role?.jobRole?.jobRoleName) || []);
			setTenantStaffId(nextStaff?.id);
			setAvailabilities(nextStaff?.availabilities);
			setUnavailableDays(nextStaff?.unavailableDays ?? []);
			setProfileImage('');
			setProfileImage(nextStaff?.signedUrl)
		}
	};

	useEffect(() => {
		if (currentStaffValues) {
			setAvailabilityStaffId(currentStaffValues?.id) //upon traversal of sidebar
		} else {
			setAvailabilityStaffId(staffDataWithSchedule?.id) // for first opening of sidebar
		}
	}, [currentStaffValues, staffDataWithSchedule])

	function formatTimeRanges(input: AvailableTime[]): string {
		return input.map(range => `(${formatTime(range.from)} to ${formatTime(range.to)})`).join(', ');
	}

	function formatTime(time: string): string {
		// Ensure the time format is consistent with AM/PM capitalization and no extra spaces
		return time.toUpperCase().replace(/\s*(AM|PM)/, ' $1');
	}

	const getDayOfWeek = (dateString: string): string => {
		const dayIndex = dayjs(dateString).day();
		const days: DayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
		return days[dayIndex];
	};

	const processRoles = (shift: Shift[]): Roles[] => {
		const roleMap: { [key: string]: Roles } = {};

		shift.forEach(item => {
			const { role, roleId, isBd, isClose, shiftName, startTime, endTime, departmentId, departmentName } = item;

			if (!roleMap[roleId]) {
				roleMap[roleId] = {
					name: role,
					code: roleId,
					roleDepartmentId: departmentId,
					roleDepartmentName: departmentName,
					shifts: []
				};
			}

			roleMap[roleId].shifts.push({
				name: shiftName,
				code: shiftName,
				startTime: dayjs(startTime, 'hh:mm A').format('HH:mm:ss'),
				endTime: dayjs(endTime, 'hh:mm A').format('HH:mm:ss'),
				close: isClose,
				bd: isBd
			});
		});

		return Object.values(roleMap);
	};

	const populateShiftAssignment = (scheduleData: Staff | null): ShiftAssignment => {
		if (!scheduleData) return { availability: [] };

		const dateKeys = scheduleData.shifts ? Object.keys(scheduleData.shifts) : [];

		const availability: AvailabilityForFE[] = days.map((day) => {
			const dateKey = dateKeys.find((key) => getDayOfWeek(key) === day);

			const shift: Shift[] = dateKey
				? scheduleData.shifts[dateKey].map((shift) => shift.assignedShifts) || []
				: [];

			const rolesArray = processRoles(shift);

			return {
				day,
				roles: rolesArray,
				availabilities: {},
				unavailableDays: [],
				holidays: [],
				mandatoryDays: [],
			};
		});

		return { availability };
	};

	// assigning API data to form inputs
	useEffect(() => {
		const dataToFormat = currentStaffValues || staffDataWithSchedule;
		if (dataToFormat) {
			const formattedData = populateShiftAssignment(dataToFormat)
			setInitialValues(formattedData);
		}
	}, [staffDataWithSchedule, currentStaffValues, schedulerOpenFlag]); // eslint-disable-line


	const onSuccess = (message?: string, variant?: string) => {
		if (message && variant) setToastMessage({ message, variant, show: true })
		// setSavedStaffData(currentStaffValues);
		// onClose()
		// queryClient.refetchQueries([STAFFS_WITH_SCHEDULES_AND_SHIFT])

		setIsLoading(true);
		// Refetch the data to ensure updated information is available
		queryClient.refetchQueries([STAFFS_WITH_SCHEDULES_AND_SHIFT]).then(() => {
			// Retrieve the latest data from the cache
			const updatedData = queryClient.getQueryData<any>(STAFFS_WITH_SCHEDULES_AND_SHIFT);
			const updatedStaffsList: Staff[] = updatedData?.data.data.staffs;

			if (updatedData) {
				// Find the specific staff data based on tenantStaffId
				const updatedStaffData = updatedStaffsList?.find(staff => staff.id === tenantStaffId);
				const initialIndex = staffs.findIndex(staff => staff.id === updatedStaffData?.id);
				setCurrentStaffIndex(initialIndex);

				if (updatedStaffData) {
					setSavedStaffData(updatedStaffData);
					const formattedData = populateShiftAssignment(updatedStaffData);
					setInitialValues({ ...formattedData, ...shiftFormHistory });

					// Update other state variables if necessary
					setJobRoles(updatedStaffData?.jobRoles.map((role: any) => role?.jobRole?.jobRoleName));
					setTenantStaffId(updatedStaffData?.id);
					setAvailabilities(updatedStaffData?.availabilities);
					setUnavailableDays(updatedStaffData?.unavailableDays ?? []);
					setProfileImage(updatedStaffData?.signedUrl);

					setAvailabilityUpdate(true);
				}
			}
		}).finally(() => {
			setIsLoading(false);
			setIsChanged(false);
			submitCount();
		});
	}

	// on api error
	const onError = (message: string, variant: string) => {
		setToastMessage({ message, variant, show: true })
	}

	const getAvailabilityBadgeColor = (availabilityText: string): string => {
		switch (availabilityText) {
			case 'all day':
				return 'staff-availability-chip text-nowrap available-all-day'
			case 'not available':
				return 'staff-availability-chip text-nowrap not-available'
			default:
				return 'staff-availability-chip text-nowrap'
		}
	}

	const getAvailabilityInfoIconClass = (availabilityText: string): string => {
		switch (availabilityText) {
			case 'all day':
				return 'ri-information-line staff-availability-info available-all-day'
			case 'not available':
				return 'ri-information-line staff-availability-info not-available'
			default:
				return 'ri-information-line staff-availability-info'
		}
	}

	const getAccordionHeaderClass = ({ isHolidays, isMandatory }: { isMandatory: boolean, isHolidays: boolean }) => {
		if (isMandatory) {
			return "mandatory-bg"
		} else if (isHolidays) {
			return "holiday-bg"
		} else {
			return ""
		}
	}

	const getIsOpenForBusiness = (day: string) => {
		const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
		const holiday = holidays.find((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate }))
		return holiday ? holiday.isOpenForBusiness : false
	};

	// Main function to process form data and send to API
	const postFormDataToApi = (formData: ShiftAssignment) => {
		// Initialize the schedule data structure for API submission
		const scheduleDataForApi: any = {
			...initialAddOrUpdateSchedule,
			scheduleId,
			tenantStaffId,
			assignShiftsByDate: {}
		};

		const { availability } = formData;

		// Iterate over each availability entry in the form data
		availability.forEach(({ day, roles }) => {
			// Get the formatted date for the given day
			const date = getDateOfWeekday({ day: day, selectedWeek, format: 'YYYY-MM-DD' });

			// If there are roles for this day, initialize an array for the date in the schedule data
			if (roles.length) {
				scheduleDataForApi.assignShiftsByDate[date] = [];
			}

			// Iterate over each role for the day
			roles.forEach(role => {
				// Map the shifts for the role to the required format
				const shifts = role.shifts.map(shift => ({
					shiftName: shift.name,
					startTime: formatTimeToAmPm(shift.startTime),
					endTime: formatTimeToAmPm(shift.endTime),
					role: role.name,
					roleId: role.code,
					departmentId: role.roleDepartmentId,
					departmentName: role.roleDepartmentName,
					isBd: shift.bd || false,
					isClose: shift.close || false,
				}));

				// Add the formatted shifts to the date in the schedule data
				scheduleDataForApi.assignShiftsByDate[date].push(...shifts);
			});
		});

		// Make the API call to add or update the schedule
		addOrUpdateScheduleApi(scheduleMutation, scheduleDataForApi, onSuccess, onError);
	};

	const compareFormData = (formData: ShiftAssignment) => {
		const dataChanged = !isEqual(formData, initialValues);
		setIsChanged(dataChanged);

		setShiftFormHistory(formData);
	}

	const ClearContent = <div>{'The changes you made will be discarded. Please click save to save changes.'}</div>;

	const accept = () => {
		// reset form on traverse
		if (formRef?.current) {
			(formRef?.current as any)?.resetForm();
		}

		setIsChanged(false);
		setVisible({ traverse: false });
		let nextStaffIndex;
		if (traverseDirection === 'previous') {
			nextStaffIndex = currentStaffIndex > 0 ? currentStaffIndex - 1 : 0;
			setCurrentStaffIndex(nextStaffIndex);
		} else {
			nextStaffIndex = currentStaffIndex < staffs.length - 1 ? currentStaffIndex + 1 : staffs.length - 1; setCurrentStaffIndex(nextStaffIndex);
		}
		const nextStaff = staffs[nextStaffIndex];
		setCurrentStaffValues(nextStaff);
		setSelectedEmployeeName(nextStaff?.preferredName || '');
		setJobRoles(nextStaff?.jobRoles.map((role: any) => role?.jobRole?.jobRoleName) || []);
		setTenantStaffId(nextStaff?.id);
		setAvailabilities(nextStaff?.availabilities);
		setUnavailableDays(nextStaff?.unavailableDays ?? []);
		setProfileImage('');
		setProfileImage(nextStaff?.signedUrl)
	};

	const handleImageLoad = () => {
		// Triggered when the image has fully loaded
		setIsImageLoaded(true);
	};

	const getMonday = (currentDay: any) => {
		// Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
		const dayOfWeek = currentDay.day();

		// Calculate the difference to the previous Monday
		const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

		// Get the Monday of the week
		const mondayOfWeek = currentDay.subtract(daysToSubtract, "day");

		return mondayOfWeek.format("YYYY-MM-DD")
	}

	function selectAvailabilityObject(data: any) {
		const mondayDate = getMonday(selectedWeek);

		// Step 1: Filter objects with actionStatus === "APPROVED" or "PENDING"
		const filteredData = data.filter(
			(item: any) => item.actionStatus === "APPROVED" || item.actionStatus === "PENDING"
		);

		// Step 2: Find the one with isRecurring=false and startDate === mondayDate, then get the latest by createdAt
		const nonRecurring = filteredData
			.filter((item: any) => !item.isRecurring && item.startDate === mondayDate)
			.sort((a: any, b: any) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime());
		// Sort by createdAt descending

		if (nonRecurring.length > 0) {
			return nonRecurring[0];
		}

		// Step 3: If no non-recurring match, pick the first isRecurring=true entry
		const recurring = filteredData.find((item: any) => item.isRecurring);

		return recurring || []; // Return null if no matching object is found
	}

	// fetch availability list
	const availabilityListDataQuery = useQueryHook(
		AVAILABILITY_LIST,
		() => {
			return getAvailabilityOfUsers({ staffId: availabilityStaffId!, startDate: getMonday(selectedWeek) })
		},
		(res) => {
			const data = res.data.data.lists;
			if (data.length === 0) return
			const selectedAvailability = selectAvailabilityObject(data)

			const weekDays = getAllWeekDays(dayjs(selectedAvailability.startDate))
			const editableData: AvailabilityFormData = {
				id: selectedAvailability.id,
				isRecurring: selectedAvailability.isRecurring ? 'recurring' : 'temporary',
				startDate: dayjs(selectedAvailability.startDate),
				endDate: selectedAvailability.endDate ? dayjs(selectedAvailability.endDate) : null,
				staffId: availabilityStaffId ? availabilityStaffId : '',
				weekdays: selectedAvailability.weekdays
					? selectedAvailability.weekdays.map((day: any, index: any) => {
						const weekDay: AvailabilityWeekFormData = {
							isAllDay: day.isAllDay,
							isAvailable: day.isAvailable,
							weekday: weekDays[index],
							notes: day.notes ?? '',
							availableTimes: day.availableTimes
								? day.availableTimes.map((time: any) => ({
									from: dayjs(
										`${weekDays[index].format('MM/DD/YYYY')} ${time.from}`
									),
									to: dayjs(`${weekDays[index].format('MM/DD/YYYY')} ${time.to}`),
								}))
								: [],
						}
						return weekDay
					})
					: [],
			}
			setAvailabilityType(selectedAvailability.isRecurring ? 'REGULAR' : 'TEMPORARY')
			setAvailabilityListData(editableData)
		},
		(error) => { },
		availabilityStaffId ? true : false
	)

	useEffect(() => {
		if (availabilityStaffId) {
			availabilityListDataQuery.refetch();
		}
	}, [availabilityStaffId, selectedWeek])

	getMonday(selectedWeek)

	const getTimeOffMessage = ({ unavailability }: { unavailability: UnavailableDay[] }, date: string) => {
		if (!unavailability || unavailability.length === 0) {
			return (
				<div onClick={() => { handleAddTimeOff(date) }}>
					<i className="ri-calendar-schedule-line"></i>
					<JTranslation typeCase="none" text="Set Time Off" />
				</div>
			);
		}

		return unavailability.map((timeOff, index) => {
			if (timeOff.actionStatus === 'APPROVED') {
				if (timeOff.type === 'full-day') {
					return (
						<div key={index} onClick={() => { handleTimeOffClick(timeOff) }}>
							<i className="ri-calendar-schedule-line"></i>
							<span style={{ marginRight: "3px", borderBottom: "none" }}>
								<JTranslation typeCase="none" text="Time Off:" />
							</span>
							<span style={{ color: "red", borderBottom: "none" }}>
								<JTranslation typeCase="none" text="Full Day" />
							</span>
						</div>
					);
				} else {
					return (
						<div key={index} onClick={() => { handleTimeOffClick(timeOff) }}>
							<i className="ri-calendar-schedule-line"></i>
							<span style={{ marginRight: "3px", borderBottom: "none" }}>
								<JTranslation typeCase="none" text="Time Off:" />
							</span>
							<span style={{ color: "red", borderBottom: "none" }}>
								<JTranslation
									typeCase="none"
									text={`${formatTimeToAmPm(timeOff.startTime)} to ${formatTimeToAmPm(timeOff.endTime)}`}
								/>
							</span>
						</div>
					);
				}
			} else if (timeOff.actionStatus === 'PENDING') {
				return (
					<div key={index} onClick={() => { handleTimeOffClick(timeOff) }}>
						<i className="ri-calendar-schedule-line"></i>
						<span style={{ marginRight: "3px", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Time Off:" />
						</span>
						<span style={{ color: "#fbaa07", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Pending" />
						</span>
					</div>
				);
			}

			return null; // Render nothing for unhandled cases
		});
	};

	const onFullDataSuccess = (res: TimeOffEntry) => {
		const { endTime, notes, reason, startTime, type, endDate, startDate, userData, id } = res

		const parsedStartTime = parseTimeForTimePicker({ formattedTime: startTime, format: 'HH:mm' })
		const parsedEndTime = parseTimeForTimePicker({ formattedTime: endTime, format: 'HH:mm' })

		// set data to timeOff request form
		setTimeOffData({
			startTime: parsedStartTime!,
			endTime: parsedEndTime!,
			fromDate: new Date(startDate),
			toDate: new Date(endDate),
			duration: calculateDuration({
				type: type,
				startTime: parsedStartTime!,
				endTime: parsedEndTime!,
				fromDate: startDate,
				toDate: endDate,
			}),
			note: notes,
			reason,
			type,
			staffId: userData?.preferredName === 'Super Admin' ? 'superadmin' : res?.staffId as string,
			id: id,
			actionStatus: res?.actionStatus,
		})
		// open request TimeOff sidebar
		setShowSchedulerTimeOffSideBar(true);
	}

	const onEditTimeOffError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const getAvailabilityText = (
		availabilities: JSX.Element | (JSX.Element | null)[] | undefined | null
	): string => {
		if (!availabilities) {
			return ''
		}

		const availabilityElements = Array.isArray(availabilities)
			? availabilities.filter((element): element is JSX.Element => element !== null)
			: [availabilities]

		const availabilityTexts = availabilityElements
			.map((element) => element?.props?.children)
			.filter((text) => text !== undefined)

		return availabilityTexts.join(', ').toLowerCase()
	}

	const handleTimeOffClick = (timeOff: UnavailableDay, mode?: string) => {
		setTimeOffPageMode(PageMode.EDIT)
		const params: Id = { id: timeOff.id }
		getFullDataById(timeOffMutation, params, onFullDataSuccess, onEditTimeOffError)
	}

	const handleAddTimeOff = (date: string) => {
		setTimeOffPageMode(PageMode.ADD)

		// set data to timeOff request form
		setTimeOffData({
			...initialValuesForTimeOff,
			staffId: availabilityStaffId as string,
			fromDate: new Date(date),
			toDate: new Date(date)
		})
		// open request TimeOff sidebar
		setShowSchedulerTimeOffSideBar(true);
	}

	return (
		<>
			<CommonModal
				show={(visible.traverse)}
				onHide={() => {
					setVisible({
						traverse: false,
					});
				}}
				modalContent={visible.traverse ? ClearContent : ''}
				title="Review and Confirm Changes"
				callback={accept}
			/>

			<Drawer
				title={(
					<>
						<div className='pt-2 pb-1 px-3 d-flex justify-content-between align-items-center'>
							<h5 className='m-0'>
								<JTranslation typeCase="pascal" text={ADD_NEW_SHIFT} />
							</h5>
							<Button
								type="text"
								icon={<CloseOutlined />}
								onClick={onClose}
							/>
						</div>
						<hr className='m-0 text-muted' />
						<div className="py-3 px-2 add-new-shift-header shadow-sm">
							<div className="d-flex justify-space-between">
								<span
									onClick={() => currentStaffIndex > 0 && handleStaffTraversal('previous')}
									className={`d-flex align-items-center scheduler-arrow ${currentStaffIndex === 0 || availabilityListDataQuery.isFetching ? 'disabled' : ''}`}>

									<i className="ri-arrow-left-s-line"></i>
								</span>
								<div className='d-flex align-items-center w-100 mx-3'>
									{isImageLoaded
										? <img
											className="rounded-circle"
											src={profileImage}
											alt={profileImage}
											style={{ width: '60px', height: '60px' }}
											onError={imageOnErrorHandler}
											onLoad={handleImageLoad}
										/> :
										<img
											className="rounded-circle"
											src={noProfileAvatar}
											alt={profileImage}
											style={{ width: '60px', height: '60px' }}
											onError={imageOnErrorHandler}
											onLoad={handleImageLoad}
										/>
									}
									<div className="d-flex flex-column ms-3">
										<p className="m-0"><b><JTranslation text={toPascalCase(selectedEmployeeName)} /></b></p>
										<small className="m-0"><JTranslation text={jobRoles?.length ? jobRoles.join(" | ") : toPascalCase(NO_ROLES_ASSIGNED)} /></small>
										<small className="m-0">
											{/* {shiftData?.scheduleDate ? `${dayjs(shiftData?.scheduleDate).format('MMM D, YYYY')} : ` : ''}
								{shiftData?.assignedShifts ? shiftData?.assignedShifts?.startTime : ''} - {shiftData?.assignedShifts ? shiftData?.assignedShifts?.endTime : ''} */}
											<JTranslation text={`Work schedule for ${dayjs(selectedWeek).startOf('week').format(DATE_FORMAT)} - ${dayjs(selectedWeek).endOf('week').format(DATE_FORMAT)}`}
											/>
										</small>
									</div>
								</div>
								<span
									onClick={() => currentStaffIndex < staffs.length - 1 && handleStaffTraversal('next')}
									className={`d-flex align-items-center scheduler-arrow ${currentStaffIndex === staffs.length - 1 || availabilityListDataQuery.isFetching ? 'disabled' : ''}`}>
									<i className="ri-arrow-right-s-line"></i>
								</span>
							</div>
						</div>
					</>
				)}	
				width={630}
				onClose={() => onClose()}
				open={shiftSideBar}
				className="custom-offcanvas add-new-shift-offcanvas"
				style={{ paddingBottom: "60px" }}
				closable={false}
				maskClosable={false}
				zIndex={1042}
				afterOpenChange={(e) => setReRender(reRender => reRender + 1)}
			>
				{
					isLoading ?
						<Loader />
						:
						<>
							{availabilityListDataQuery.isFetching ?
								<ShiftAssignmentSkeleton /> :
								<Formik
									onSubmit={postFormDataToApi}
									initialValues={initialValues}
									validationSchema={validationSchema}
									validateOnChange={true}
									validate={compareFormData}
									enableReinitialize={true}
									innerRef={formRef}
									key={reRender}
								>
									{({ values, errors, touched }) => (
										<Form className='shift-schedule-sidebar-form'>
											<div className="row">
												<div className="col-md-12 col-lg-12 mb-3">
													<Accordion className="availability-form-accordion" key={selectedDay} defaultActiveKey={selectedDay}>
														<FieldArray
															name="availability"
															render={() => {
																return (
																	<div>
																		{values.availability.map(
																			(weekDay, availabilityIndex) => {
																				const rolesExist = errors?.availability?.[availabilityIndex];
																				const touchedExist = touched?.availability?.[availabilityIndex];
																				const availability = getStaffAvailabilityForTheDayForAccordian({
																					day: weekDay.day,
																					availabilities: availabilities,
																					selectedWeek
																				})
																				const availabilityText = getAvailabilityText(availability)

																				setMode(PageMode.ADD);
																				if (availabilities && Object.values(availabilities).length > 0) {
																					setMode(PageMode.EDIT);
																				}

																				const date = getDateOfWeekday({ day: weekDay.day, selectedWeek, format: 'YYYY-MM-DD' })
																				const isHolidays = holidays.filter((holiday) => isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })).length > 0
																				const isMandatory = mandatoryDays.filter((mandatoryDay) => isDateInRange({ date: date, startDate: mandatoryDay.startDate, endDate: mandatoryDay.endDate })).length > 0
																				const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
																				const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))

																				// Check if rolesExist and touchedExist are not undefined
																				const isError = rolesExist && touchedExist;

																				// Apply style conditionally
																				const accordionStyle = isError ? { border: '1px solid red' } : undefined;
																				return (
																					<FieldArray name={`availability[${availabilityIndex}].roles`} key={availabilityIndex}
																						render={(availabilityHelper) => {
																							return (
																								<Accordion.Item
																									eventKey={weekDay.day}
																									style={accordionStyle}
																									// className={isHolidays && !getIsOpenForBusiness(weekDay.day) ? "disabled" : ""}
																								>
																									<Accordion.Header className={`accordion-custom-schedule ${getAccordionHeaderClass({ isHolidays, isMandatory })}`}>
																										<>
																											{
																												<span className='workschedule-date'>
																													<JTranslation
																														typeCase="pascal"
																														text={`${weekDay.day
																															} (${getDateOfWeekday({
																																selectedWeek,
																																day: weekDay.day,
																															})})`}
																													/>
																												</span>
																											}

																											{(isHolidays && !getIsOpenForBusiness(weekDay.day)) && <Badge value={BUSINESS_CLOSE} severity="danger" />}
																											{/* {isUnavailable && <Badge value={TIME_OFF} severity='danger' className='m-1 custom-badge-danger' />} */}

																											<div
																												onClick={() => {
																													setShowSchedulerAvailabilitySideBar(true)
																												}}
																												className={getAvailabilityBadgeColor(availabilityText)}
																											>
																												<i className={getAvailabilityInfoIconClass(availabilityText)}></i>
																												Availability: {availability}
																												<i className="ri-settings-3-line availability-settings-icon"></i>
																											</div>

																										</>
																									</Accordion.Header>
																									<Accordion.Body>
																										<div className='manage-time-off d-flex justify-content-between align-items-center'>
																											<div className='d-flex align-items-center border-0'>
																												{isHolidays && <Badge value={HOLIDAY} severity='danger' className='m-1 custom-badge-danger' />}
																												{isMandatory && <Badge value={MANDATORY} severity='warning' className='m-1 custom-badge-primary' />}
																												{/* {isUnavailable && getUnavailabilityBadge({ unavailability })} */}
																											</div>
																											{getTimeOffMessage({ unavailability }, date)}
																										</div>
																										{weekDay?.roles?.length === 0 && AddAssignmentCard({ arrayHelpers: availabilityHelper })}

																										<div className="shift-card-body" id={`shift-card-body-${availabilityIndex}`}>
																											{weekDay.roles.map((role, roleIndex) => {
																												// @ts-ignore
																												const rolesExist = errors?.availability?.[availabilityIndex]?.roles?.[roleIndex];
																												const touchedExist = touched?.availability?.[availabilityIndex]?.roles?.[roleIndex];

																												// Check if rolesExist and touchedExist are not undefined
																												const isError = rolesExist && touchedExist;

																												// Apply style conditionally
																												const roleStyle = isError ? { border: '1px solid red' } : undefined;
																												return (
																													<FieldArray
																														name={`availability.${availabilityIndex}.roles.${roleIndex}`}
																														key={roleIndex}
																														render={() => {
																															// Sort departments alphabetically by depName
																															const sortedDepartments = [...departments].sort((a, b) =>
																																a.depName.localeCompare(b.depName)
																															);

																															// Sort rolesList alphabetically by name
																															const sortedRolesList = [...rolesList].sort((a, b) =>
																																a.name.localeCompare(b.name)
																															);

																															return (
																																<Card key={roleIndex} className='mt-3 work-schedule-card position-relative'>
																																	<div className='row shift-dropdown-role'>
																																		{/* Department Dropdown */}
																																		<div className="col-md-6 d-flex flex-column mb-3">
																																			<strong><JTranslation
																																				typeCase="pascal"
																																				text={AREA_OF_OPERATION} />
																																			</strong>
																																			<Field name={`availability.${availabilityIndex}.roles.${roleIndex}`}>
																																				{({ field, form }: FieldProps) => {
																																					const { depName, departmentId, roleDepartmentName, roleDepartmentId } = field.value;

																																					return (
																																						<Dropdown
																																							value={{
																																								depName: depName ? depName : roleDepartmentName,
																																								departmentId: departmentId ? departmentId : roleDepartmentId,
																																							}}
																																							onChange={(e: DropdownChangeEvent) => {
																																								const { value } = e;
																																								form.setFieldValue(field.name, { ...field.value, ...value });

																																								// Update role options dynamically based on selected department
																																								const filteredRoles = sortedRolesList.filter(
																																									(role) => role.roleDepartmentId === value.departmentId
																																								);
																																								form.setFieldValue(
																																									`availability.${availabilityIndex}.roles.${roleIndex}.roleOptions`,
																																									filteredRoles
																																								);
																																							}}
																																							style={roleStyle}
																																							options={sortedDepartments}
																																							disabled={!departments.length}
																																							optionLabel="depName"
																																							placeholder={"Select Area"}
																																							valueTemplate={depSelectionTemplate}
																																							itemTemplate={depOptionTemplate}
																																							className="w-full md:w-14rem"
																																							filter
																																							pt={{
																																								wrapper: {
																																									style: { fontSize: '1rem' }
																																								}
																																							}}
																																						/>
																																					);
																																				}}
																																			</Field>
																																			<ErrorMessage
																																				className="formik-error"
																																				name={`availability.${availabilityIndex}.roles.${roleIndex}.departmentId`}
																																				component="div"
																																			/>
																																		</div>

																																		{/* Role Dropdown */}
																																		<div className="col-md-6 d-flex flex-column mb-3">
																																			<strong>{ROLE}</strong>
																																			<Field name={`availability.${availabilityIndex}.roles.${roleIndex}`}>
																																				{({ field, form }: FieldProps) => {
																																					const { code, name, roleDepartmentId, roleDepartmentName, roleOptions = [] } = field.value;

																																					// Ensure the role options are also sorted alphabetically
																																					const sortedRoleOptions = [...roleOptions].sort((a, b) =>
																																						a.name.localeCompare(b.name)
																																					);

																																					return (
																																						<Dropdown
																																							value={{
																																								code: code,
																																								name: name,
																																								roleDepartmentId: roleDepartmentId,
																																								roleDepartmentName: roleDepartmentName,
																																							}}
																																							onChange={(e: DropdownChangeEvent) => {
																																								const { value } = e;
																																								form.setFieldValue(field.name, { ...field.value, ...value });
																																							}}
																																							style={roleStyle}
																																							options={sortedRoleOptions.length ? sortedRoleOptions : sortedRolesList}
																																							optionLabel="name"
																																							placeholder={SELECT_ROLE}
																																							valueTemplate={selectionTemplate}
																																							itemTemplate={optionTemplate}
																																							className="w-full md:w-14rem"
																																							filter
																																							pt={{
																																								wrapper: {
																																									style: { fontSize: '1rem' }
																																								}
																																							}}
																																						/>
																																					);
																																				}}
																																			</Field>
																																			<ErrorMessage
																																				className="formik-error"
																																				name={`availability.${availabilityIndex}.roles.${roleIndex}.name`}
																																				component="div"
																																			/>
																																		</div>
																																	</div>

																																	<div className='shift-dropdown-role-body'>
																																		{/* Shift Assignment */}
																																		<div className="d-flex flex-column">
																																			{role.shifts?.map((shiftInfo, shiftIndex) => (
																																				<FieldArray
																																					name={`availability[${availabilityIndex}].roles[${roleIndex}].shifts`}
																																					key={shiftIndex}
																																					render={(shiftsHelper) => {
																																						return (
																																							<ShiftAssignmentCard
																																								key={shiftIndex}
																																								shifts={shifts}
																																								errors={errors}
																																								touched={touched}
																																								availabilityIndex={availabilityIndex}
																																								roleIndex={roleIndex}
																																								shiftIndex={shiftIndex}
																																								arrayHelpers={shiftsHelper}
																																								setShifts={setShifts}
																																								availabilityHelper={availabilityHelper}
																																							/>
																																						);
																																					}}
																																				/>
																																			))}
																																		</div>
																																	</div>
																																</Card>
																															);
																														}}
																													/>
																												)
																											})}
																										</div>
																									
																										{
																											values.availability[availabilityIndex].day === weekDay.day && 
																											values.availability[availabilityIndex].roles.length ? (
																												<div className='py-2 mt-2 mx-2 px-2 shift-card-footer'>
																													<Button
																														className='add-shift-btn'
																														//type='dashed'
																														icon={<i className="ri-add-circle-fill"></i>}
																														onClick={() => {
																															availabilityHelper.push({
																																name: "",
																																code: "",
																																departmentId: "",
																																shifts: [
																																	{
																																		bd: false,
																																		close: false,
																																		code: "",
																																		endTime: "",
																																		name: "",
																																		startTime: "",
																																	},
																																],
																															});

																															// scroll shift card body
																															setTimeout(() => {
																																const shiftCardBody = document.getElementById(`shift-card-body-${availabilityIndex}`);
																																if (shiftCardBody) {
																																	shiftCardBody.scroll({
																																		top: shiftCardBody.scrollHeight - shiftCardBody.offsetHeight,
																																		behavior: 'smooth'
																																	});
																																}
																															}, 100);
																														}}
																													>
																														<small><JTranslation type='text' text={'Add Role'} /></small>
																													</Button>
																												</div>
																											) : <></>
																										}
																										
																									</Accordion.Body>
																								</Accordion.Item>
																							)
																						}}

																					/>
																				)
																			}
																		)}
																	</div>
																)
															}
															}
														/>
													</Accordion>
												</div>
											</div>
											<div className='save-btn-section shadow save-btn-absolute'>
												<button
													className="btn btn btn-custom-primary-outline"
													type="reset"
													data-testid="cancel-btn"
													onClick={() => onClose()}								>
													<JTranslation typeCase="pascal" text={CANCEL} />
												</button>
												<button
													className="btn btn-custom-primary"
													type="submit"
													data-testid="save-btn">
													<JTranslation typeCase="pascal" text={SAVE} />
												</button>
											</div>
										</Form>
									)}
								</Formik>}

						</>

				}

				<AvailabilitySideBar
					availabilityType={availabilityType}
					pageMode={mode}
					setMode={setMode}
					setShowSideBar={setShowSideBar}
					setToastMessage={setToastMessage}
					selectedUserId={availabilityStaffId}
					parent='schedulePage'
					selectedWeek={selectedWeek}
					availabilityListData={availabilityListData}
					onAvailabilitySuccess={onSuccess}
					showSchedulerAvailabilitySideBar={showSchedulerAvailabilitySideBar}
					setShowSchedulerAvailabilitySideBar={setShowSchedulerAvailabilitySideBar}
				/>

				<SchedulerTimeOffSidebar
					pageMode={timeOffPageMode}
					setToastMessage={setToastMessage}
					showSchedulerTimeOffSideBar={showSchedulerTimeOffSideBar}
					setShowSchedulerTimeOffSideBar={setShowSchedulerTimeOffSideBar}
					onTimeOffSuccess={onSuccess}
				/>
			</Drawer>

		</>
	)
}

export default ShiftScheduleSideBar