import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { days, ScheduleData, Staff, getDateOfWeekday, ScheduleMetadata, CustomEvent, JobRoleForShift, isDateInRange, getTimeOffMessage, updateScheduleMetadataWithStaffCount, initialDataScheduleMetadataMinimumEmployeeCount, StaffRequirement, getStaffAvailabilityForTheDay } from '../../helpers/workTimeScheduleHelper'
import { getFirstLetterUpperCase, toPascalCase } from '../../helpers/utils'
import { getUserRoles } from '../../helpers/userRolesHelper'
import { JobRole, PerDayCountForListing, PrimeReactDropDown, StyleDetails, ToastMessageProps } from '../../constants/staticTypes'
import { JTranslation } from '../../helpers/jTranslate/JTranslation'
import { NO_ROLES_ASSIGNED } from '../../constants/strings'
import { useQueryClient } from 'react-query'
import { USER_ROLES } from '../../constants/queryKeys'
import CustomEventForm from './CustomEventForm'
import CustomTooltip from './CustomTooltip'
import dayjs from 'dayjs'
import useMutationHook from '../../hooks/useMutationHook'
import useQueryHook from '../../hooks/useQueryHook'
import UserAvatar from './UserAvatar'
import TimeOffCard from './TimeOffCard'
import { Divider } from 'primereact/divider'
import { Badge } from 'primereact/badge'
import { MultiSelect } from 'primereact/multiselect'

type Props = {
    reloadPageData: () => void
    saveEmployeeName: (name: string) => void
    scheduleId: string
    selectedWeek: dayjs.Dayjs
    setData: Dispatch<SetStateAction<Staff | null>>,
    setSelectedDay: Dispatch<SetStateAction<string>>
    setShiftSideBar: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: Dispatch<SetStateAction<ToastMessageProps>>
    shiftSideBar: boolean,
    staffingRequirement: PerDayCountForListing[]
    staffsWithSchedule: ScheduleData | null
    scheduleMetadata: ScheduleMetadata | null
    selectedUsers: PrimeReactDropDown[]
    setSelectedUsers: Dispatch<SetStateAction<PrimeReactDropDown[]>>
    isLoading: boolean
    isDateOlder: boolean
}

const ShiftScheduler = ({
    reloadPageData,
    saveEmployeeName,
    scheduleId,
    selectedWeek,
    setData,
    setShiftSideBar,
    setToastMessage,
    shiftSideBar,
    staffingRequirement,
    staffsWithSchedule,
    setSelectedDay,
    scheduleMetadata,
    selectedUsers,
    setSelectedUsers,
    isLoading,
    isDateOlder
}: Props) => {
    const queryClient = useQueryClient()
    const scheduleMutation = useMutationHook(queryClient, true)
    const { staffs, schedule, holidays, mandatoryDays } = staffsWithSchedule!
    const totalEvents = scheduleMetadata?.customEvents?.map(event => event?.eventData)?.flat().length ?? 0

    const [rolesList, setRolesList] = useState<JobRole[]>([]); // roles list
    const [staffingRequirementWithMinStaff, setStaffingRequirementWithMinStaff] = useState<StaffRequirement[]>(initialDataScheduleMetadataMinimumEmployeeCount)
    const [hoveredRow, setHoveredRow] = useState<string | null>(null);
    const [allUsers, setAllUsers] = useState<PrimeReactDropDown[]>([])

    useEffect(() => {
        if (staffs.length) {
            const allStaffs: PrimeReactDropDown[] = staffs.map((staff) => {
                const { id, preferredName } = staff
                return { code: id, name: preferredName }
            })
            setAllUsers(allStaffs)
        }
    }, [staffs])

    // roles list
    useQueryHook(USER_ROLES, getUserRoles, (res) => {
        let filteredData = res.data?.data.lists.filter((item: any) => item.isAvailableForSchedule)

        const sortedRoles = filteredData.sort((a: any, b: any) =>
            a.jobRoleName.localeCompare(b.jobRoleName)
        );

        setRolesList(sortedRoles);
    });

    useEffect(() => {
        if (rolesList.length && scheduleMetadata && staffingRequirement) {
            const { staffRequirement } = scheduleMetadata

            const listWithMinCount = updateScheduleMetadataWithStaffCount({
                rolesList: rolesList,
                staffRequirement: staffRequirement,
                staffingRequirement: staffingRequirement
            });

            setStaffingRequirementWithMinStaff(listWithMinCount)
        }
    }, [rolesList, scheduleMetadata, staffingRequirement]);

    const getRoleColorCode = (roleId: string): StyleDetails => {
        const role = rolesList?.find(role => role.id === roleId)
        if (role?.styleDetails) {
            return role.styleDetails
        } else {
            return {
                backgroundColor: '#87b6fb',
                color: '#262930'
            }
        }
    }

    const onSuccess = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
        reloadPageData()
    }

    const onError = (message: string, variant: string) => {
        setToastMessage({ message, variant, show: true })
    }

    const staffNameTemplate = (rowData: Staff) => {
        const { id, preferredName = '', jobRoles = [], photoThumbnail } = rowData
        const userOptions: PrimeReactDropDown = { code: id, name: preferredName }
        const checked = selectedUsers.some((user) => user.code === id)
        const roles = jobRoles
            .map((role: JobRoleForShift | undefined) => role?.jobRole?.jobRoleName)
            .filter(Boolean)
            .join(', ')

        return (
            <div
                key={id}
                className="text-nowrap d-flex align-items-center"
                onMouseEnter={() => setHoveredRow(id)}  // Track hovered row ID
                onMouseLeave={() => setHoveredRow(null)} // Reset when mouse leaves
            >
                {/* Conditionally display checkbox on hover */}
                {(hoveredRow === id || checked) && (
                    <div className="select-staff-checkbox">
                        <Checkbox
                            onChange={(e) => {
                                if (e.checked) {
                                    setSelectedUsers([...selectedUsers, userOptions])
                                } else {
                                    setSelectedUsers(selectedUsers.filter((user) => user.code !== id))
                                }
                            }}
                            checked={checked}
                        />
                    </div>
                )}

                {/* Avatar */}
                {hoveredRow !== id && !checked && (
                    <UserAvatar imageUrl={photoThumbnail} name={preferredName} />
                )}

                {/* User Details */}
                <div style={{ width: '10rem' }}>
                    <div className="overflow-ellipsis fw-bold">
                        <JTranslation typeCase="pascal" text={preferredName} />
                    </div>
                    <div className="overflow-ellipsis" title={roles}>
                        <JTranslation
                            typeCase="pascal"
                            text={roles || NO_ROLES_ASSIGNED}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const cellBodyTemplate = ({ day, rowData }: { day: string; rowData: Staff }) => {
        const { shifts, preferredName, unavailableDays, availabilities } = rowData
        const date = getDateOfWeekday({ day, selectedWeek, format: 'YYYY-MM-DD' })
        const availabilityText = getStaffAvailabilityForTheDay({ day, availabilities, selectedWeek, type: 'schedule' })

        const unavailabilityArray = unavailableDays.filter((unavailableDay) => isDateInRange({ date: date, startDate: unavailableDay.startDate, endDate: unavailableDay.endDate }))
        const unavailability = unavailabilityArray.filter((item) => (item.actionStatus === 'APPROVED' || item.actionStatus === 'PENDING'))
        const shiftsArray = shifts[date]
        const isBusinessOpen = holidays.filter((holiday) => {
            const isSameDate = isDateInRange({ date: date, startDate: holiday.startDate, endDate: holiday.endDate })
            const isNotOpenForBusiness = holiday.isOpenForBusiness === false
            return isSameDate && isNotOpenForBusiness
        }).length > 0

        const timeOffText = getTimeOffMessage({ unavailability: unavailability, type: 'schedule', actionButton: true }, date)?.length
            ? <TimeOffCard unavailability={unavailability} type='schedule' actionButton={true} date={date} />
            : availabilityText

        if (schedule.actionStatus === 'draft' && !Object.keys(shifts).length && !isDateOlder) {
            return (
                timeOffText ? (
                    <div className=''>
                        {timeOffText}
                        <div
                            className={`small-add-button`}
                            onClick={() => {
                                setShiftSideBar(!shiftSideBar)
                                saveEmployeeName(rowData.preferredName)
                                setSelectedDay(day)
                                setData(rowData)
                            }}
                        >
                            <i className="ri-add-line"></i>
                        </div>
                    </div>
                ) : (<div className='d-flex'>
                    <span
                        className={`${unavailability ? 'unavailable' : ''}`}
                    >
                        {
                            getTimeOffMessage({ unavailability: unavailability, type: 'schedule' }, date)?.length
                                ? <TimeOffCard unavailability={unavailability} type='schedule' actionButton={true} date={date} />
                                : getStaffAvailabilityForTheDay({ day, availabilities, selectedWeek })
                        }
                    </span>
                    <div
                        className={`scheduler-cell-body`}
                        onClick={() => {
                            setShiftSideBar(!shiftSideBar)
                            saveEmployeeName(rowData.preferredName)
                            setSelectedDay(day)
                            setData(rowData)
                        }}
                    >
                        <i className="ri-add-line"></i>
                    </div>
                </div>)
            )
        }

        if (shiftsArray?.length) {
            // Sort the shiftsArray based on startTime and endTime 
            const sortedShifts = shiftsArray.sort((a, b) => {
                const startA = dayjs(a.assignedShifts.startTime, "hh:mm A");
                const startB = dayjs(b.assignedShifts.startTime, "hh:mm A");
                const endA = dayjs(a.assignedShifts.endTime, "hh:mm A");
                const endB = dayjs(b.assignedShifts.endTime, "hh:mm A");

                // Compare start times first; if they're equal, compare end times
                if (startA.isSame(startB)) {
                    return endA.isBefore(endB) ? -1 : 1;
                }
                return startA.isBefore(startB) ? -1 : 1;
            });

            return sortedShifts.map((shift, index) => {
                const { assignedShifts } = shift;
                const { startTime, endTime, isBd, isClose, role, roleId } = assignedShifts;
                const styleDetails = getRoleColorCode(roleId);
                const { backgroundColor, color } = styleDetails;

                return (
                    <>
                        {index === 0 && timeOffText && timeOffText}

                        <CustomTooltip
                            key={index}
                            staffName={preferredName}
                            showDelay={500}
                            content={sortedShifts}
                        >
                            <div
                                className="scheduler-shift-card-body"
                                style={{
                                    backgroundColor: "white",
                                    border: `2px solid ${backgroundColor}`,
                                }}
                                onClick={() => {
                                    if (schedule.actionStatus === "draft" && !isDateOlder) {
                                        setShiftSideBar(!shiftSideBar);
                                        saveEmployeeName(rowData.preferredName);
                                        setSelectedDay(day);
                                        setData(rowData);
                                    }
                                }}
                            >
                                <div className="d-flex">
                                    <div className="flex-grow-0 d-flex align-items-center justify-center">
                                        <span
                                            className="shift-role"
                                            style={{
                                                backgroundColor,
                                                color: color,
                                            }}
                                        >
                                            {getFirstLetterUpperCase(role)}
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 shift-name-main text-nowrap">
                                        {isClose ? `${startTime} - Close` : ""}
                                        {isBd ? `${startTime} - V` : ""}
                                        {!isBd && !isClose
                                            ? `${startTime} - ${endTime}`
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </CustomTooltip>

                        {index === sortedShifts.length - 1 &&
                            schedule.actionStatus === "draft" &&
                            !isDateOlder ? (
                            <div
                                className={`small-add-button`}
                                onClick={() => {
                                    setShiftSideBar(!shiftSideBar);
                                    saveEmployeeName(rowData.preferredName);
                                    setSelectedDay(day);
                                    setData(rowData);
                                }}
                            >
                                <i className="ri-add-line"></i>
                            </div>
                        ) : null}
                    </>
                );
            });
        } else {
            return schedule.actionStatus === 'draft' && !isDateOlder ? (
                <div className=''>
                    {timeOffText}
                    <div
                        className={timeOffText ? 'small-add-button' : 'scheduler-cell-body'}
                        onClick={() => {
                            setShiftSideBar(!shiftSideBar)
                            saveEmployeeName(rowData.preferredName)
                            setSelectedDay(day)
                            setData(rowData)
                        }}
                    >
                        <i className="ri-add-line"></i>
                    </div>
                </div >
            ) : timeOffText
        }
    }

    const renderColumns = () => {
        return days.map((day, index) => {
            const customEvent = scheduleMetadata?.customEvents?.find((event) => event.dayName.toLowerCase() === day.toLowerCase())
            return (
                <Column
                    key={index}
                    headerClassName="workTimeScheduleHeader p-0"
                    header={(headerData) => (
                        <CustomEventForm
                            parent='web'
                            scheduleId={scheduleId}
                            scheduleMetadata={scheduleMetadata}
                            customEvent={customEvent ?? {} as CustomEvent}
                            scheduleMutation={scheduleMutation}
                            onSuccess={onSuccess}
                            onError={onError}
                            day={day}
                            selectedWeek={selectedWeek}
                            actionStatus={schedule.actionStatus}
                            staffingRequirement={staffingRequirement}
                            staffingRequirementWithMinStaff={staffingRequirementWithMinStaff}
                            headerData={headerData}
                            holidays={holidays}
                            mandatoryDays={mandatoryDays}
                            isDateOlder={isDateOlder}
                        />
                    )}
                    headerStyle={{ height: '110px' }}
                    body={(data) => cellBodyTemplate({ day, rowData: data })}
                    //style={{ width: '12.5%' }}
                    style={{ minWidth: '12rem' }}
                    className="shift-column"
                />
            )
        })
    }

    const tableHeader = () => {
        return (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center" style={{minWidth:'12rem', height: '110px'}}>
                <div className='w-100 px-4 d-flex justify-content-start align-items-center' style={{height: '60px'}}>
                    <Checkbox
                        inputId="select-all-users"
                        name="select-all"
                        onChange={(e) => e.checked ? setSelectedUsers(allUsers) : setSelectedUsers([])}
                        checked={selectedUsers.length !== 0 && selectedUsers.length === allUsers.length}
                    />
                    <label htmlFor="select-all-users" className="mx-2">
                        <JTranslation text={"Select All"} />
                    </label>                    
                </div>
                <Divider className='p-0 m-0 py-1' />
                <div className='w-100 d-flex justify-content-between align-items-center px-4' style={{height: '43px'}}>
                    <JTranslation text={"Events"} typeCase='pascal' />
                    <Badge className="ms-2" value={totalEvents} />
                </div>
            </div>
        );
    };

    return (
        <div className="card h-100">
            <DataTable
                value={staffs}
                showGridlines
                scrollable
                scrollHeight='100%'
                className="work-time-schedule-table d-flex h-100 flex-column"
                loading={isLoading}
                pt={{
                    loadingOverlay: {
                        style: { zIndex: 3 }
                    }
                }}
            >
                <Column
                    field="day"
                    className="overflow-ellipsis"
                    header={tableHeader()}
                    headerClassName='p-0'
                    body={staffNameTemplate}
                    style={{ minWidth: '12rem' }}
                    headerStyle={{ height: '110px' }}
                    frozen
                    pt={{
                        headerTitle: { style: { width: '100%' } }
                    }}
                />
                {renderColumns()}
            </DataTable>
        </div>
    )
}

export default React.memo(ShiftScheduler)
