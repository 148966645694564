import { Button, Drawer } from 'antd'
import React, { useContext } from 'react'
import { PageMode } from '../../constants/constants'
import { ToastMessageProps } from '../../constants/staticTypes'
import { REQUEST_TIME_OFF, TIME_OFF_REQUEST } from '../../constants/strings'
import { JTranslation } from '../../helpers/jTranslate'
import { CloseOutlined } from "@ant-design/icons"
import TimeOffRequestForm from '../time_off_request/TimeOffRequestForm'
import { ScheduleManagementCtx } from '../../context/ScheduleManagementCtxProvider'
import { Badge } from 'primereact/badge'

type Props = {
  pageMode: PageMode.ADD | PageMode.EDIT
  setToastMessage: React.Dispatch<React.SetStateAction<ToastMessageProps>>
  showSchedulerTimeOffSideBar: boolean
  setShowSchedulerTimeOffSideBar: React.Dispatch<React.SetStateAction<boolean>>
  onTimeOffSuccess?: (message?: string, variant?: string) => void
}

function SchedulerTimeOffSidebar({
  pageMode,
  setToastMessage,
  showSchedulerTimeOffSideBar,
  setShowSchedulerTimeOffSideBar,
  onTimeOffSuccess
}: Readonly<Props>) {
  const { timeOffData } = useContext(ScheduleManagementCtx);
  
  let severity: "info" | "success" | "warning" | "danger" | null | undefined = 'info';
  if (timeOffData?.actionStatus === 'APPROVED') {
    severity = 'success';
  } else if (timeOffData?.actionStatus === 'REJECTED' || timeOffData?.actionStatus === 'CANCELLED') {
    severity = 'danger';
  } else if (timeOffData?.actionStatus === 'PENDING') {
    severity = 'warning';
  }
  
  return (
    <Drawer
      open={showSchedulerTimeOffSideBar}
      onClose={() => {
        setShowSchedulerTimeOffSideBar(false);
      }}
      width={630}
      placement="right"
      className="custom-offcanvas"
      zIndex={1042}
      title={
        <div className='d-flex align-items-center'>
          <JTranslation typeCase="pascal" text={pageMode === PageMode.ADD ? REQUEST_TIME_OFF : `Edit ${TIME_OFF_REQUEST}`} />
          {pageMode === PageMode.EDIT && (
            <Badge className='ms-2' value={<JTranslation typeCase="pascal" text={timeOffData?.actionStatus ?? ''} />} severity={severity} />
          )}
        </div>
      }
      closable={false}
      maskClosable={false}
    >
      <div style={{ position: "absolute", top: 15, right: 16 }}>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={() => setShowSchedulerTimeOffSideBar(false)}
        />
      </div>

      <div className="row">
        <div className=" col-md-12 col-lg-12 mb-3 ">
          <TimeOffRequestForm
            onClose={() => setShowSchedulerTimeOffSideBar(false)}
            pageMode={pageMode}
            setToastMessage={setToastMessage}
            parent={"scheduler"}
            onTimeOffSuccess={onTimeOffSuccess}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default SchedulerTimeOffSidebar