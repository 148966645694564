import _ from 'lodash'
import { ADD_NEW_SHIFT, APPROVE, NO_WORK_SHIFT_ADDED, REJECT } from '../constants/strings'
import { AddGlobalStaffingCount, ConflictData, Conflicts, DayOfWeek, JobRole, MinimumEmployeeCountType, Payload, PerDayCountForListing, PrimeReactDropDown, Reservations, Roles, ShiftAssignment } from '../constants/staticTypes'
import { AlertVariant, DATE_FORMAT, HttpMethods } from '../constants/constants'
import { AxiosResponse } from 'axios'
import { Button } from 'primereact/button'
import { FieldArrayRenderProps } from 'formik'
import { capitalizeFirstLetter, dateFormatter, formatTimeToAmPm, getErrorMessage } from './utils'
import { JTranslation } from './jTranslate'
import { omit } from 'lodash'
import { staffingRequirementsApi, scheduleApi } from '../constants/apiEndPoints'
import { SwapRequestStatus, SwapStaffDetails } from './swapScheduleHelper'
import { UseMutationResult } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import dayjs, { Dayjs } from 'dayjs'
import HttpServiceHelper from './httpServiceHelper'
import isBetween from 'dayjs/plugin/isBetween'; // Import the weekday plugin
import isoWeek from 'dayjs/plugin/isoWeek'; // Import the isoWeek plugin
import moment from 'moment'
import weekday from 'dayjs/plugin/weekday'; // Import the weekday plugin
import CustomTooltip from '../components/manage_work_schedule/CustomTooltip'
import { Badge } from 'primereact/badge'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'

dayjs.extend(weekday); // Extend dayjs with weekday plugin
dayjs.extend(isoWeek); // Extend dayjs with isoWeek plugin
dayjs.extend(isBetween); // Extend dayjs with isBetween plugin

export type Holidays = {
	startDate: string; // "YYYY-MM-DD" format
	endDate: string; // "YYYY-MM-DD" format
	startTime: string | null;
	endTime: string | null;
	timeLabel: string | null;
	durationType: 'full-day' | 'half-day' | 'custom'; // assuming possible values based on context
	title: string;
	isOpenForBusiness: boolean;
	isPaid: boolean;
	isApproved: boolean;
	isPublished: boolean | null;
	actionStatus: 'draft' | 'pending' | 'completed' | 'cancelled'; // assuming possible values based on context
};

export type ConvertedData = {
	roles: Roles[];
};

export type UnavailableDay = {
	id: string;
	tenantStaffId: string;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
	type: "full-day" | "time-off"
	reason: string | null;
	note: string;
	actionStatus: "APPROVED" | "PENDING" | "REJECTED" | "CANCELLED";
	tenantId: string;
	deletedBy: string | null;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
	tenant_staff_id: string;
	approved_tenant_staff_id: string | null;
	deleted_by: string | null;
};

export type AvailableTime = {
	to: string;
	from: string;
};

export type Event = {
	id: string;
	isRecurring: boolean;
	startDate: string;
	endDate: string;
	tenantId: string;
	tenantStaffId: string;
	approvedTenantStaffId: string | null;
	deletedBy: string | null;
	actionStatus: "APPROVED" | "PENDING" | "REJECTED" | "CANCELLED";
	note: string | null;
	type: "full-day" | "time-off";
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	dateRange: string;
	notes: string;
	weekday: string;
	isAllDay: boolean;
	isAvailable: boolean;
	availableTimes?: AvailableTime[];
};

export type Availability = {
	[key: string]: Event[];
};


export type UpdateDraftMetaData = {
	scheduleId: string;
	scheduleMetadata: ScheduleMetadata;
}

export type SwapRequestDetails = {
	actionStatus: SwapRequestStatus;
	createdAt: string;
	fromShiftDetails: { fromEndTime: string; fromStartTime: string; fromRole: string; scheduleDate: string; shiftName: string; };
	fromShiftId: string;
	fromTenantStaff: SwapStaffDetails;
	fromTenantStaffId: string;
	id: string;
	requestType: 'swap' | 'pool';
	scheduleId: string;
	tenantId: string;
	toShiftDetails: { toEndTime: string; toStartTime: string; toRole: string; scheduleDate: string; shiftName: string; };
	toShiftId: string;
	toTenantStaff: SwapStaffDetails;
	toTenantStaffId: string;
	updatedAt: string;
}

export type ScheduleMetadata = {
	staffRequirement: StaffRequirement[];
	customEvents: CustomEvent[];
}

export type PublishedSchedule = {
	scheduleId: string;
	scheduleStartDate: string;
	scheduleEndDate: string;
	id: string;
	tenantId: string;
	tenantStaffId: string;
	scheduleDate: string;
	assignedShifts: AssignedShift;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	schedule_id: string;
	tenant_staff_id: string;
	tenant_id: string;
	fromSwapRequests: SwapRequestDetails[];
	toSwapRequests: SwapRequestDetails[];
}

export type PublishedScheduleExtended = {
	id: string;
	startDate: string;
	endDate: string;
	tenantId: string;
	actionStatus: string;
	publishedAt: string;
	scheduleMetadata: ScheduleMetadata;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
	shifts: PublishedSchedule[];
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
}

export type PoolData = {
	status: SwapRequestStatus
	toShiftId: string
	requestType: 'pool' | 'swap'
	swapRequestId: string
}

export type AssignedShift = {
	shiftName: string
	startTime: string
	endTime: string
	role: string
	roleId: string
	departmentId: string
	isClose: boolean
	isBd: boolean
	isPoolRequested?: boolean
	poolData?: PoolData
	oldData?: PoolData
}

export type AssignShiftsByDate = {
	[date: string]: AssignedShift[];
};

export type AddOrUpdateSchedule = {
	scheduleId: string;
	tenantStaffId: string;
	assignShiftsByDate: AssignShiftsByDate;
};

export type PublishDraftedScheduleId = {
	scheduleId: string;
	scheduleData?: ScheduleIdsResponse | null;
};

export type CopySchedulePayloadType = {
	scheduleId: string;
	startDate: string;
	endDate: string;
};

export type CopyScheduleIndividualPayloadType = {
	scheduleId: string;
	tenantStaffIds: string[];
	startDate: string;
	endDate: string;
};

export interface JobRoleWithMinCount extends JobRole {
	minCount: string,
}

export type JobRoleDepartment = {
	id: string;
	departmentName: string;
	departmentDescription: string | null;
};

export type JobRoleForShift = {
	id: string;
	tenantStaffId: string;
	jobRoleId: string;
	effectiveFrom: string;
	effectiveEnd: string | null;
	isCurrent: boolean;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	job_role_id: string;
	tenant_staff_id: string;
	jobRole: JobRole;
};

export type MandatoryDay = {
	id: string;
	tenantId: string;
	startDate: string;
	endDate: string;
	isApproved: boolean | null;
	isDraft: boolean | null;
	isPublished: boolean | null;
	timeLabel: string;
	durationType: string;
	title: string | null;
	actionStatus: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	tenant_id: string;
};

export type Staff = {
	id: string;
	email: string | null;
	firstName: string;
	lastName: string;
	preferredName: string;
	signedUrl: string;
	photoThumbnail: string | null;
	jobRoles: JobRoleForShift[];
	shifts: ShiftsByDate;
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
};

export type EventFormData = {
	id: string,
	guests: number,
	time: dayjs.Dayjs,
	desc: string,
	guestName: string,
}

export const initialEventFormValues: EventFormData = {
	id: uuidv4(),
	guests: 0,
	time: dayjs().add(30 - dayjs().minute() % 30, 'minute').second(0).millisecond(0),
	desc: '',
	guestName: ''
}

export type CustomEvent = {
	date: string;
	dayId: number;
	dayName: string;
	eventData: EventFormData[]; // You can specify a more detailed type if you know the structure of the event data
};

export type StaffRequirement = {
	date: string; // ISO date string
	dayId: string;
	dayName: string;
	customCount: string | null;
	jobRoles: JobRoleWithMinCount[];
}

export type Schedule = {
	id: string;
	startDate: string;
	endDate: string
	actionStatus: 'draft' | 'published' | 'clear-draft' | '';
	publishedAt: string | null;
	scheduleMetadata: ScheduleMetadata;
}

export type ScheduleData = {
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
	schedule: Schedule;
	staffs: Staff[];
};

export type ScheduleIdInfo = {
	id: string
	startDate: string
	endDate: string
	actionStatus: 'draft' | 'published'
	publishedAt: null | string
}

export type ScheduleIdsResponse = {
	draft: ScheduleIdInfo
	published: ScheduleIdInfo
}

export type DraftResponse = {
	id: string;
	startDate: string;
	endDate: string;
	actionStatus: string;
	publishedAt: string | null;
	scheduleMetadata: ScheduleMetadata;
}

export type CreateDraftResponse = {
	id: string
	startDate: null
	endDate: null
	actionStatus: string
	publishedAt: null
	created: true
}

export type StartAndEndDates = {
	startDate: string,
	endDate: string
}

export type ScheduleId = {
	scheduleId: string,
}
export interface Shift {
	departmentId: string;
	departmentName: string;
	date: string
	day: DayOfWeek
	role: string
	roleId: string
	shiftName: string
	startTime: string
	endTime: string
	isClose: boolean
	isBd: boolean
}
export interface ExtendedShift extends Shift {
	assignedShifts: Shift
	id: string
}
export interface StaffCount {
	date: string;
	dayId: string;
	dayName: string;
	customCount: string | null;
}
export interface EmployeeScheduleData {
	preferredName: string;
	staffName: string;
	staffId: string;
	staffData: Staff;
	roles: string[],
	staffRequirement: StaffRequirement[]
	monday: Shift[];
	tuesday: Shift[];
	wednesday: Shift[];
	thursday: Shift[];
	friday: Shift[];
	saturday: Shift[];
	sunday: Shift[];
	customEvents: CustomEvent[] | []
}
export interface StaffScheduleData {
	day: DayOfWeek | "";
	isHoliday: boolean;
	reservations: Reservations[] | [];
	mergeSlot: string;
	schedule: Shift[] | [];
}
export interface StaffScheduleViewData {
	data: StaffScheduleData[];
}
export interface ScheduleRow {
	time: TimeOfDay;
	// mergeSlot: string,
	schedules: {
		monday: StaffScheduleData;
		tuesday: StaffScheduleData
		wednesday: StaffScheduleData
		thursday: StaffScheduleData;
		friday: StaffScheduleData;
		saturday: StaffScheduleData;
		sunday: StaffScheduleData;
	}
}
export interface ScheduleViewData {
	data: ScheduleRow[];
}

export const days: DayOfWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export interface Reservation {
	eventName: string;
	guestCount: number;
	eventTime: string;
}

type getDate = {
	selectedWeek: dayjs.Dayjs,
	day: string,
	format?: string,
}

export interface DropDown {
	name: string
	code: string
}

export interface Location {
	name: string
	code: string
}

export interface Shifts {
	name: string
	code: string
}

export interface StaffCounts {
	expected: number;
	current: number;
}

interface WeeklyStaffSchedule {
	[day: string]: StaffCounts;
}

export interface ShiftTransformed {
	scheduleDate: string;
	isBd: boolean;
	role: string;
	roleId: string;
	endTime: string;
	isClose: boolean;
	shiftName: string;
	startTime: string;
}

export interface TransformedData {
	scheduleId: string;
	scheduleStartDate: string;
	scheduleEndDate: string;
	id: string;
	tenantId: string;
	tenantStaffId: string;
	assignedShifts: ShiftTransformed[];
	availabilities: Availability;
	unavailableDays: UnavailableDay[];
	holidays: Holidays[];
	mandatoryDays: MandatoryDay[];
}

type ConflictMessage = {
	conflicts: Conflicts,
	selectedUsers: PrimeReactDropDown[]
}

export const generateConflictMessages = ({ conflicts, selectedUsers }: ConflictMessage): { name: string; message: string }[] => {
	const { existingSchedule, staffConflicts, staffAvailability, holidays, timeOffRequests } = conflicts;
	const messages: { name: string; message: string }[] = [];

	const getNameFromStaffId = (staffId: string): string | null => {
		return selectedUsers.find((user) => user.code === staffId)?.name ?? null;
	};

	if (existingSchedule) {
		messages.push({ name: "Schedule", message: "A schedule has already been published for the week." });
	}

	if (holidays?.length > 0) {
		messages.push(
			...holidays.map(({ title, startDate, endDate }) => {
				let message = `Holiday "${title}" from ${dateFormatter({ date: startDate })} to ${dateFormatter({ date: endDate })}.`
				if(startDate === endDate) {
					message = `Holiday "${title}" on ${dateFormatter({ date: startDate })}.`
				}
				return {
					name: "Holiday",
					message
				}
			})
		);
	}

	if (staffConflicts && Object.keys(staffConflicts).length > 0) {
		Object.entries(staffConflicts).forEach(([staffId, conflict]) => {
			const staffName = getNameFromStaffId(staffId) || "Unknown";
			const { availability, timeOffRequests } = conflict;

			if (availability?.length > 0) {
				messages.push(
					...availability.map(({ startDate, endDate, type }) => {
						const name = capitalizeFirstLetter(staffName)
						let message = `${name} is unavailable for some shifts from ${dateFormatter({ date: startDate })} (${type.replace("-", " ")}).`
						if(dateFormatter({date: endDate}) !== '') {
							message = `${name} is unavailable for some shifts from ${dateFormatter({ date: startDate })} to ${dateFormatter({ date: endDate })} (${type.replace("-", " ")}).`
						}
						return { name, message }
					})
				);
			}

			if (timeOffRequests?.length > 0) {
				messages.push(
					// @ts-ignore
					...timeOffRequests.map(({ startDate, endDate, type }) => {
						const name = capitalizeFirstLetter(staffName)
						let message = `${name} has taken time off from ${dateFormatter({ date: startDate })} to ${dateFormatter({ date: endDate })} (${type.replace("-", " ")}).`
						if(startDate === endDate) {
							message = `${name} has taken time off on ${dateFormatter({ date: startDate })} (${type.replace("-", " ")}).`
						}
						return { name, message }
					})
				);
			}
		});
	}

	if (staffAvailability?.length > 0) {
		messages.push(
			...staffAvailability.flatMap(({ staffName, conflicts }) =>
				conflicts.map(({ startDate, endDate, type }) => {
					const name = capitalizeFirstLetter(staffName)
					let message = `${name} is unavailable for some shifts from ${dateFormatter({ date: startDate })} (${type.replace("-", " ")}).`
					if(dateFormatter({date: endDate}) !== '') {
						message = `${name} is unavailable for some shifts from ${dateFormatter({ date: startDate })} to ${dateFormatter({ date: endDate })} (${type.replace("-", " ")}).`
					}
					return { name, message }
				})
			)
		);
	}

	if (timeOffRequests?.length > 0) {
		messages.push(
			...timeOffRequests.flatMap(({ staffName, requests }) =>
				requests.map(({ startDate, endDate, type }) => {
					const name = capitalizeFirstLetter(staffName)
					let message = `${name} has taken time off from ${dateFormatter({ date: startDate })} to ${dateFormatter({ date: endDate })} (${type.replace("-", " ")}).`
					if(startDate === endDate) {
						message = `${name} has taken time off on ${dateFormatter({ date: startDate })} (${type.replace("-", " ")}).`
					}
					return { name, message }
				})
			)
		);
	}

	return messages;
};

export const getTimeOffMessage = (
	{
		unavailability,
		type = 'others',
		actionButton = false
	}: {
		unavailability: UnavailableDay[],
		type?: 'schedule' | 'others',
		actionButton?: boolean
	},
	date: string
) => {
	// Filter unavailability based on the matching date
	const filteredDays = unavailability.filter(
		(timeOff) => timeOff.startDate === date || timeOff.endDate === date
	);

	// console.log({filteredDays})
	const wrapTemplate = (children: JSX.Element, timeOff: UnavailableDay) => {
		if (type === 'others') {
			return (
				<div className="scheduler-shift-card-body-not-available">
					<div className="d-flex">
						<div className="flex-grow-0 d-flex align-items-center justify-center">
							<span className="shift-role"><i className="ri-time-line"></i></span>
						</div>
						<div className="flex-grow-1 shift-name-main text-nowrap">
							{children}
						</div>
					</div>
				</div>
			)
		}
		if (type === 'schedule') {
			const acceptRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
				confirmDialog({
					className: 'custom-confirm-dialog',
					message: <JTranslation typeCase="capitalize" text={'Are you sure you want to accept this request?'} />,
					icon: 'pi pi-info-circle',
					acceptClassName: 'p-button-success',
					rejectClassName: 'p-button-danger',
					draggable: false,
					accept: () => {
						// TODO: call API to accept request
					},
				})
			}

			const rejectRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
				confirmDialog({
					className: 'custom-confirm-dialog',
					message: <JTranslation typeCase="capitalize" text={'Are you sure you want to reject this request?'} />,
					icon: 'pi pi-info-circle',
					draggable: false,
					acceptClassName: 'p-button-success',
					rejectClassName: 'p-button-danger',
					accept: () => {
						// TODO: call API to reject request
					},
				})
			}

			const checkIfMultipleDays = timeOff?.startDate !== timeOff?.endDate;
			const toolTipContent = (
				<div className="d-flex flex-column" style={{ minWidth: '270px' }}>
					<div className="d-flex flex-row justify-content-between my-2">
						<div className="flex-grow-1">
							<strong><JTranslation typeCase="none" text="Requested Time off" /></strong>
						</div>
						{
							timeOff?.actionStatus === 'PENDING' && (
								<div className="flex-grow-0">
									<Badge
										className="custom-badge"
										value={<JTranslation typeCase="pascal" text={'Pending'} />}
										severity="warning"
									/>
								</div>
							)
						}
					</div>
					<div className="mt-2 d-flex flex-row align-items-center">
						{checkIfMultipleDays ? (
							<strong>
								{`${dayjs(timeOff?.startDate).format(DATE_FORMAT)} - ${dayjs(timeOff?.endDate).format(DATE_FORMAT)}`}
							</strong>
						) : (
							<strong>
								{
									(timeOff?.startTime && timeOff?.endTime)
										? `${dayjs(timeOff?.startDate).format(DATE_FORMAT)} | ${formatTimeToAmPm(timeOff?.startTime)} to ${formatTimeToAmPm(timeOff?.endTime)}`
										: `${dayjs(timeOff?.startDate).format(DATE_FORMAT)}`
								}
							</strong>
						)}
					</div>
					<hr />
					<div className='mt-2'>
						<JTranslation typeCase="none" text={timeOff?.reason ?? ''} />
					</div>
					{timeOff?.actionStatus === 'PENDING' && actionButton && (
						<>
							<hr />
							<ConfirmDialog />
							<div className='mt-2 d-flex align-items-center justify-content-end'>
								<button
									className='btn btn-sm btn-danger'
									type='button'
									onClick={rejectRequest}
								>
									<JTranslation typeCase="pascal" text={REJECT} />
								</button>
								<button
									className='ms-2 btn btn-sm btn-success'
									type='button'
									onClick={acceptRequest}
								>
									<JTranslation typeCase="pascal" text={APPROVE} />
								</button>
							</div>
						</>
					)}
				</div>
			)
			return (
				<CustomTooltip
					key={'pending-' + timeOff?.id}
					staffName={null}
					showDelay={500}
					content={toolTipContent}
					manualClose={timeOff?.actionStatus === 'PENDING'}
				>
					<div className="scheduler-shift-card-body-not-available">
						<div className="d-flex">
							<div className="flex-grow-0 d-flex align-items-center justify-center">
								<span className="shift-role"><i className="ri-time-line"></i></span>
							</div>
							<div className="flex-grow-1 shift-name-main text-nowrap">
								{children}
							</div>
						</div>
					</div>
				</CustomTooltip>
			)
		}
	}

	// Return the mapped JSX for the filtered days
	return filteredDays.map((timeOff, index) => {
		if (timeOff.actionStatus === 'APPROVED') {
			if (timeOff.type === 'full-day') {
				return wrapTemplate(
					<div key={index}>
						<span style={{ marginRight: "3px", borderBottom: "none" }}>
							<strong><JTranslation typeCase="none" text="R/O:" /></strong>
						</span>
						<span style={{ color: "red", borderBottom: "none" }}>
							<JTranslation typeCase="none" text="Full Day" />
						</span>
					</div>,
					timeOff
				);
			} else {
				return wrapTemplate(
					<div key={index}>
						<span style={{ marginRight: "3px", borderBottom: "none" }}>
							<strong><JTranslation typeCase="none" text="R/O:" /></strong>
						</span>
						<span style={{ color: "red", borderBottom: "none" }}>
							<JTranslation
								typeCase="none"
								text={`${formatTimeToAmPm(timeOff.startTime)} to ${formatTimeToAmPm(timeOff.endTime)}`}
							/>
						</span>
					</div>,
					timeOff
				);
			}
		} else if (timeOff.actionStatus === 'PENDING') {
			return wrapTemplate(
				<div key={index}>
					<span style={{ marginRight: "3px", borderBottom: "none" }}>
						<strong><JTranslation typeCase="none" text="R/O:" /></strong>
					</span>
					<span style={{ color: "#fbaa07", borderBottom: "none" }}>
						<JTranslation typeCase="none" text="Pending" />
					</span>
				</div>,
				timeOff
			);
		}
	});
};

export const transformData = (data: PublishedScheduleExtended[]): TransformedData[] => {
	const result: TransformedData[] = [];
	const groupedData: { [key: string]: TransformedData } = {};
	if (data.length) {
		const { availabilities, unavailableDays, mandatoryDays, holidays } = data[0];
		data[0]?.shifts?.forEach(shift => {
			if (!groupedData[shift.tenantStaffId]) {
				groupedData[shift.tenantStaffId] = {
					scheduleId: shift.scheduleId,
					scheduleStartDate: shift.scheduleStartDate,
					scheduleEndDate: shift.scheduleEndDate,
					id: shift.id,
					tenantId: shift.tenantId,
					tenantStaffId: shift.tenantStaffId,
					assignedShifts: [],
					availabilities,
					unavailableDays,
					mandatoryDays,
					holidays
				};
			}

			groupedData[shift.tenantStaffId].assignedShifts.push({
				scheduleDate: shift.scheduleDate,
				isBd: shift.assignedShifts.isBd,
				role: shift.assignedShifts.role,
				roleId: shift.assignedShifts.roleId,
				endTime: shift.assignedShifts.endTime,
				isClose: shift.assignedShifts.isClose,
				shiftName: shift.assignedShifts.shiftName,
				startTime: shift.assignedShifts.startTime
			});
		});
	}

	for (const key in groupedData) {
		if (groupedData.hasOwnProperty(key)) {
			result.push(groupedData[key]);
		}
	}

	return result;
}

export const staffCountByDay: WeeklyStaffSchedule = {
	monday: { expected: 0, current: 0 },
	tuesday: { expected: 0, current: 0 },
	wednesday: { expected: 0, current: 0 },
	thursday: { expected: 0, current: 0 },
	friday: { expected: 0, current: 0 },
	saturday: { expected: 0, current: 0 },
	sunday: { expected: 0, current: 0 }
};

export const checkStaffSchedule = (schedule: WeeklyStaffSchedule): boolean => {
	for (const day in schedule) {
		if (schedule.hasOwnProperty(day)) {
			const { current, expected } = schedule[day];
			if (current !== expected) {
				return false;
			}
		}
	}
	return true;
};

export const locations: DropDown[] = [
	{ name: 'Kitchen', code: 'K' },
	{ name: 'Front of House', code: 'FOH' },
	{ name: 'Bar', code: 'B' },
	{ name: 'Dining Area', code: 'DA' },
	{ name: 'Management Office', code: 'MO' },
	{ name: 'Storage', code: 'S' },
	{ name: 'Reception', code: 'R' },
	{ name: 'Outdoor Patio', code: 'OP' },
	{ name: 'Private Dining Room', code: 'PDR' },
	{ name: 'Restrooms', code: 'RS' },
]

export type TimeOfDay = '12:00 AM' | '12:30 AM' | '1:00 AM' | '1:30 AM' | '2:00 AM' | '2:30 AM' | '3:00 AM' | '3:30 AM' | '4:00 AM' | '4:30 AM' | '5:00 AM' | '5:30 AM' | '6:00 AM' | '6:30 AM' | '7:00 AM' | '7:30 AM' | '8:00 AM' | '8:30 AM' | '9:00 AM' | '9:30 AM' | '10:00 AM' | '10:30 AM' | '11:00 AM' | '11:30 AM' | '12:00 PM' | '12:30 PM' | '1:00 PM' | '1:30 PM' | '2:00 PM' | '2:30 PM' | '3:00 PM' | '3:30 PM' | '4:00 PM' | '4:30 PM' | '5:00 PM' | '5:30 PM' | '6:00 PM' | '6:30 PM' | '7:00 PM' | '7:30 PM' | '8:00 PM' | '8:30 PM' | '9:00 PM' | '9:30 PM' | '10:00 PM' | '10:30 PM' | '11:00 PM' | '11:30 PM';

export const timeArray: TimeOfDay[] = ['12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'];

export const initialAddOrUpdateSchedule: AddOrUpdateSchedule = {
	scheduleId: '',
	tenantStaffId: '',
	assignShiftsByDate: {
		'': [
			{
				shiftName: '',
				startTime: '',
				endTime: '',
				role: '',
				roleId: '',
				departmentId: '',
				isBd: false,
				isClose: false
			},
		],
	},
}

export const initialScheduleData: EmployeeScheduleData[] = [
	{
		staffId: '',
		staffName: '',
		preferredName: '',
		staffData: {} as Staff,
		roles: [],
		staffRequirement: [],
		customEvents: [],
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
		sunday: [],
	},
]

export const initialDataMinimumEmployeeCount: MinimumEmployeeCountType = [
	{ dayName: 'Monday', dayId: '1', minCount: '0', jobRoles: [] },
	{ dayName: 'Tuesday', dayId: '2', minCount: '0', jobRoles: [] },
	{ dayName: 'Wednesday', dayId: '3', minCount: '0', jobRoles: [] },
	{ dayName: 'Thursday', dayId: '4', minCount: '0', jobRoles: [] },
	{ dayName: 'Friday', dayId: '5', minCount: '0', jobRoles: [] },
	{ dayName: 'Saturday', dayId: '6', minCount: '0', jobRoles: [] },
	{ dayName: 'Sunday', dayId: '0', minCount: '0', jobRoles: [] },
]

export const initialDataScheduleMetadataMinimumEmployeeCount: StaffRequirement[] = [
	{ dayName: 'Monday', dayId: '1', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Tuesday', dayId: '2', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Wednesday', dayId: '3', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Thursday', dayId: '4', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Friday', dayId: '5', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Saturday', dayId: '6', customCount: '0', jobRoles: [], date: '' },
	{ dayName: 'Sunday', dayId: '0', customCount: '0', jobRoles: [], date: '' },
]

export const initialDataShiftAssignment: ShiftAssignment = {
	availability: [
		{ day: 'monday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'tuesday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'wednesday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'thursday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'friday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'saturday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
		{ day: 'sunday', roles: [], availabilities: {}, holidays: [], mandatoryDays: [], unavailableDays: [] },
	],
}

export const initialScheduleStatus: Schedule = {
	id: '',
	startDate: '',
	endDate: '',
	actionStatus: '',
	publishedAt: '',
	scheduleMetadata: {
		staffRequirement: [],
		customEvents: []
	}
}

export function isDateInSelectedWeek({ startDate, endDate, selectedWeek }: { startDate: string, endDate: string, selectedWeek: dayjs.Dayjs }): boolean {
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	const weekStart = selectedWeek.startOf('week'); // Assumes week starts on Sunday
	const weekEnd = selectedWeek.endOf('week');     // Ends on Saturday

	const isStartWithinWeek = start.isBetween(weekStart, weekEnd, 'day', '[]');
	const isEndWithinWeek = end.isBetween(weekStart, weekEnd, 'day', '[]');

	return isStartWithinWeek && isEndWithinWeek;
}

export const isTimeWithinSchedule = ({ time, shift }: { time: TimeOfDay, shift: Shift }): boolean => {
	const timeFormat = 'hh:mm A';
	const givenTime = moment(time, timeFormat);
	const startTime = moment(shift.startTime, timeFormat);
	const endTime = moment(shift.endTime, timeFormat);

	return givenTime.isSameOrAfter(startTime) && givenTime.isSameOrBefore(endTime);
};

export function isDateInRange({ startDate, endDate, date }: { startDate: string, endDate: string, date: string }): boolean {
	// Parse the dates using Moment.js
	const targetDate = moment(date, 'YYYY-MM-DD');
	const start = moment(startDate, 'YYYY-MM-DD');
	const end = moment(endDate, 'YYYY-MM-DD');

	// Check if the date is within the range (inclusive)
	return targetDate.isBetween(start, end, undefined, '[]');
}

const getJobRolesNames = (jobRoles: JobRoleForShift[]) => {
	return jobRoles.map((role) => {
		const { jobRole } = role
		return jobRole?.jobRoleName
	})
}
export interface ShiftsByDate {
	[date: string]: ExtendedShift[];
}

// Function to get all assigned shifts for a specific weekday
const getAllAssignedShifts = ({ data, weekDay }: { data: Staff, weekDay: DayOfWeek }) => {
	const { shifts } = data
	const assignedShifts: Shift[] = [];
	for (const date in shifts) {

		if (shifts.hasOwnProperty(date)) {
			const parsedDate = dayjs(date);
			const dayOfWeek = parsedDate.format('dddd').toLowerCase(); // Get the day of the week in full format, e.g., 'Monday'

			if (dayOfWeek === weekDay) {
				const tempShifts = shifts[date];
				tempShifts.forEach(shift => {
					assignedShifts.push(shift);
				});
			}
		}
	}

	return assignedShifts;
};

export const formatApiStaffData = ({ scheduleData, filterBy }: { scheduleData: ScheduleData, filterBy?: 'SWAP' | 'SCHEDULE' }) => {
	const { staffs, schedule } = scheduleData
	const formattedSchedules: EmployeeScheduleData[] = staffs.map((staff): EmployeeScheduleData | null => {
		if (filterBy && filterBy === 'SWAP' && !Object.values(staff.shifts).length) return null

		const { id, preferredName, jobRoles } = staff
		const roles = getJobRolesNames(jobRoles)
		return {
			staffId: id,
			staffName: preferredName,
			staffData: staff,
			preferredName: preferredName,
			roles: roles.length ? roles : ['No role assigned'],
			staffRequirement: schedule.scheduleMetadata.staffRequirement,
			customEvents: schedule.scheduleMetadata.customEvents,
			monday: getAllAssignedShifts({ data: staff, weekDay: 'monday' }),
			tuesday: getAllAssignedShifts({ data: staff, weekDay: 'tuesday' }),
			wednesday: getAllAssignedShifts({ data: staff, weekDay: 'wednesday' }),
			thursday: getAllAssignedShifts({ data: staff, weekDay: 'thursday' }),
			friday: getAllAssignedShifts({ data: staff, weekDay: 'friday' }),
			saturday: getAllAssignedShifts({ data: staff, weekDay: 'saturday' }),
			sunday: getAllAssignedShifts({ data: staff, weekDay: 'sunday' }),
		}
	}).filter((staff) => staff !== null) as EmployeeScheduleData[]
	return formattedSchedules
}

export const weeklyEmployeeCountValidationSchema = Yup.array().of(
	Yup.object().shape({
		minCount: Yup.number()
			.required('Minimum staff count is required'),
		jobRoles: Yup.array().of(
			Yup.object().shape({
				minCount: Yup.number()
					.required('Each job role must have a minimum count. Please enter at least 0')
			})
		),
	})
);

export const perDayEmployeeCountValidationSchema = Yup.array().of(
	Yup.object().shape({
		customCount: Yup.number()
			.typeError('Minimum staff count must be a number')
			.required('Minimum staff count is required')
			.nonNullable(), // Ensures it cannot be null
		jobRoles: Yup.array().of(
			Yup.object().shape({
				minCount: Yup.number()
					.required('Each job role must have a minimum count. Please enter at least 0')
					.nonNullable() // Ensures it cannot be null
			})
		),
	})
);


export const getDateOfWeekday = ({ selectedWeek, day, format = 'MMM D, YYYY' }: getDate): string => {
	const normalizedWeekday = day.toLowerCase()
	let resultDate = selectedWeek.startOf('isoWeek')

	const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
	const targetDayIndex = daysOfWeek.indexOf(normalizedWeekday)

	while (resultDate.day() !== targetDayIndex) {
		resultDate = resultDate.add(1, 'day')
	}

	return resultDate.format(format)
}

export const selectionTemplate = (option: DropDown, props: any) => {
	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{<JTranslation text={option.name} />}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder} />}</span>
}

export const depSelectionTemplate = (option: any, props: any) => {
	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{<JTranslation text={option.depName} />}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder} />}</span>
}

export const shiftSelectionTemplate = (option: any, props: any) => {
	const formattedStartTime = option && formatTimeToAmPm(option.startTime);
	const formattedEndTime = option && formatTimeToAmPm(option.endTime);

	if (option) {
		return (
			<div className="flex align-items-center">
				<div>{formattedStartTime} - {formattedEndTime}</div>
			</div>
		)
	}

	return <span>{<JTranslation text={props.placeholder || ''} />}</span>
}

export const optionTemplateWithTime = ({ startTime, endTime, name }: { name: string; startTime: string; endTime: string }) => {
	const formattedStartTime = formatTimeToAmPm(startTime);
	const formattedEndTime = formatTimeToAmPm(endTime);

	return (
		<div className="flex align-items-center">
			<div className='d-flex flex-row align-items-center justify-content-between'>
				<div>{formattedStartTime} - {formattedEndTime}</div>
				<small style={{ fontSize: '0.75rem' }}>{name}</small>
			</div>
		</div>
	);
}

export const optionTemplate = ({ name }: { name: string; startTime: string; endTime: string }) => {
	return (
		<div className="flex align-items-center">
			<div>{<JTranslation text={name} />}</div>
		</div>
	);
}

export const depOptionTemplate = ({ depName }: { depName: string; startTime: string; endTime: string }) => {
	return (
		<div className="flex align-items-center">
			<div>{<JTranslation text={depName} />}</div>
		</div>
	);
}

type addProps = { arrayHelpers: FieldArrayRenderProps }
export const AddAssignmentCard = ({ arrayHelpers }: addProps) => {
	return (

		<div className='no-work-shift p-4'>
			<i className="ri-file-list-3-line"></i>
			<p className='mb-0 title-nowork'>{NO_WORK_SHIFT_ADDED}</p>
			<button
				type='button'
				className="btn btn-custom-primary-outline"
				onClick={() => addEmptyRow({ arrayHelpers })}
			>
				{ADD_NEW_SHIFT}
			</button>
		</div>

	)
}

export const AddCardButton = ({ arrayHelpers }: addProps) => {
	return (
		<div>
			<button
				type='button'
				className="btn btn-custom-primary-outline"
				onClick={() => addEmptyRow({ arrayHelpers })}
			>
				{ADD_NEW_SHIFT}
			</button>
		</div>
	)
}

export const AddShiftButton = ({ arrayHelpers }: addProps) => {
	return (
		<div className='mt-4'>
			<Button type="button" label="Add" icon="pi pi-plus" className='p-button-primary-outline' onClick={() => addShift({ arrayHelpers })} outlined />
		</div>
	)
}

export const addEmptyRow = ({ arrayHelpers }: addProps) => {
	arrayHelpers.push({
		name: "",
		code: "",
		departmentId: "",
		shifts: [
			{
				bd: false,
				close: false,
				code: "",
				endTime: "",
				name: "",
				startTime: "",
			},
		],
	});
}

export const addShift = ({ arrayHelpers }: addProps) => {
	arrayHelpers.push({
		bd: false,
		close: false,
		code: "",
		endTime: "",
		name: "",
		startTime: "",
	})
}

export const validationSchema = Yup.object().shape({
	availability: Yup.array().of(
		Yup.object().shape({
			day: Yup.string(),
			roles: Yup.array().of(
				Yup.object().shape({
					name: Yup.string().required("Please select a role"),
					code: Yup.string().required("Please select a role"),
					roleDepartmentId: Yup.string(),
					roleDepartmentName: Yup.string(),
					departmentId: Yup.string(),
					shifts: Yup.array().of(
						Yup.object()
							.shape({
								code: Yup.string().required("Please select a shift"),
								// name: Yup.string().required("Please select a shift"),
								close: Yup.bool(),
								bd: Yup.bool(),
								startTime: Yup.string().required('Required'),
								endTime: Yup.string().required('Required'),
							})
							.test("close-bd-exclusive", "", function (value) {
								const { close, bd } = value;
								if (close && bd) {
									return this.createError({
										path: `${this.path}.bd`,
										message: "You cannot select both close and business decline",
									});
								}
								return true;
							})
							.test('is-valid-end-time', '', function (value) {
								const { startTime, endTime } = value;
								const start = dayjs(startTime, "HH:mm:ss", true)
								const end = dayjs(endTime, "HH:mm:ss", true)

								if (end.isSameOrBefore(start)) {
									return this.createError({
										path: `${this.path}.endTime`,
										message: "End time must be greater than start time",
									});
								}
								return true
							})
					),
				})
					// Custom validation to check if either `roleDepartmentId` or `departmentId` is present
					.test(
						"role-department-id-or-department-id", "", function (value) {
							const { roleDepartmentId, departmentId } = value;
							if (!roleDepartmentId && !departmentId) {
								return this.createError({
									path: `${this.path}.departmentId`,
									message: "Please select an area",
								});
							}
							return true;
						}
					)
			),
		})
	),
});


export interface Weekday {
	day: string;
	date: string;
}

export const generateWeekdayDates = (date: Dayjs): Weekday[] => {
	const weekdays: Weekday[] = [];
	let currentDate: Dayjs = date.startOf('week'); // Start from Monday

	for (let i = 0; i < 7; i++) {
		weekdays.push({
			day: currentDate.format('dddd').toLowerCase(), // Get the lowercase weekday name
			date: currentDate.format(DATE_FORMAT), // Format the date as required
		});
		currentDate = currentDate.add(1, 'day'); // Move to the next day
	}

	return weekdays;
};

export const generateTwoWeekdayDates = (date: Dayjs): Weekday[] => {
	const weekdays: Weekday[] = [];
	let currentDate: Dayjs = date.startOf('week'); // Start from Monday

	for (let i = 0; i < 14; i++) {
		weekdays.push({
			day: currentDate.format('dddd').toLowerCase(), // Get the lowercase weekday name
			date: currentDate.format(DATE_FORMAT), // Format the date as required
		});
		currentDate = currentDate.add(1, 'day'); // Move to the next day
	}

	return weekdays;
};

export const getWeekRange = (selectedDate: dayjs.Dayjs) => {
	const startOfWeek = selectedDate.isoWeekday(1); // Start of the week (Monday)
	const endOfWeek = selectedDate.isoWeekday(7);   // End of the week (Sunday)

	return {
		startDate: startOfWeek.format('YYYY-MM-DD'),
		endDate: endOfWeek.format('YYYY-MM-DD')
	} as StartAndEndDates;
};

export const getScheduleId = (draftedScheduleId: string, publishedScheduleId: string): string => {
	if (!draftedScheduleId && publishedScheduleId) {
		// Case 1: Return publishedScheduleId if draft is empty
		return publishedScheduleId;
	} else if (draftedScheduleId && publishedScheduleId) {
		// Case 2: Return draftedScheduleId if both are present
		return draftedScheduleId;
	} else if (draftedScheduleId && !publishedScheduleId) {
		// Case 3: Return draftedScheduleId if draftedScheduleId is present and publishedScheduleId is empty
		return draftedScheduleId;
	} else {
		// Default case: Return an empty string or handle as needed
		return '';
	}
}

export const updateRolesListWithStaffCount = ({
	rolesListWithMinStaff,
	staffingRequirement,
}: {
	staffingRequirement: MinimumEmployeeCountType;
	rolesListWithMinStaff: JobRoleWithMinCount[];
}): MinimumEmployeeCountType => {
	// Clone staffingRequirement to avoid mutating original data
	let staffingRequirementWithMinCount = _.cloneDeep(staffingRequirement);

	// Initialize all roles with minCount = "0"
	const updatedRolesListWithMinCount = rolesListWithMinStaff.map((role) => ({
		...role,
		minCount: "0",
	}));

	// Update staffingRequirementWithMinCount with updated minCounts
	staffingRequirementWithMinCount = staffingRequirementWithMinCount.map((requirement) => {
		const { jobRoles } = requirement;

		if (jobRoles.length > 0) {
			// Update roles based on existing jobRoles
			const updatedJobRoles = jobRoles.map((jobRole) => {
				const matchingRole = updatedRolesListWithMinCount.find(
					(updatedRole) => updatedRole.id === jobRole.id
				);
				return matchingRole ? { ...matchingRole, minCount: jobRole.minCount } : jobRole;
			});

			return { ...requirement, jobRoles: updatedJobRoles };
		} else {
			// If no jobRoles, replace with updatedRolesListWithMinCount
			return { ...requirement, jobRoles: updatedRolesListWithMinCount };
		}
	});

	return staffingRequirementWithMinCount;
};

export const updateScheduleMetadataWithStaffCount = ({
	rolesList,
	staffRequirement,
	staffingRequirement
}: {
	rolesList: JobRole[];
	staffRequirement: StaffRequirement[];
	staffingRequirement: PerDayCountForListing[];
}): StaffRequirement[] => {
	let updatedStaffRequirement = _.cloneDeep(staffRequirement) ?? [];

	// Create a roles list with minCount set to "0"
	const updatedRolesListWithMinCount = rolesList.map((role) => ({
		...role,
		minCount: "0",
	}));

	// Iterate through each requirement in staffRequirement
	updatedStaffRequirement.forEach((requirement) => {
		// Ensure `requirement` is defined
		if (!requirement) return;

		// Find the corresponding staffingRequirement entry by dayId
		const staffingReqEntry = staffingRequirement.find(
			(entry) => entry?.dayId === requirement?.dayId
		);

		// Ensure `requirement.jobRoles` is always an array
		const staffJobRoles = requirement?.jobRoles ?? [];
		const staffingJobRoles = staffingReqEntry?.jobRoles ?? [];

		// Determine jobRoles source: Prefer staffRequirement jobRoles if available, otherwise use staffingRequirement jobRoles
		requirement.jobRoles =
			staffJobRoles.length > 0
				? staffJobRoles // Use existing jobRoles from staffRequirement
				: staffingJobRoles.length > 0
					? _.cloneDeep(staffingJobRoles) // Use staffingRequirement jobRoles if staffRequirement is empty
					: _.cloneDeep(updatedRolesListWithMinCount); // Default to roles list with minCount "0"
	});

	return updatedStaffRequirement;
};

// add global staffing requirements API 
export const addGlobalStaffingApi = (
	customMenuMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: AddGlobalStaffingCount,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	customMenuMutation.mutate(
		{
			url: staffingRequirementsApi,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// fetch global staffing requirements
export const getGlobalStaffing = () => {
	return HttpServiceHelper({
		url: staffingRequirementsApi,
		method: HttpMethods.GET,
	})
}

export const fetchDraftedAndPublishedScheduleIds = (param: StartAndEndDates) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/all`,
		method: HttpMethods.POST,
		data: param,
	})
}

export const createDraftSchedule = (param: StartAndEndDates) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/draft?flatDateWithUser=false`,
		method: HttpMethods.POST,
		data: param,
	})
}

export const fetchStaffsWithSchedulesAndShift = ({ scheduleId }: ScheduleId) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/staffs_with_shifts/${scheduleId}`,
		method: HttpMethods.POST,
		data: { 'flatDataWithUser': false, 'filterJobRoleAvailableForSchedule': true },
	})
}

export const fetchUserPublishedListForWeek = ({ tenantStaffId, dateRange, separateBySchedule = false }: { tenantStaffId: string, dateRange: StartAndEndDates, separateBySchedule?: boolean }) => {
	return HttpServiceHelper({
		url: `${scheduleApi}/shifts/${tenantStaffId}/published`,
		method: HttpMethods.POST,
		data: {
			...dateRange,
			separateBySchedule,
		},
	})
}

// Add / update schedule to user
export const addOrUpdateScheduleApi = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: AddOrUpdateSchedule,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/draft/shifts`,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// Add / update schedule to user
export const publishDraftedSchedule = (
	scheduleMutation: UseMutationResult<
		AxiosResponse<any, any>,
		unknown,
		Payload,
		void
	>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	const { scheduleId, scheduleData } = param;
	scheduleMutation.mutate(
		{
			url: scheduleData?.published.id
				? `${scheduleApi}/${scheduleId}/publish?isForcePublish=true`
				: `${scheduleApi}/${scheduleId}/publish`,
			method: HttpMethods.POST,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	);
};

// CLONE - Create a draft clone from published
export const cloneDraftFromPublished = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/clone`,
			method: HttpMethods.POST
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// update draft metadata
export const updateDraftMetadata = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: UpdateDraftMetaData,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/meta_data`,
			method: HttpMethods.PUT,
			data: param,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

// Delete an existing draft
export const deleteExistingDraft = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: PublishDraftedScheduleId,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/draft/${param.scheduleId}`,
			method: HttpMethods.DELETE,
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const copyEntireSchedule = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: CopySchedulePayloadType,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/new_week/clone`,
			method: HttpMethods.POST,
			// remove scheduleId from param
			data: omit(param, ['scheduleId']),
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const validateCopyEntireSchedule = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: CopySchedulePayloadType,
	onSuccess: (data: ConflictData) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/new_week/validate`,
			method: HttpMethods.POST,
			// remove scheduleId from param
			data: omit(param, ['scheduleId']),
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const validateCopyIndividualSchedule = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: CopyScheduleIndividualPayloadType,
	onSuccess: (data: ConflictData) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/new_week_users/validate`,
			method: HttpMethods.POST,
			// remove scheduleId from param
			data: omit(param, ['scheduleId']),
		},
		{
			onSuccess: (res) => onSuccess(res.data.data),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}
export const copyIndividualSchedule = (
	scheduleMutation: UseMutationResult<AxiosResponse<any, any>, unknown, Payload, void>,
	param: CopyScheduleIndividualPayloadType,
	onSuccess: (message: string, variant: string) => void,
	onError: (res: string, variant: string) => void
) => {
	scheduleMutation.mutate(
		{
			url: `${scheduleApi}/${param.scheduleId}/new_week_users/clone`,
			method: HttpMethods.POST,
			// remove scheduleId from param
			data: omit(param, ['scheduleId']),
		},
		{
			onSuccess: (res) => onSuccess(res.data.message, AlertVariant.SUCCESS),
			onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
		}
	)
}

export const getStaffAvailabilityForTheDay = ({
	day,
	availabilities,
	selectedWeek,
	type = 'others'
}: {
	day: string
	availabilities: Availability | undefined,
	selectedWeek: dayjs.Dayjs,
	type?: 'schedule' | 'others'
}) => {
	// Check if availabilities exist for the specified day
	if (!availabilities || Object.keys(availabilities).length === 0) {
		return null
	}

	const staffAvailabilities: any = availabilities[day]?.filter((availability) => {
		const { weekday, startDate, endDate } = availability;
		const isDateCorrect = isDateInSelectedWeek({ startDate, endDate, selectedWeek });

		// return isAvailable && day === weekday && isDateCorrect;
		return day === weekday && (isDateCorrect || availability.isRecurring === true) && (availability.actionStatus === 'APPROVED' || availability.actionStatus === 'PENDING');
	})

	let sortedAvailabilities: any[] = [];
	if (staffAvailabilities) {
		const hasNonRecurring = staffAvailabilities.some((availability: any) => !availability.isRecurring);

		// if there is temporary availability, use it
		const filteredAvailabilities = hasNonRecurring
			? staffAvailabilities.filter((availability: any) => !availability.isRecurring)
			: staffAvailabilities.filter((availability: any) => availability.isRecurring);

		// Now sort the filtered list by `createdAt` in descending order
		sortedAvailabilities = filteredAvailabilities.sort(
			(a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
	}
	const staffAvailability = sortedAvailabilities.slice(0, 1);

	// if no availability, return null (Not Set)
	if(staffAvailability?.length === 0) {
		return null;
	}
	
	if (!staffAvailability[0]?.isAvailable) {
		if (type === 'schedule') {
			return (
				// <CustomTooltip
				// 	staffName={preferredName}
				// 	showDelay={500}
				// 	content={timeOffText}
				// >
				<div className="scheduler-shift-card-body-not-available">
					<div className="d-flex">
						<div className="flex-grow-0 d-flex align-items-center justify-center">
							<span className="shift-role"><i className="ri-time-line"></i></span>
						</div>
						<div className="flex-grow-1 shift-name-main text-nowrap">
							<strong><JTranslation typeCase="none" text="N/A" /></strong>
						</div>
					</div>
				</div>
				// </CustomTooltip>
			)
		}
		if (type === 'others') {
			return <strong><JTranslation typeCase="none" text="N/A" /></strong>;
		}
	}
};

export const getStaffAvailabilityForTheDayExtended = ({
	day,
	availabilities,
	selectedWeek
}: {
	day: string
	availabilities: any[] | undefined,
	selectedWeek: dayjs.Dayjs
}) => {
	// Check if availabilities exist for the specified day
	if (!availabilities || availabilities.length === 0 || !(availabilities as any)[0]?.weekdays) {
		return null
	}

	const staffAvailability: any = (availabilities as any)[0]?.weekdays?.find((availability: any) => availability.weekday === day);

	if (!staffAvailability?.isAvailable) {
		return (
			<div className="scheduler-shift-card-body-not-available">
				<div className="d-flex">
					<div className="flex-grow-0 d-flex align-items-center justify-center">
						<span className="shift-role"><i className="ri-time-line"></i></span>
					</div>
					<div className="flex-grow-1 shift-name-main text-nowrap">
						<strong><JTranslation typeCase="none" text="N/A" /></strong>
					</div>
				</div>
			</div>
		)
	}

	if (staffAvailability?.availableTimes) {
		// TODO: may need to show individual availability
		// return staffAvailability.availableTimes.map((time: any) => {
		// 	return <div key={time.startTime}>{time.startTime} - {time.endTime}</div>
		// })
	}

	return null
};

export const formatTime = (time: string): string => {
	// Ensure the time format is consistent with AM/PM capitalization and no extra spaces
	return time.toUpperCase().replace(/\s*(AM|PM)/, ' $1');
}

export const formatTimeRanges = (input: AvailableTime[]): string => {
	return input.map(range => `(${formatTime(range.from)} to ${formatTime(range.to)})`).join(', ');
}

export const getStaffAvailabilityForTheDayForAccordian = ({
	day,
	availabilities,
	selectedWeek
}: {
	day: string,
	availabilities: Availability | undefined,
	selectedWeek: dayjs.Dayjs
}) => {
	if (!availabilities || Object.keys(availabilities).length === 0) {
		return <strong><JTranslation
			typeCase="pascal"
			text="Not set" /></strong>;
	}

	const staffAvailabilities: any = availabilities[day]?.filter((availability) => {
		const { isAvailable, weekday, startDate, endDate } = availability;
		const isDateCorrect = isDateInSelectedWeek({ startDate, endDate, selectedWeek });

		// return isAvailable && day === weekday && isDateCorrect;
		return day === weekday && (isDateCorrect || availability.isRecurring === true) && (availability.actionStatus === 'APPROVED' || availability.actionStatus === 'PENDING');
	})

	let sortedAvailabilities: any[] = [];
	if (staffAvailabilities) {
		const hasNonRecurring = staffAvailabilities.some((availability: any) => !availability.isRecurring);

		// if there is temporary availability, use it
		const filteredAvailabilities = hasNonRecurring
			? staffAvailabilities.filter((availability: any) => !availability.isRecurring)
			: staffAvailabilities.filter((availability: any) => availability.isRecurring);

		// Now sort the filtered list by `createdAt` in descending order
		sortedAvailabilities = filteredAvailabilities.sort(
			(a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
	}
	const staffAvailability = sortedAvailabilities.slice(0, 1);

	if (Object.keys(staffAvailability).length === 0) {
		return <strong><JTranslation
			typeCase="pascal"
			text="Not set" /></strong>;
	}
	if (staffAvailability[0]?.actionStatus === 'PENDING') {
		return <strong>Pending</strong>;
	}
	if (staffAvailability[0]?.availableTimes?.length) {
		const time = formatTimeRanges(staffAvailability[0]?.availableTimes);
		return <strong>{time}</strong>;
	}
	if (staffAvailability[0]?.isAllDay) {
		return <strong>All Day</strong>;
	}
	if (!staffAvailability[0]?.isAvailable) {
		return <strong>Not Available</strong>;
	}
};

export const isRoleWithShiftPresent = (targetRole: any, daysArray: any) => {
	let matchedDays = [];
	for (const day of daysArray) {
		if (!day.roles) continue; // Ensure roles exist
		for (const role of day.roles) {
			if (role.code === targetRole.code) {
				if (!role.shifts || role.shifts.length !== targetRole.shifts.length) {
					continue;
				}
				let allShiftsMatch = targetRole.shifts.every((targetShift: any) =>
					role.shifts.some((shift: any) =>
						shift.code === targetShift.code &&
						shift.startTime === targetShift.startTime &&
						shift.endTime === targetShift.endTime
					)
				);
				if (allShiftsMatch) {
					matchedDays.push(day);
					break; // Move to the next day after finding a match
				}
			}
		}
	}
	return matchedDays;
} 