export const CURRENT_USER = 'current-user';
export const LOGIN_STATUS = 'login-status';
export const SIDEBAR_TOGGLE = 'sidebar-toggle';
export const USER_ROLES = "user-roles";
export const SHOW_LOADER = "loader";
export const TENANT_INFO = "tenant-info";
export const USERS_LIST = "users-list";
export const USERS_TIME_OFF_LIST = "user-time-off-request";
export const HOLIDAYS_LIST = "holiday-list";
export const MENU_LIST = "menu-list";
export const MENU_ITEMS = "menu-items";
export const MENU_ITEM_DETAIL = "menu-item-detail";
export const PUBLIC_MENU = "public-menu";
export const SELECT_MENU_ITEM = "select-menu-item";
export const USER_INFO = "user-info";
export const PERMISSIONS = "permissions";
export const QUESTION_SET = "question-set";
export const DATA_CHANGE = "data-change";
export const GUEST_SURVEY = "guest-survey";
export const GUEST_LIST = "guests-list";
export const SERVERS_LIST = "servers-list";
export const SURVEY_REPORTS = "survey-reports";
export const OFFERS_LIST = "offers-list";
export const GUEST_PRIZES = "guest-prizes";
export const GUEST_WINNING_PRIZE = "guest-winning-prize";
export const COUPONS_LIST = "coupons-list";
export const GUEST_ROUTE_PARAMS = "guest-route-params";
export const USER_TRACKING = "user-tracking";
export const GUEST_VISITED_GRAPH = "guest-visited-graph";
export const GUEST_SURVEY_RATING_GRAPH = "guest-survey-rating-graph";
export const GUEST_VISITED_GRID = "guest-visited-grid";
export const UPLOADED_FILE_LIST = "uploaded-file-list";
export const EXTRACTED_FILE_LIST = "extracted-file-list";
export const FNB_GLOBAL_SETTINGS = "f&b-global-settings";
export const WELCOME_CONFIG = "welcome-config";
export const PUBLIC_WELCOME_CONFIG = "public-welcome-config";
export const ADD_ONS_LIST = "add-ons-list";
export const USER_GLOBAL_CONFIG = "user-global-config";
export const INGREDIENTS_LIST = "ingredients-list";
export const INGREDIENTS_ITEM_DETAILS = "ingredients-item-details";
export const CUSTOM_MENU_LIST = "custom-menu-list";
export const CUSTOM_MENU_LIST_GUEST_VIEW = "custom-menu-list-guest-view";
export const NEWS_AND_EVENTS_LIST = "news-and-events-list";
export const EVENTS_LIST_GUEST_VIEW = "events-list-guest-view";
export const NEWS_LIST_GUEST_VIEW = "news-list-guest-view";
export const ADD_ONS_GROUP_LIST = "add-ons-group-list";
export const SHIFT_CONFIG_LIST = "shift-config-list";
export const AVAILABILITY_LIST = "availability-list";
export const AVAILABILITY_REQUESTS_LIST = "availability-requests";
export const MANDATORY_DAYS_LIST = "mandatory-days-list";
export const OFF_DAYS = "off-days";
export const BRANDING_INFO = "branding-info";
export const ABOUT_US_INFO = "about-us-info";
export const ABOUT_US_CONFIG = "about-us-config";
export const UNREAD_NOTIFICATIONS_LIST = "unread-notifications-list";
export const PERMISSION_LIST = "permission-list";
export const PERMISSION_ROLE_LIST = "permission-role-list";
export const NOTIFICATIONS_LIST = "notifications-list";
export const HEADER_RENDERED = "header-rendered";
export const ROLES_LIST = "roles-list";
export const STAFFING_REQUIREMENTS_LIST = "staffing-requirements-list";
export const SCHEDULE_STATUS = "schedule-status";
export const CREATE_DRAFT_SCHEDULE = "create-draft-schedule";
export const STAFF_AVAILABILITY = "staff-availability";
export const STAFFS_WITH_SCHEDULES_AND_SHIFT = "staffs-with-schedules-and-shift";
export const USER_PUBLISHED_LIST_FOR_WEEK = "user-published-list-for-week";
export const MOBILE_USER_PUBLISHED_LIST_FOR_WEEK = "mobile-user-published-list-for-week";
export const AREA_LIST = "area-list";
export const SWAP_REQUEST_LIST = "swap-request-list";
export const HELP_LIST = "help-list";
export const STAFFS_WITH_SCHEDULES_AND_SHIFT_PRINT = "staffs-with-schedules-and-shift-print";
export const STAFFS_WITH_SCHEDULES_AND_SHIFT_SCHEDULE = "staffs-with-schedules-and-shift-schedule";
export const STAFFS_WITH_SCHEDULES_AND_SHIFT_SWAP = "staffs-with-schedules-and-shift-swap";
