import { useEffect, useRef, useState } from 'react'
import { AREA_OF_OPERATION, WORK_TIME_SCHEDULE } from '../../constants/strings'
import { AxiosResponse } from 'axios'
import { Button } from 'primereact/button'
import { cloneDeep } from 'lodash'
import { MOBILE_VIEW_BREAKPOINT, toastMessageInitialData } from '../../constants/constants'
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown'
import { getRolesList } from '../../helpers/roleHelper'
import { getStaffId } from '../../helpers/utils'
import { isEmptyObject } from 'jquery'
import { JobRole, ScheduleDepartmentDropDown, ToastMessageProps } from '../../constants/staticTypes'
import { JTranslation } from '../../helpers/jTranslate'
import { MOBILE_USER_PUBLISHED_LIST_FOR_WEEK, ROLES_LIST, SCHEDULE_STATUS, STAFFS_WITH_SCHEDULES_AND_SHIFT_SCHEDULE, USER_PUBLISHED_LIST_FOR_WEEK } from '../../constants/queryKeys'
import { PublishedSchedule, ScheduleData, ScheduleIdsResponse, ScheduleMetadata, depOptionTemplate, depSelectionTemplate, fetchDraftedAndPublishedScheduleIds, fetchStaffsWithSchedulesAndShift, fetchUserPublishedListForWeek, getWeekRange } from '../../helpers/workTimeScheduleHelper'
import { TabMenu } from 'primereact/tabmenu'
import { TabView, TabPanel } from 'primereact/tabview'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'
import StaffSchedule from '../../components/staff_schedule/StaffSchedule'
import StaffScheduleMobileView from '../../components/staff_schedule/StaffScheduleMobileView'
import SwapScheduleView from '../../components/shift_swap/SwapScheduleView'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import withSidebar from '../../hoc/withSidebar'
import WeekFilter from '../../components/week_filter/WeekFilter'
import FilterWrapper from '../../components/filter_wrapper/FilterWrapper'
import { Dialog } from 'primereact/dialog'
import PrintPreview from '../manage_work_schedule/PrintPreview'

const StaffScheduleView = () => {
    const { width } = useWindowSize()
    const breakPoint = MOBILE_VIEW_BREAKPOINT
    const headerBreakPoint = 275
    const headerBreakPointForWindow = 590
    const staffId = getStaffId()
    const tabItems = [
        { label: 'My Schedule' },
        { label: 'Full Schedule' },
    ]
    const scheduleDialogRef = useRef(null);

    const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
    const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
    const [scheduleData, setScheduleData] = useState<PublishedSchedule[]>([]);
    const [scheduleFilteredData, setScheduleFilteredData] = useState<PublishedSchedule[]>([]);
    const [mobileScheduleData, setMobileScheduleData] = useState<PublishedSchedule[]>([]);
    const [mobileScheduleFilteredData, setMobileScheduleFilteredData] = useState<PublishedSchedule[]>([]);
    const [activeIndex, setActiveIndex] = useState(0)
    const dateRange = getWeekRange(selectedWeek); // Calculate the date range for the selected week
    const [staffsWithSchedule, setStaffsWithSchedule] = useState<ScheduleData | null>(null)
    const [staffsWithScheduleFiltered, setStaffsWithScheduleFiltered] = useState<ScheduleData | null>(null)
    const [publishedScheduleId, setPublishedScheduleId] = useState('')
    const [showPreview, setShowPreview] = useState(false)
    const [departments, setDepartments] = useState<ScheduleDepartmentDropDown[]>([])
    const [selectedDepartment, setSelectedDepartment] = useState<ScheduleDepartmentDropDown | null>(null)
    const [showPrintPreview, setShowPrintPreview] = useState(false)
    const [scheduleMetadata, setScheduleMetadata] = useState<ScheduleMetadata | null>(null)

    const onSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data
        setScheduleData(publishedSchedule)
        setScheduleFilteredData(publishedSchedule)
    }

    const onMobileSuccess = (res: AxiosResponse<any, any>) => {
        const publishedSchedule: PublishedSchedule[] = res.data.data
        setMobileScheduleData(publishedSchedule)
        setMobileScheduleFilteredData(publishedSchedule)
    }

    const scheduleQuery = useQueryHook(USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange, separateBySchedule: true }), onSuccess)

    const mobileScheduleQuery = useQueryHook(MOBILE_USER_PUBLISHED_LIST_FOR_WEEK, () => fetchUserPublishedListForWeek({ tenantStaffId: staffId, dateRange: dateRange }), onMobileSuccess)

    const onSuccessOfScheduleStatus = (res: AxiosResponse<any, any>) => {
        const scheduleData: ScheduleIdsResponse = res.data.data
        const { published } = scheduleData

        // Check if draft and published schedules are empty
        const isPublishedEmpty = isEmptyObject(published)

        // If published is not empty, save the published's id using savePublishedScheduleId
        if (!isPublishedEmpty) {
            setPublishedScheduleId(published.id)
        } else {
            setPublishedScheduleId("")
            setStaffsWithSchedule(null)
            setStaffsWithScheduleFiltered(null)
        }
    }

    const onSuccessOfStaffsWithSchedule = (res: AxiosResponse<any, any>) => {
        const sortStaffByPreferredName = (staffsWithSchedule: ScheduleData) => {
            // Separate staffs into two groups: with shifts and without shifts
            const staffsWithShifts = staffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length > 0);
            const staffsWithoutShifts = staffsWithSchedule.staffs.filter(staff => Object.keys(staff.shifts).length === 0);

            // Sort each group by preferredName
            staffsWithShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));
            staffsWithoutShifts.sort((a, b) => a.preferredName.localeCompare(b.preferredName));

            // Combine the sorted arrays, with staff with shifts first
            const sortedStaffs = [...staffsWithShifts, ...staffsWithoutShifts];

            // Update the staffs in the original object
            return { ...staffsWithSchedule, staffs: sortedStaffs };
        };

        const staffsWithSchedule: ScheduleData = res.data.data
        const sortedStaffsWithSchedule = sortStaffByPreferredName(staffsWithSchedule);

        setStaffsWithSchedule(sortedStaffsWithSchedule)
        setStaffsWithScheduleFiltered(sortedStaffsWithSchedule)
        setScheduleMetadata(staffsWithSchedule?.schedule?.scheduleMetadata ?? {} as ScheduleMetadata)
    }

    const onRoleListSuccess = (res: AxiosResponse<any, any>) => {
        let data: JobRole[] | [] = res.data?.data.lists
        let departments: ScheduleDepartmentDropDown[] = []

        data.forEach((item) => {
            const { jobRoleDepartment, isAvailableForSchedule } = item

            if (!isAvailableForSchedule) return;

            if (jobRoleDepartment.departmentName) {
                departments.push({
                    departmentId: jobRoleDepartment.id,
                    depName: jobRoleDepartment.departmentName ?? "",
                })
            }
        })

        // Use Set to remove duplicates based on the department ID
        const uniqueDepartments = Array.from(
            new Set(departments.map(dep => dep.departmentId))
        ).map(code => departments.find(dep => dep.departmentId === code)!);

        setDepartments(uniqueDepartments);
    }

    // fetch role list (api call)
    useQueryHook(ROLES_LIST, () => getRolesList({ limit: 500 }), onRoleListSuccess)

    // schedule data main QUERY-1
    const scheduleDataQuery = useQueryHook(
        SCHEDULE_STATUS,
        () => fetchDraftedAndPublishedScheduleIds(dateRange),
        onSuccessOfScheduleStatus,
        (error) => console.log(`Error fetching draft and published schedule ids.`, error),
        true,
        false,
    )

    // schedule data main QUERY-2
    const staffsWithScheduleQuery = useQueryHook(
        STAFFS_WITH_SCHEDULES_AND_SHIFT_SCHEDULE,
        () => fetchStaffsWithSchedulesAndShift({ scheduleId: publishedScheduleId }),
        onSuccessOfStaffsWithSchedule,
        undefined,
        !!publishedScheduleId,
        false
    )

    // refetch the published schedule api once the selected date has changed 
    useEffect(() => {
        scheduleQuery.refetch()
        mobileScheduleQuery.refetch()
        scheduleDataQuery.refetch()

        // clear other filters
        setSelectedDepartment(null)
    }, [selectedWeek]) // eslint-disable-line

    useEffect(() => {
        if (publishedScheduleId) {
            staffsWithScheduleQuery.refetch()
        }
    }, [publishedScheduleId])

    // Area of operation filter
    useEffect(() => {
        if (selectedDepartment) {
            setScheduleFilteredData(cloneDeep(scheduleData).map((schedule) => {
                const filteredShifts = (schedule as any)?.shifts?.filter((shift: any) => shift?.assignedShifts?.departmentId === selectedDepartment?.departmentId)
                return {
                    ...schedule,
                    shifts: filteredShifts
                }
            }))

            setMobileScheduleFilteredData(
                cloneDeep(mobileScheduleData).filter(
                    (shift: any) =>
                        shift?.assignedShifts?.departmentId === selectedDepartment?.departmentId
                )
            );

            const scheduledStaffs = cloneDeep(staffsWithSchedule)
            const filteredStaffs = scheduledStaffs?.staffs?.map(staff => {
                const filteredShifts: any = {};
                for (const shiftKey in staff.shifts) {
                    filteredShifts[shiftKey] = staff.shifts[shiftKey].filter(
                        (shift) => shift.assignedShifts?.departmentId === selectedDepartment?.departmentId
                    );
                }
                return { ...staff, shifts: filteredShifts };
            })
            setStaffsWithScheduleFiltered({ ...scheduledStaffs, staffs: filteredStaffs } as ScheduleData)
        } else {
            setScheduleFilteredData(scheduleData)
            setMobileScheduleFilteredData(mobileScheduleData)
            setStaffsWithScheduleFiltered(staffsWithSchedule)
        }
    }, [selectedDepartment]);

    return (
        <>
            {/* TOAST MESSAGE COMPONENT */}
            <ToastAlert
                show={toastMessage.show}
                onClose={() => setToastMessage(toastMessageInitialData)}
                message={toastMessage.message}
                variant={toastMessage.variant}
            />

            <Dialog
                ref={scheduleDialogRef}
                visible={showPrintPreview}
                maximized={true}
                showHeader={false}
                onHide={() => {
                    if (!showPrintPreview) return;
                    setShowPrintPreview(false);
                }}
                pt={{
                    content: {
                        style: { padding: '0px' }
                    }
                }}
            >
                <div className='h-100 w-100'>
                    <PrintPreview 
                        reloadPageDataSchedule={() => {}} 
                        setShowPrintPreview={setShowPrintPreview} 
                        scheduleDialogRef={scheduleDialogRef} 
                        filterProps={{
                            areaOfOperation: selectedDepartment?.departmentId,
                            staffId: activeIndex === 0 ? staffId : null,
                            selectedWeek: selectedWeek
                        }}
                    />
                </div>
            </Dialog>

            <div className="page-title d-flex align-items-center py-2">
                <div className="flex-grow-1 d-flex align-items-center">
                    <h5 className='m-0'>
                        <JTranslation typeCase="pascal" text={WORK_TIME_SCHEDULE} />
                    </h5>
                </div>
                <div className="flex-grow-0 align-items-center">
                    {/* Fullscreen preview */}
                    {width >= breakPoint && (
                        <Button
                            icon="ri-fullscreen-line"
                            className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
                            title="Fullscreen"
                            onClick={() => {
                                setShowPreview(true)
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={`col-md-12 col-lg-12 mb-3 h-100 ${showPreview ? 'mlx-fullscreen' : ''}`}>
                <div className="card h-100 ">
                    <div className="card-header flex-grow-0 p-0">
                        <div className="d-flex flex-wrap align-items-end justify-content-between">
                            <div className={`flex-grow-1 ${width <= headerBreakPointForWindow ? 'border-bottom': ''}`} style={{ minWidth: `${headerBreakPoint}p` }}>
                                <TabMenu 
                                    model={tabItems} 
                                    activeIndex={activeIndex} 
                                    onTabChange={(e) => setActiveIndex(e.index)} 
                                    pt={{ menu: { style: { borderColor: 'transparent' } } }}
                                />
                            </div>
                            <div className="flex-grow-0 ms-auto d-flex align-items-center p-2 me-2">
                                <FilterWrapper 
                                    isFiltered={!!selectedDepartment}
                                    clearFilter={() => {
                                        setSelectedDepartment(null)
                                    }}
                                >
                                    <Dropdown
                                        value={selectedDepartment ?? null}
                                        onChange={(e: DropdownChangeEvent) => setSelectedDepartment(e.value ?? null)}
                                        options={departments}
                                        optionLabel="name"
                                        placeholder={AREA_OF_OPERATION}
                                        filter
                                        filterBy="depName"
                                        showClear
                                        disabled={!departments.length}
                                        valueTemplate={depSelectionTemplate}
                                        itemTemplate={depOptionTemplate}
                                        className="w-full md:w-14rem"
                                        panelClassName='full-screen-dropdown-panel'
                                        pt={{ input: { style: { minWidth: '100px',paddingTop: '0.365rem', paddingBottom: '0.365rem' } } }}
                                    />
                                </FilterWrapper>
                                <span className="ms-3">
                                    <WeekFilter
                                        setPrevSelectedWeek={setSelectedWeek}
                                        setNextSelectedWeek={setSelectedWeek}
                                        selectedWeek={selectedWeek}
                                        onChange={(date, dateString) => {
                                            const selectedDate = date ? dayjs(date) : dayjs()
                                            setSelectedWeek(selectedDate)
                                        }}
                                    />
                                </span>
                                
                                {/* <Button
                                    tooltip={'Print Preview'}
                                    className='ms-2 rounded'
                                    tooltipOptions={{ position: 'bottom' }}
                                    disabled={mobileScheduleQuery.isFetching || staffsWithScheduleQuery.isFetching || scheduleDataQuery.isFetching}
                                    onClick={() => setShowPrintPreview(!showPrintPreview)}
                                    icon="pi pi-print"
                                    outlined
                                    pt={{
                                        root: { style: { minWidth: '40px', padding: '0.375rem', marginRight: '0.5rem' } },
                                        icon: { style: { fontSize: '1.5rem' } }
                                    }}
                                >
                                </Button> */}
                                <div className='cldr-action-icon ms-2'>
                                        <i 
                                            className={`pi pi-print ${
                                               mobileScheduleQuery.isFetching || staffsWithScheduleQuery.isFetching || scheduleDataQuery.isFetching ? 'disabled' : ''
                                            }`}
                                            role='button'
                                            title={'Print Preview'}
                                            onClick={() => setShowPrintPreview(!showPrintPreview)}
                                        ></i>
                                    </div>

                                {width >= breakPoint && (
                                    <Button
                                        hidden={!showPreview}
                                        rounded
                                        outlined
                                        icon="ri-fullscreen-exit-line"
                                        className="ms-3 p-button-primary color-primary-custom"
                                        title="Close Preview"
                                        onClick={() => {
                                            setShowPreview(false)
                                        }}
                                        pt={{
                                            root: { style: { height: '2.5rem', width: '2.5rem' } },
                                            icon: { style: { fontSize: '1.5rem' } }
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-body flex-grow-1 p-0 overflow-auto">
                        <div className="survey-section h-100">
                            <TabView
                                className={`staff-schedule-panel w-100 h-100 schedule-scroll settings-tab ${width >= breakPoint ? 'hide-tab-arrow' : ''}`}
                                activeIndex={activeIndex}
                                onTabChange={(e) => setActiveIndex(e.index)}
                                scrollable={true}
                                pt={{ navContainer: { style: { display: 'none' } } }}
                            >
                                <TabPanel
                                    className='test-name h-100'
                                    header={<JTranslation typeCase="pascal" text={'My Schedule'} />}
                                >
                                    {/* switch web and mobile components */}
                                    {width >= breakPoint
                                        ? <StaffSchedule 
                                            selectedWeek={selectedWeek} 
                                            scheduleData={scheduleFilteredData} 
                                            isFetching={scheduleQuery.isFetching} 
                                            scheduleMetadata={scheduleMetadata}
                                        />
                                        : <StaffScheduleMobileView
                                            selectedWeek={selectedWeek}
                                            scheduleData={mobileScheduleFilteredData}
                                            holidays={staffsWithSchedule?.holidays ?? []}
                                            mandatoryDays={staffsWithSchedule?.mandatoryDays ?? []}
                                            isFetching={mobileScheduleQuery.isFetching || staffsWithScheduleQuery.isFetching}
                                        />
                                    }
                                </TabPanel>
                                <TabPanel
                                className='h-100'
                                    header={<JTranslation typeCase="pascal" text={'Full Schedule'} />}
                                >
                                    <SwapScheduleView
                                        staffsWithSchedule={staffsWithScheduleFiltered}
                                        staffsWithScheduleQuery={staffsWithScheduleQuery}
                                        scheduleDataQuery={scheduleDataQuery}
                                        selectedWeek={selectedWeek}
                                        parent='myScheduleView'
                                        scheduleMetadata={scheduleMetadata}
                                    />
                                </TabPanel>
                            </TabView>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withSidebar(StaffScheduleView)
