import { defaultPagination, defaultPaginationEvent } from '../../helpers/utils'
import {
	DELETE_WARNING,
	AVAILABILITY_REQUESTS,
	AVAILABILITY_REQUESTS_HELP
} from '../../constants/strings'
import withSidebar from '../../hoc/withSidebar'
import WarningModal from '../../components/warning_modal/WarningModal'
import ToastAlert from '../../components/alert/ToastAlert'
import {
	AlertVariant,
	DATE_FORMAT,
	MOBILE_VIEW_BREAKPOINT,
	PageMode, toastMessageInitialData
} from '../../constants/constants'
import { useContext, useEffect, useRef, useState } from 'react'
import {
	AddAvailabilityResponse,
	AvailabilityData, DatatablePagEvent, PaginationApi, SingleAvailabilityFormData,
	ToastMessageProps
} from '../../constants/staticTypes'
import { CommonCtx } from '../../context/CommonCtxProvider'
import AvailabilitySideBar from '../../components/availability/AvailabilitySideBar'
import { FaSearch } from 'react-icons/fa'
import { useQueryClient } from 'react-query'
import { DebounceInput } from 'react-debounce-input'
import { AxiosResponse } from 'axios'
import {
	deleteStaffAvailability,
	getAllAvailabilityOfUsers
} from '../../helpers/availabilityHelper'
import useQueryHook from '../../hooks/useQueryHook'
import { AVAILABILITY_REQUESTS_LIST } from '../../constants/queryKeys'
import useMutationHook from '../../hooks/useMutationHook'
import AllAvailabilityDataGrid from '../../components/availability/AllAvailabilityDataGrid'
import { useWindowSize } from 'react-use'
import MlxPopover from '../../components/common/MlxPopover'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { MultiSelect } from 'primereact/multiselect'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import FilterWrapper from '../../components/filter_wrapper/FilterWrapper'

const statusList = [
	{ label: 'Pending', value: 'PENDING' },
	{ label: 'Approved', value: 'APPROVED' },
	{ label: 'Rejected', value: 'REJECTED' }
]

function AvailabilityList() {
	const queryClient = useQueryClient()
	const availabilityMutation = useMutationHook(queryClient, true)
	const filterPanelRef = useRef(null)

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityType, setAvailabilityType] = useState<'REGULAR' | 'TEMPORARY'>('REGULAR')
	const [allAvailabilityList, setAllAvailabilityList] = useState<AvailabilityData[]>([])
	const [deleteAvailability, setDeleteAvailability] = useState<SingleAvailabilityFormData>({}) // delete availability
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message
	const [dataTableEvent, setDataTableEvent] = useState<DatatablePagEvent>(defaultPaginationEvent);
	const [paginationData, setPaginationData] = useState<PaginationApi>(defaultPagination);
	const [search, setSearch] = useState("")
	const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs | null>(null)
	const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
	const [urlParams, setUrlParams] = useState<string>(`?page=${(dataTableEvent.page ?? 0) + 1}&limit=${dataTableEvent.rows}`)

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)

	const [showTitle, setShowTitle] = useState(true)
	const { width } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT

	// availability list fetch success
	const onAvailabilityListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const availabilityListData = (res.data.data.lists as AvailabilityData[]) || ([] as AvailabilityData[])
		setAllAvailabilityList(availabilityListData)

		const paginationData = res?.data?.data?.pagination as PaginationApi;
		setPaginationData(paginationData);
	}

	// fetch availability list
	const availabilityListData = useQueryHook(
		AVAILABILITY_REQUESTS_LIST,
		() => getAllAvailabilityOfUsers(urlParams),
		onAvailabilityListFetchSuccess,
	)

	const onAvailabilityDeleteClick = (availability: AvailabilityData) => {
		setDeleteAvailability({ id: availability.id })
		setWarningMessage(DELETE_WARNING)
		setWarning(true)
	}

	const deleteAvailabilityApiCall = () => {
		deleteStaffAvailability(availabilityMutation, deleteAvailability, onSuccess, onError)
	}

	const onSuccess = (res: AddAvailabilityResponse) => {
		queryClient.refetchQueries([AVAILABILITY_REQUESTS_LIST])
		setShowSideBar(false)
		setToastMessage({ message: res.message, show: true, variant: AlertVariant.SUCCESS })
	}

	const onError = (message: string) => {
		setToastMessage({ message: message, show: true, variant: AlertVariant.ERROR })
	}

	const filterTemplate = (
		<FilterWrapper 
			isFiltered={!!(selectedStatus || selectedWeek)} 
			clearFilter={() => {
				setSelectedStatus(null)
				setSelectedWeek(null)
			}}
		>
			<DatePicker
				placeholder='Select Week'
				className="mb-2 availability-date-picker w-100"
				onChange={(date, dateString) => {
					if (!date) {
						setSelectedWeek(null)
						return
					}

					const selectedDate = date ? dayjs(date) : dayjs()
					setSelectedWeek(selectedDate)
				}}
				picker="week"
				value={selectedWeek}
				format={(value) =>
					`${dayjs(value).startOf('week').format(DATE_FORMAT)} - ${dayjs(value)
						.endOf('week')
						.format(DATE_FORMAT)}`
				}
				showWeek={false}
				allowClear={true}
				style={{ borderRadius: '3px' }}
			/>
			<Dropdown 
				className='w-100'
				value={selectedStatus}
				options={statusList}
				itemTemplate={(option) => <span key={option.value}>{option.label}</span>}
				onChange={(e) => setSelectedStatus(e.target.value)}
				showClear={true}
				placeholder={'Status'}
				optionLabel='label'
				optionValue='value'
				style={{ borderRadius: '3px' }}
				pt={{ input: { style: { padding: '0.444rem 0.75rem' } } }}
			/>
		</FilterWrapper>
	)

	// get availability list with params
	useEffect(() => {
		let urlParams = `?page=${(dataTableEvent.page ?? 0) + 1}&limit=${dataTableEvent.rows}`;
		if (search) {
			urlParams += `&search=${search}`
		}
		if (selectedStatus) {
			urlParams += `&filters[actionStatus]=${selectedStatus}`
		}
		if (selectedWeek?.isValid()) {
			urlParams += `&filters[startDate_lte]=${selectedWeek.startOf('week').format('YYYY-MM-DD')}`;
			urlParams += `&filters[endDate_gte]=${selectedWeek.endOf('week').format('YYYY-MM-DD')}`;
		}
		setUrlParams(urlParams);
	}, [search, selectedWeek, selectedStatus, dataTableEvent?.forceUpdate])

	useEffect(() => {
		availabilityListData.refetch();
	}, [urlParams]);

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
					setDeleteAvailability({})
				}}
				callback={() => {
					setWarning(false)
					deleteAvailabilityApiCall()
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			{/* availability sidebar */}
			<AvailabilitySideBar
				availabilityType={availabilityType}
				pageMode={mode}
				setMode={setMode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="page-title">
					<h5 className="d-flex">
						{AVAILABILITY_REQUESTS}
						<MlxPopover data={{ title: AVAILABILITY_REQUESTS, body: AVAILABILITY_REQUESTS_HELP }} />
					</h5>
				</div>

				<div className="card h-100 overflow-auto">
					<div className="card-header flex-grow-0">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 py-2">
								{breakPoint < width && showTitle &&
									<h4 className="mb-0 title">{AVAILABILITY_REQUESTS}</h4>
								}
							</div>
							<div className="flex-grow-0 d-flex align-items-center">
								{/* Filter Template */}
								{filterTemplate}

								<div
									className={`ms-2 form-group has-search ${!showTitle ? '' : 'search-small-box'}`}
									style={{ maxWidth: '175px' }}
								>
									<span className="fa fa-search form-control-feedback">
										<FaSearch />{' '}
									</span>
									<DebounceInput
										onFocusCapture={() => { breakPoint >= width && setShowTitle(false) }}
										onBlurCapture={(event: any) => {
											if (breakPoint >= width && event.target.value.length === 0) {
												setShowTitle(true)
											}
										}}
										className="form-control"
										placeholder="Search"
										minLength={0}
										debounceTimeout={800}
										onChange={(e) => setSearch(e.target.value)}
										data-test-id={'availability-search-input'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-auto">
						<div className="h-100 d-flex flex-column p-2">
							<AllAvailabilityDataGrid
								queryClient={queryClient}
								tableData={allAvailabilityList}
								setMode={setMode}
								setToastMessage={setToastMessage}
								setShowSideBar={setShowSideBar}
								showSideBar={showSideBar}
								onAvailabilityDeleteClick={onAvailabilityDeleteClick}
								setAvailabilityType={setAvailabilityType}
								dataTableEvent={dataTableEvent}
								setDataTableEvent={setDataTableEvent}
								paginationData={paginationData}
								loading={availabilityListData.isFetching}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(AvailabilityList)
