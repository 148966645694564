import React from "react";
import { Field, ErrorMessage, FieldProps } from 'formik';
import { JTranslation } from '../../helpers/jTranslate';
import { JobRoleWithMinCount } from '../../helpers/workTimeScheduleHelper';
import { InputNumber } from "antd";
import { capitalize } from "lodash";

type Props = {
    jobRoles: JobRoleWithMinCount[]
};

const StaffCountCardForEvents = ({  jobRoles }: Props) => {

    return (
        <>
            {jobRoles.map((role, index) => (
                <div className="mb-2 row" key={role.id}>
                    <label className='col'>{capitalize(role?.jobRoleName ?? '')}</label>
                    <Field
                        className='col'
                        name={`jobRoles.[${index}].minCount`}
                    >
                        {({ field, form }: FieldProps) => (
                            // <InputNumber
                            //     id={`jobRoles.[${index}].minCount`}
                            //     data-testid={`jobRoles.[${index}].minCount`}
                            //     value={field.value}
                            //     className="input-number-custom p-inputtext-sm"
                            //     onChange={(event) => form.setFieldValue(field.name, event.value, true)}
                            //     showButtons={true}
                            //     useGrouping={false}
                            //     min={0} max={999}
                            // />
                            <InputNumber
                                id={`jobRoles.[${index}].minCount`}
                                data-testid={`jobRoles.[${index}].minCount`}
                                value={Number(field.value)}
                                onChange={(value) => form.setFieldValue(field.name, value, true)}
                                min={0} 
                                max={999}
                                style={{ width: '70px', minWidth: '70px', padding: '0px' }}
                            />
                        )}
                    </Field>
                    <ErrorMessage
                        className="formik-error"
                        name={`jobRoles.[${index}].minCount`}
                        component="div"
                        render={(error) => (
                            <span className="formik-error">
                                <JTranslation text={error} />
                            </span>
                        )}
                    />
                </div>
            ))}
        </>
    );
};

export default React.memo(StaffCountCardForEvents);
