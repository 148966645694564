import { useState, useContext, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { CommonCtx } from '../../context/CommonCtxProvider'
import { DELETE_WARNING, TIME_OFF_REQUEST, EMPLOYEE_TIME_OFF, HELP_TIME_OFF_PAGE, HELP_TIME_OFF_HISTORY, REQUEST_TIME_OFF, MY_TIME_OFF } from '../../constants/strings'
import { FaSearch } from 'react-icons/fa'
import { getAllUserTimeOffRequests, getAllByUser } from '../../helpers/timeOffHelper'
import { getStaffId, checkPermission } from '../../helpers/utils'
import { routes } from '../../constants/routes'
import { toastMessageInitialData, PageMode, PermissionKeys, SubPermissionKeys, MOBILE_VIEW_BREAKPOINT } from '../../constants/constants'
import { ToastMessageProps, TimeOffEntry } from '../../constants/staticTypes'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { USERS_TIME_OFF_LIST } from '../../constants/queryKeys'
import Loader from '../../components/loader/Loader'
import TimeOffImage from '../../assets/images/time_off.svg'
import TimeOffRequestSideBar from '../../components/time_off_request/TimeOffRequestSideBar'
import ToastAlert from '../../components/alert/ToastAlert'
import useQueryHook from '../../hooks/useQueryHook'
import UserTimeOffRequestsDataGrid from '../../components/time_off_request/UserTimeOffRequestsDataGrid'
import WarningModal from '../../components/warning_modal/WarningModal'
import withSidebar from '../../hoc/withSidebar'
import { DebounceInput } from 'react-debounce-input'
import { JTranslation, TranslationContext, jTranslationText } from '../../helpers/jTranslate'
import { useWindowSize } from 'react-use'
import MlxPopover from '../../components/common/MlxPopover'

function TimeOffRequest() {
	const queryClient = useQueryClient()
	const location = useLocation()
	const currentPath = location.pathname
	const staffId = getStaffId()
	const limit = 500
	const { width, height } = useWindowSize()
	const breakPoint = MOBILE_VIEW_BREAKPOINT
	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)	
	const translationContext = useContext(TranslationContext)
	const { targetLanguage } = translationContext
	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [search, setSearch] = useState("")
	const [showWarning, setWarning] = useState(false)
	const [timeOffRequestList, setTimeOffRequestList] = useState<TimeOffEntry[]>([])
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)	
	const [translationText, setTranslatedText] = useState<{
		search: string,
	  }>({ 
		search: 'Search',
	});
	const [showTitle, setShowTitle] = useState(true)

	// Translate on load and language switch
	useEffect(() => {
		const fetchTranslation = async () => {
			try {
				const translations = await Promise.all([
					jTranslationText({ text: 'Search', typeCase: 'pascal', translationContext }),
				])
				
				setTranslatedText({
					search: translations[0] ?? 'Search',
				})
			} catch {
				setTranslatedText({
					search: 'Search',
				})
			}
		}
		fetchTranslation()
	}, [targetLanguage])

	// users list fetch success
	const onUserListFetchSuccess = (res: AxiosResponse<any, any>) => {
		const timeOffRequestListData = res.data.data.lists || [];
		const sortedTimeOffRequestListData = (timeOffRequestListData as TimeOffEntry[]).sort((a, b) => b.createdAt - a.createdAt)
		setTimeOffRequestList(sortedTimeOffRequestListData)
	}

	useEffect(() => {
		return () => {
			setSearch("")
			queryClient.removeQueries(USERS_TIME_OFF_LIST)
		}

	}, [currentPath]) // eslint-disable-line	

	// fetch users list
	const timeOffData = useQueryHook(
		USERS_TIME_OFF_LIST,
		() => {
			if (currentPath === routes.time_off && staffId) {
				return getAllByUser({ id: staffId, limit: limit, search: search });
			} else {
				return getAllUserTimeOffRequests({ limit: limit, search: search });
			}
		},
		onUserListFetchSuccess
	);

	// on trigger search
	const onSearchTrigger = (searchKey: string) => {
		if (timeOffData.isFetching) {
			queryClient.cancelQueries([USERS_TIME_OFF_LIST])
		}

		if (currentPath === routes.time_off_requests) {
			queryClient.fetchQuery(USERS_TIME_OFF_LIST, () => getAllUserTimeOffRequests({ limit: limit, search: searchKey }))
		} else {
			queryClient.fetchQuery(USERS_TIME_OFF_LIST, () => getAllByUser({ id: staffId, limit: limit, search: searchKey }))
		}
	}

	if (timeOffData.isLoading) {
		return <Loader />
	}

	return (
		<>
			{/* Delete modal */}
			<WarningModal
				show={showWarning}
				title={DELETE_WARNING}
				onHide={() => {
					setWarning(false)
				}}
				callback={() => {
					setWarning(false)
				}}
			/>

			{/* toaster component */}
			<ToastAlert
				show={toastMessage.show}
				onClose={() => setToastMessage(toastMessageInitialData)}
				message={toastMessage.message}
				variant={toastMessage.variant}
			/>

			{/* side bar component  */}
			<TimeOffRequestSideBar pageMode={mode} setShowSideBar={setShowSideBar} setToastMessage={setToastMessage} />

			<div className="page-title">
				<h5 className="d-flex">
					<JTranslation typeCase="pascal" text={EMPLOYEE_TIME_OFF} />
					<MlxPopover data={{title:EMPLOYEE_TIME_OFF, body:currentPath === routes.time_off_requests ? HELP_TIME_OFF_PAGE : HELP_TIME_OFF_HISTORY}}/>
				</h5>
			</div>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="card h-100 ">
					<div className="card-header flex-grow-0">
						<div className="d-flex  align-items-center">
							<div className="flex-grow-1">
								{showTitle && 
									<h4 className="mb-0 title">{
										currentPath === routes.time_off_requests
											? <JTranslation typeCase="pascal" text={TIME_OFF_REQUEST+'s' } />
											: <JTranslation typeCase="pascal" text={MY_TIME_OFF} />
									}</h4>
								}
							</div>
							<div className="flex-grow-0 me-2 ms-1">
								<button
									className={"btn btn-custom-primary d-flex icon-only-btn " +
										(
											(currentPath === routes.time_off_requests 
												? !checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.ADD)
												: !checkPermission(queryClient, PermissionKeys.MANAGE_MY_TIME_OFF, SubPermissionKeys.MY_TIME_OFF)) &&
											' disabled'
										)
									}
									type="button"
									onClick={() => {
										if (
											currentPath === routes.time_off_requests 
												? checkPermission(queryClient, PermissionKeys.MANAGE_TIME_OFF, SubPermissionKeys.ADD)
												: checkPermission(queryClient, PermissionKeys.MANAGE_MY_TIME_OFF, SubPermissionKeys.MY_TIME_OFF)
										) {
											setShowSideBar(true)
										}
									}}
								>
									<i className="ri-add-circle-line me-1"></i>
									<span className="btn-text"><JTranslation typeCase="pascal" text={REQUEST_TIME_OFF} /></span>
								</button>
							</div>
							{currentPath === routes.time_off_requests && 
							<div className="flex-grow-0 d-flex justify-content-end ">
								<div className="col-md-12">
									<div className={`form-group has-search ${!showTitle ? '' : 'search-small-box'}`}>
										<span className="fa fa-search form-control-feedback">
											<FaSearch />{' '}
										</span>
										<DebounceInput
											onFocusCapture={() => {breakPoint >= width && setShowTitle(false)}}
											onBlurCapture={(event: any) => {                                                
                                                if(breakPoint >= width && event.target.value.length === 0) {
                                                    setShowTitle(true)
                                                }
                                            }}
											className="form-control"
											placeholder={translationText.search}
											minLength={0}
											debounceTimeout={800}
											onChange={(e) => {
												onSearchTrigger(e.target.value);
												setSearch(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>}
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-auto">
						<div className="survey-section h-100">
							{timeOffRequestList.length ? (
								<UserTimeOffRequestsDataGrid
									tableData={timeOffRequestList}
									setMode={setMode}
									setToastMessage={setToastMessage}
									setShowSideBar={setShowSideBar}
									showSideBar={showSideBar}
								/>
							) : timeOffData.isLoading || timeOffData.isRefetching ? (
								<Loader />
							) : (
								<div className="text-center">
									<img src={TimeOffImage} alt="time-off-request" />
									<h5 className="mt-5 mb-3"><JTranslation typeCase="pascal" text={"No Time Off Requested"} /></h5>
									<p>
										<JTranslation typeCase="capitalize" text={`Do you need some time off? No worries! Just Send a new request whenever you want
										to schedule some time off, and we'll make sure it's properly handled.`} />
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default withSidebar(TimeOffRequest)
