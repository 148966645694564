import { Dialog } from "primereact/dialog";
import { JTranslation } from "../../helpers/jTranslate";
import { Chip } from "primereact/chip";
import { getAllWeekDays } from "../../helpers/utils";
import dayjs from 'dayjs';
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { isRoleWithShiftPresent } from "../../helpers/workTimeScheduleHelper";

type Props = {
    visible: boolean;
    shiftRole: any
    availabilities: any;
    header: string;
    callback: React.Dispatch<React.SetStateAction<any>>;
    onHide: () => void;
};

function RepeatShiftModal({
    visible,
    header,
    shiftRole,
    availabilities,
    callback,
    onHide
}: Readonly<Props>) {

    const weekDays = getAllWeekDays(dayjs());
    const roleData = cloneDeep(shiftRole);
    const activeColor = {
        minWidth: "60px",
        backgroundColor: "var(--primary-color)",
        color: "#fff",
    }

    const [repeatShiftContent, setRepeatShiftContent] = useState([]);

    const addRoleToDay = (targetRole: any, targetDay: any) => {
        const targetData = cloneDeep(repeatShiftContent);
        const updatedData: any = targetData.find((d: any) => d.day === targetDay);
        if (!updatedData) return;
        if (!updatedData.roles) updatedData.roles = [];

        const existingRole = updatedData.roles.find((role: any) => role.code === targetRole.code);
        if (!existingRole) {
            updatedData.roles.push(targetRole);
        } else {
            const shiftsMatch = targetRole.shifts.length === existingRole.shifts.length &&
                targetRole.shifts.every((targetShift: any) =>
                    existingRole.shifts.some((shift: any) =>
                        shift.code === targetShift.code &&
                        shift.startTime === targetShift.startTime &&
                        shift.endTime === targetShift.endTime
                    )
                );

            if (!shiftsMatch) {
                updatedData.roles.push(targetRole);
            }
        }

        setRepeatShiftContent(targetData);
    }

    const removeRoleFromDay = (targetRole: any, targetDay: any) => {
        const targetData = cloneDeep(repeatShiftContent);
        const updatedData: any = targetData.find((d: any) => d.day === targetDay);
        if (!updatedData || !updatedData.roles) return false;
        updatedData.roles = updatedData.roles.filter((role: any) => role.code !== targetRole.code);

        setRepeatShiftContent(targetData);
    }

    const footerContent = (
        <div className="d-flex justify-content-end align-items-center">
            <button
                className="btn btn btn-custom-primary-outline"
                type="button"
                data-testid="cancel-btn"
                onClick={onHide}
            >
                <JTranslation typeCase="pascal" text="Cancel" />
            </button>
            <button
                className="btn btn-custom-primary ms-2"
                type="button"
                data-testid="save-btn"
                onClick={() => {
                    // Apply changes
                    callback(repeatShiftContent)
                    onHide();
                }}
            >
                <JTranslation typeCase="pascal" text="Apply" />
            </button>
        </div>
    );

    const getActiveDays = () => {
        if (visible && roleData && repeatShiftContent) {
            return isRoleWithShiftPresent(roleData, repeatShiftContent)?.map((day: any) => day.day) ?? [];
        }
        return [];
    }

    useEffect(() => {
        if(!visible) {
            setRepeatShiftContent(cloneDeep(availabilities))
        }
    }, [visible])

    useEffect(() => {
        setRepeatShiftContent(availabilities ? cloneDeep(availabilities) : [])
    }, [availabilities])

    return (
        <Dialog
            header={<JTranslation typeCase="pascal" text={header} />}
            visible={visible}
            style={{ minWidth: '370px', maxWidth: '510px' }}
            onHide={onHide}
            footer={footerContent}
            draggable={false}
            headerClassName="p-3"
            contentClassName="ps-3 py-1"
            pt={{
                footer: { style: { padding: '1.5rem 1rem' } },
            }}
        >
            <div className="mb-3 text-muted">
                <JTranslation typeCase="capitalize" text="This feature allows admin to apply a created shift to other days within the same week. This will be applied to all the days of the week selected." />
            </div>

            <div className="mt-5 mb-2"><strong><JTranslation typeCase="capitalize" text="Apply To" /></strong></div>

            <div className="d-flex flex-row align-items-center flex-wrap gap-2 mb-3">
                {weekDays.map((day, index) => {
                    const activeDays = getActiveDays();
                    const currentDay = day.format('dddd').toLowerCase();
                    return (
                        <Chip
                            className="d-flex align-items-center justify-content-center"
                            label={day.format('ddd')}
                            key={index}
                            role="button"
                            onClick={() => {
                                if (activeDays.includes(currentDay)) {
                                    removeRoleFromDay(roleData, currentDay)
                                } else {
                                    addRoleToDay(roleData, currentDay)
                                }
                            }}
                            style={activeDays.includes(currentDay) ? activeColor : { minWidth: "60px" }}
                        />
                    )
                })}
            </div>
        </Dialog>
    )
}

export default RepeatShiftModal;
