import loader from '../../assets/images/loader.gif';
import loaderMothersDay from '../../assets/images/loadingmothersday.gif';
// import { celebrationsConfigs } from '../celebrations/celebrationsConfigs';
import { checkFestivalEffects } from '../celebrations/helpers';
import type { BrandingData } from '../../constants/staticTypes';
import { useQuery } from 'react-query';
import { BRANDING_INFO } from '../../constants/queryKeys';
import { getTenantInfo } from '../../helpers/authHelper';
import { useLocation } from 'react-router-dom'; // Import useLocation to access location.pathname
import SVGLoader  from './svgLoader'; // Import the SVGLoader component

import { FESTIVAL_TEMPLATES } from '../../constants/constants';

type Props = {
  heightAuto?: boolean;
};

function Loader({ heightAuto = false }: Props) {
  const location = useLocation(); // Use useLocation to access the current route

  const { data: brandingInfo } = useQuery<BrandingData>(BRANDING_INFO, {
    enabled: true,
    queryFn: () =>
      getTenantInfo().then(
        (tenantInfo) => tenantInfo.data?.data?.branding as BrandingData
      ),
  });

  // Check for festival effects and return a special logo or the standard loader
  const isFestival = checkFestivalEffects(
    brandingInfo as BrandingData,
    FESTIVAL_TEMPLATES.MOTHERS_DAY,
    location.pathname
  );

  if (isFestival) {
    return (
      <div className={heightAuto ? 'loader-outer auto-height' : 'loader-outer'}>
         <SVGLoader />
        <br />
        <h6>Loading.......</h6>
      </div>
    );
  }
  return (
    <div className={heightAuto ? 'loader-outer auto-height' : 'loader-outer'}>
      <SVGLoader />
    </div>
  );
}

export default Loader;
