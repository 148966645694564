import { useState, useContext, useEffect, useRef } from 'react'
import ToastAlert from '../../components/alert/ToastAlert'
import WarningModal from '../../components/warning_modal/WarningModal'
import { DATE_FORMAT, PageMode, toastMessageInitialData } from '../../constants/constants'
import { ToastMessageProps, JobRole, HolidayData } from '../../constants/staticTypes'
import { CURRENT_WEEK, VIEW_AVAILABILITY, VIEW_AVAILABILITY_HELP } from '../../constants/strings'
import { CommonCtx } from '../../context/CommonCtxProvider'
import withSidebar from '../../hoc/withSidebar'
import { getAllDays, getAllWeekDays, toPascalCase } from '../../helpers/utils'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import AvailabilityViewDataGrid from '../../components/availability/AvailabilityViewDataGrid'
import { AxiosResponse } from 'axios'
import Loader from '../../components/loader/Loader'
import { HOLIDAYS_LIST, MANDATORY_DAYS_LIST, STAFF_AVAILABILITY, USER_ROLES } from '../../constants/queryKeys'
import useQueryHook from '../../hooks/useQueryHook'
import { Button } from 'primereact/button'
import MandatoryDaysSideBar from '../../components/availability/MandatoryDaysSideBar'
import { getUserRoles } from '../../helpers/userRolesHelper'
import MlxPopover from '../../components/common/MlxPopover'
import { useWindowSize } from 'react-use'
import { getAllMandatoryDays, getAvailabilityGlance } from '../../helpers/availabilityHelper'
import { MultiSelect } from 'primereact/multiselect'
import { getAllHolidaysList } from '../../helpers/holidayHelper'
import { cloneDeep } from 'lodash'
import { Avatar } from 'primereact/avatar'
import placeholderImg from '../../assets/images/placeholder-avatar.jpg'
import WeekFilter from '../../components/week_filter/WeekFilter'
import FilterWrapper from '../../components/filter_wrapper/FilterWrapper'

function ViewAvailability() {
	const previewRef = useRef(null)
	const { width } = useWindowSize()

	// STATE VARIABLE
	const [mode, setMode] = useState<PageMode>(PageMode.ADD)
	const [availabilityList, setAvailabilityList] = useState<any[]>([])
	const [availabilityFilteredList, setAvailabilityFilteredList] = useState<any[]>([])
	const [rolesList, setRolesList] = useState<JobRole[]>([])
	const [selectedRoles, setSelectedRoles] = useState<string[]>([])
	const [mandatoryDays, setMandatoryDays] = useState<dayjs.Dayjs[]>([])
	const [holidayList, setHolidayList] = useState<HolidayData[]>([])
	const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs>(dayjs())
	const [weekDays, setWeekDays] = useState<dayjs.Dayjs[]>([])
	const [showPreview, setShowPreview] = useState(false)
	const [showTitle, setShowTitle] = useState(true)
	const [searchValue, setSearchValue] = useState<string>('')
	const [availabilityUserList, setAvailabilityUserList] = useState<any[]>([])
	const [selectedAvailabilityUser, setSelectedAvailabilityUser] = useState<string[]>([])

	// CONTEXT VARIABLE
	const { showSideBar, setShowSideBar } = useContext(CommonCtx)

	// ALERT VARIABLES
	const [toastMessage, setToastMessage] = useState<ToastMessageProps>(toastMessageInitialData)

	// WARNING MODAL
	const [showWarning, setWarning] = useState(false) // Warning popup
	const [warningMessage, setWarningMessage] = useState('') // Warning message

	// roles list fetch success
	const onRoleSuccess = (res: AxiosResponse<any, any>) => {
		const data: JobRole[] | [] = res.data?.data?.lists as JobRole[]
		const sortedRoles = [...data].sort((a, b) => a?.jobRoleName?.localeCompare(b?.jobRoleName));
		setRolesList(sortedRoles)
	}

	// fetch roles list
	const userRoles = useQueryHook(USER_ROLES, getUserRoles, onRoleSuccess)

	// fetch all mandatory days
	const mandatoryDaysQuery = useQueryHook(
		MANDATORY_DAYS_LIST,
		() => getAllMandatoryDays(),
		(response: AxiosResponse<any, any>) => {
			const data = response?.data?.data?.lists
			if (data) {
				const convertStringToDayJS = [];
				for (const day of data) {
					// can have single time, day or multiple days
					const startDate = dayjs(day.startDate)
					const endDate = dayjs(day.endDate)
					const totalDays = getAllDays(startDate, endDate)
					convertStringToDayJS.push(...totalDays)
				}
				setMandatoryDays(convertStringToDayJS)
			}
		},
		() => { },
		false,
		false
	)

	// holiday list
	const holidayListQuery = useQueryHook(
		HOLIDAYS_LIST,
		() => getAllHolidaysList(dayjs().year()),
		(response: AxiosResponse<any, any>) => {
			const holidayList = (response?.data?.data?.lists ?? []) as HolidayData[]
			setHolidayList(holidayList)
		},
		() => { },
		false,
		false

	)

	// fetch roles list
	const staffAvailabilityQueryHook = useQueryHook(STAFF_AVAILABILITY, () => getAvailabilityGlance({
		startDate: weekDays[0].format('YYYY-MM-DD'),
		endDate: weekDays[weekDays.length - 1].format('YYYY-MM-DD'),
	}), (response: AxiosResponse<any, any>) => {
		const responseData = response?.data?.data;
		const sortedData = [...responseData]?.sort((a, b) => {
			if (a?.firstName?.trim()?.toLowerCase() < b?.firstName?.trim()?.toLowerCase()) return -1;
			if (a?.firstName?.trim()?.toLowerCase() > b?.firstName?.trim()?.toLowerCase()) return 1;
			return 0;
		});
		const userList = sortedData?.map(data => ({
			id: data?.id,
			firstName: data?.firstName,
			lastName: data?.lastName,
			preferredName: data?.preferredName,
			photoThumbnail: data?.signedUrl ?? data?.photoThumbnail
		}))
		setAvailabilityUserList(userList)
		setAvailabilityFilteredList(sortedData)
		setAvailabilityList(sortedData)
	}, () => { },
		false,
		false
	)

	// set all week days
	useEffect(() => {
		const weekDays = getAllWeekDays(selectedWeek, 'DD/MM/YYYY')
		setWeekDays(weekDays)

		// clear other filters 
		setSelectedRoles([])
		setSelectedAvailabilityUser([])
	}, [selectedWeek])

	// fetch onc weekdays are selected
	useEffect(() => {
		if (weekDays.length) {
			holidayListQuery.refetch()
			mandatoryDaysQuery.refetch()
			staffAvailabilityQueryHook.refetch()
		}
	}, [weekDays])

	// filter availability list by selected users and roles
	useEffect(() => {
		let filteredAvailability = cloneDeep(availabilityList);

		// Filter by selected users
		if (selectedAvailabilityUser?.length > 0) {
			filteredAvailability = filteredAvailability.filter(availability =>
				selectedAvailabilityUser.includes(availability?.id)
			);
		}

		// Filter by selected roles
		if (selectedRoles?.length > 0) {
			filteredAvailability = filteredAvailability.filter((user) => {
				const userRoles = user?.jobRoles?.map((role: any) => role?.jobRoleId);
				return selectedRoles.some((role) => userRoles?.includes(role));
			});
		}

		setAvailabilityFilteredList(filteredAvailability);
	}, [selectedAvailabilityUser, selectedRoles, availabilityList]);

	if (staffAvailabilityQueryHook.isLoading || mandatoryDaysQuery.isLoading || holidayListQuery.isLoading || userRoles.isLoading) {
		return <Loader />
	}

	return (
		<>
			<WarningModal
				show={showWarning}
				title={warningMessage}
				onHide={() => {
					setWarning(false)
				}}
				callback={() => {
					setWarning(false)
				}}
			/>

			<ToastAlert
				data-testid="toast"
				message={toastMessage.message}
				onClose={() => setToastMessage(toastMessageInitialData)}
				show={toastMessage.show}
				variant={toastMessage.variant}
			/>

			{/* Mandatory days sidebar */}
			<MandatoryDaysSideBar
				pageMode={mode}
				setMode={setMode}
				setShowSideBar={setShowSideBar}
				setToastMessage={setToastMessage}
			/>

			<div className="col-md-12 col-lg-12 mb-3 h-100 ">
				<div className="page-title d-flex mb-3 align-items-center">
					<div className="flex-grow-1">
						<h5 className="mb-0 d-flex align-items-center">
							<span>{VIEW_AVAILABILITY}</span>
							<MlxPopover data={{ title: VIEW_AVAILABILITY, body: VIEW_AVAILABILITY_HELP }} />
						</h5>
					</div>
					<div className="flex-grow-0 align-items-center">
						{/* Fullscreen preview */}
						<Button
							icon="ri-fullscreen-line"
							className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
							title="Fullscreen"
							onClick={() => {
								setShowPreview(true)
							}}
						/>

						{/* F&B configuration*/}
						<Button
							icon="ri-settings-4-line "
							className=" p-button-rounded p-button-primary p-button-text fs-4 color-primary-custom"
							title="Mandatory Days"
							onClick={() => {
								setShowSideBar(true)
							}}
						/>
					</div>
				</div>

				<div className={`card h-100  ${showPreview ? 'mlx-fullscreen' : ''}`} ref={previewRef}>
					<div className="card-header flex-grow-0 px-3 px-2">
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 d-flex align-items-center"></div>
							<div className="flex-grow-0 me-2 ms-1">
								<div className="d-flex align-items-center">
									<FilterWrapper 
										isFiltered={!!(selectedAvailabilityUser?.length || selectedRoles?.length)}
										clearFilter={() => {
											setSelectedAvailabilityUser([])
											setSelectedRoles([])
										}}
									>
										<MultiSelect
											className='mb-2'
											data-testid='roles-select'
											value={selectedRoles}
											options={rolesList}
											itemTemplate={(option: JobRole) => <span key={option.id}>{option.jobRoleName}</span>}
											onChange={(e) => {
												setSelectedRoles(e.value)
											}}
											panelClassName={'custom-multiselect'}
											optionLabel='jobRoleName'
											showSelectAll={false}
											maxSelectedLabels={1}
											optionValue='id'
											filter={true}
											placeholder={'Select roles'}
											pt={{ closeButton: { style: { display: 'none' } } }}
											showClear={true}
										/>
										<MultiSelect
											data-testid='users-select'
											value={selectedAvailabilityUser}
											options={availabilityUserList}
											itemTemplate={(option: any) => (
												<div className='d-flex flex-row align-items-center' key={option.id}>
													<Avatar 
														className='me-2' 
														image={option?.photoThumbnail ?? placeholderImg} 
														imageFallback={placeholderImg} 
														shape="circle" 
													/>
													<span>{toPascalCase(option.firstName ? `${option?.firstName} ${option?.lastName}` : option?.preferredName)}</span>
												</div>
											)}
											onChange={(e) => {
												setSelectedAvailabilityUser(e.value)
											}}
											panelClassName={'custom-multiselect'}
											optionLabel='firstName'
											showSelectAll={false}
											maxSelectedLabels={1}
											optionValue='id'
											filter={true}
											placeholder={'Select User'}
											pt={{ closeButton: { style: { display: 'none' } } }}
											showClear={true}
										/>
									</FilterWrapper>

									<span className='ms-2'>
										<WeekFilter 
											onChange={(date, dateString) => {
												const selectedDate = date ? dayjs(date) : dayjs()
												setSelectedWeek(selectedDate)
											}}
											selectedWeek={selectedWeek}
											setPrevSelectedWeek={setSelectedWeek}
											setNextSelectedWeek={setSelectedWeek}
										/>
									</span>

									{showPreview && (
										<Button
											rounded
											outlined
											icon="ri-fullscreen-exit-line"
											className="ms-3 p-button-primary color-primary-custom rounded-circle border-primary"
											title="Close Preview"
											onClick={() => {
												setShowPreview(false)
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="card-body flex-grow-1 overflow-hidden">
						<div className="h-100 d-flex flex-column justify-content-center">
							<AvailabilityViewDataGrid
								tableData={availabilityFilteredList}
								mandatoryDays={mandatoryDays}
								weekDays={weekDays}
								loading={staffAvailabilityQueryHook.isFetching || mandatoryDaysQuery.isFetching || holidayListQuery.isFetching}
								holidayList={holidayList}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default withSidebar(ViewAvailability)
