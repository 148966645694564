// SVG loader component
  const SVGLoader = () => (
    <svg
      xmlSpace="preserve"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      style={{margin: "initial", display: "block", shapeRendering: "auto", background: "transparent"}}
      preserveAspectRatio="xMidYMid"
      width="150"
      height="150"
    >
      <g style={{transformOrigin: "50% 50%", transform: "rotate(0deg) scale(0.8, 0.8)"}}>
        <g>
          <g>
            <g style={{opacity: 1, transformOrigin: "50px 50px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation: "1s linear -0.625s infinite normal forwards running animate"}}>
              <path fill="#8D6E63" d="M92.5 45c-.1-7.7-3.7-18.5-6.8-22.3-.3-.4-1.1-.2-1.1.3V75.9c0 .9.8 1.6 1.8 1.6.9 0 1.6-.5 1.8-1.3l.1-.5c.2-.7.2-1.5.2-2.2L88 58.4c2.4-1.9 4.6-6 4.5-13.4z"></path>
            </g>
          </g>
          <g>
            <g style={{opacity: 1, transformOrigin: "50px 50px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation: "1s linear -0.75s infinite normal forwards running animate"}}>
              <path fill="#A1887F" d="M17.9 26.9c0-.9-.4-1.7-1-1.7h-.1c-.5.1-.9.9-.9 1.7l-.3 8.5c0 .6-.4 1-.8 1s-.8-.5-.8-1l-.2-9.8c0-.7-.2-1.2-.6-1.5l-.1-.1H13h-.1-.1-.1-.1-.1l-.1.1c-.3.3-.6.9-.6 1.5l-.2 9.8c0 .6-.4 1-.8 1s-.8-.5-.8-1L9.7 27c0-.9-.4-1.7-.9-1.7h-.1c-.5 0-1 .7-1 1.7l-.3 9.6v3.2c0 2 1 3.6 2.3 4.2.5.3.9.5 1.2 1.2.2.6.3 1.1.3 1.7l-.5 24.8c0 2.4.9 4.4 2 4.4h.2c1.1 0 2-2 2-4.4 0-4.5-.5-24.8-.5-24.8 0-.6.1-1.2.3-1.7.3-.7.7-.9 1.2-1.2 1.3-.6 2.3-2.3 2.3-4.2v-3.2l-.3-9.7z"></path>
            </g>
          </g>
          <g>
            <g style={{opacity: 1, transformOrigin: "50px 50px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation: "1s linear -0.875s infinite normal forwards running animate"}}>
              <circle fill="#FF9800" r="28.4" cy="50" cx="50.7"></circle>
            </g>
          </g>
          <g>
            <g style={{opacity: 1, transformOrigin: "50px 50px", transform: "matrix3d(0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 0.91, 0, 0, 0, 0, 1)", animation: "1s linear -1s infinite normal forwards running animate"}}>
              <path strokeMiterlimit="10" strokeWidth="2" stroke="#FFCC80" fill="#FFA726" d="M66.7 36C70 39.7 72 44.6 72 50c0 11.8-9.5 21.3-21.3 21.3S29.4 61.8 29.4 50s9.5-21.3 21.3-21.3c6.4 0 12.1 2.8 16 7.3"></path>
            </g>
          </g>
        </g>
      </g>
      <style>
        {`
          @keyframes animate {
            0% { transform: scale(1); }
            50% { transform: scale(0.85); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </svg>
  );


  export default SVGLoader;